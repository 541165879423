import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export default function CloseButton({ onClick, className, style, loading, success }) {
  return (
    <OverlayTrigger overlay={<Tooltip id="document-print-tooltip" className='font-weight-bold'><FormattedMessage id="GENERAL.CLOSE" /></Tooltip>}>
      <Button variant="link" className={clsx("close icon-md", className)} style={style} onClick={onClick} id='btn_close' disabled={loading || success}>
        <Icon.Close />
      </Button>
    </OverlayTrigger>
  );
}