import React from "react";
import { Icon } from "_metronic/_icons";
import { FormattedMessage } from "react-intl";



export const INITIAL_DIGITAL_PRODUCT = {
  token: '',
  order_id: '',
  order_number: '',
  marketplace: '',
  marketplace_url: '',
  sku: '',
  key: '',
  key_id: '',
  product: '',
  name: '',
  email: '',
  email_sended_date: null,
  order_date: null,
};

export const defaultSorted = [{ dataField: 'order_date', order: 'desc' }];

export const allQueryKeys = [
  'search',
  'key_status',
  'marketplace',
  'date_from',
  'date_to',
];


export const KEY_STATUS = [
  {
    value: "sent",
    label: <FormattedMessage id="PRODUCT.KEYS.EMAIL.SENT" />,
    icon: <Icon.EnvelopeCheck />,
  },
  {
    value: "not_sent",
    label: <FormattedMessage id="PRODUCT.KEYS.EMAIL.NOT_SENT" />,
    icon: <Icon.EnvelopeSlash />,
  },
  {
    value: "approved",
    label: <FormattedMessage id="PRODUCT.KEYS.KEY.APPROVED" />,
    icon: <Icon.FileEarmarkCheck />,
  },
  {
    value: "not_approved",
    label: <FormattedMessage id="PRODUCT.KEYS.KEY.NOT_APPROVED" />,
    icon: <Icon.FileEarmarkMinus />,
  },
];


// export const KEY_STATUS_FILTER = [
//   ...KEY_STATUS,
//   {
//     value: "all_status",
//     label: <FormattedMessage id="All Status" />,
//     className: 'secondary',
//   },
// ]