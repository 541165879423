import React, { createContext, useContext, useState } from 'react';
import { ModuleRoutes } from 'constants/moduleRoutes';
import * as uiHelpers from './DocumentsUIHelpers';
import { useHistory } from 'react-router-dom';
import { UIDates } from '_metronic/_helpers';
import { useIntl } from 'react-intl';
import moment from 'moment';



const DocumentsUIContext = createContext();
export const DocumentsUIConsumer = DocumentsUIContext.Consumer;
export const useDocumentsUIContext = () => useContext(DocumentsUIContext);


export function DocumentUIProvider({ children }) {

  const { formatMessage: intl } = useIntl();

  const history = useHistory();
  const [ids, setIds] = useState([]);
  const [documentId, setDocumentId] = useState();
  const [shipperId, setShipperId] = useState();
  const [customerId, setCustomerId] = useState();

  const [requestedDocument, setRequestedModule] = useState(null);

  const setupRequestedDocument = (document) => {
    setRequestedModule({
      ...document,
      title: intl({ id: document.title }),
      plural: intl({ id: document.plural }),
    });
    setIds([]);
  };

  const [showProductSelectDialog, setShowProductSelectDialog] = useState(false);
  const [showProductEditDialog, setProductEditDialog] = useState(false);
  const handleCloseProductEditDialog = () => {
    setShowProductSelectDialog(true);
    setProductEditDialog(false);
  };
  const handleOpenProductEditDialog = () => {
    setShowProductSelectDialog(false);
    setProductEditDialog(true);
  };

  const [positionTableRowId, setPositionTableRowId] = useState(null);
  const handleCloseProductSelectDialog = () => {
    setShowProductSelectDialog(false);
  };
  const handleOpenProductSelectDialog = row => {
    setShowProductSelectDialog(true);
    setPositionTableRowId(row);
  };

  const [showVariantSelectDialog, setShowVariantSelectDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleCloseVariantSelectDialog = () => {
    setShowVariantSelectDialog(false);
    setSelectedProduct(null);
  };
  const handleOpenVariantSelectDialog = productId => {
    setShowVariantSelectDialog(true);
    setSelectedProduct(productId);
  };


  //* Customer Edit Dialog
  const [showCustomerEditDialog, setCustomerEditDialog] = useState(false);
  const handleCloseCustomerEditDialog = () => {
    setCustomerEditDialog(false);
  };
  const handleOpenCustomerEditDialog = () => {
    setCustomerEditDialog(true);
  };

  const [showCustomerAddressDialog, setShowCustomerAddressDialog] = useState(false);
  const [, setCustomerAddress] = useState({});
  const handleCloseCustomerAddressDialog = () => {
    setShowCustomerAddressDialog(false);
  };
  const handleOpenCustomerAddressDialog = address => {
    setShowCustomerAddressDialog(true);
    setCustomerAddress(address);
  };


  //* Server_Respond_Dialog
  const [showServerRespondDialog, setShowServerRespondDialog] = useState(false);
  const handleOpenServerRespondDialog = () => {
    setShowServerRespondDialog(true);
  };
  const handleCloseServerRespondDialog = () => {
    setShowServerRespondDialog(false);
  };


  //* Send_Email_Dial0g
  const [showSendEmailsDialog, setShowSendEmailsDialog] = useState(false);
  const [reminder, setReminder] = useState(null);
  const handleOpenSendEmailsDialog = () => {
    setShowSendEmailsDialog(true);
  };
  const handleCloseSendEmailsDialog = () => {
    setShowSendEmailsDialog(false);
  };


  //* Customer details dialog
  const [showCustomerDetailsDialog, setShowCustomerDetailsDialog] = useState(false);
  const openCustomerDetailsDialog = id => {
    setDocumentId(id);
    setShowCustomerDetailsDialog(true);
  };
  const closeCustomerDetailsDialog = () => {
    setDocumentId(undefined);
    setShowCustomerDetailsDialog(false);
  };


  //* Document details dialog
  const [showDocumentDetailsDialog, setShowDocumentDetailsDialog] = useState(false);
  const openDocumentDetailsDialog = id => {
    setDocumentId(id);
    setShowDocumentDetailsDialog(true);
  };
  const closeDocumentDetailsDialog = () => {
    setDocumentId(undefined);
    setShowDocumentDetailsDialog(false);
  };


  const [confirmedParams, setConfirmedParams] = useState(undefined);
  const [showConvertDocumentAlertDialog, setShowConvertDocumentAlertDialog] = useState(false);
  const openConvertDocumentAlertDialog = params => {
    setConfirmedParams(params);
    setShowConvertDocumentAlertDialog(true);
  };
  const closeConvertDocumentAlertDialog = () => {
    setConfirmedParams(undefined);
    setShowConvertDocumentAlertDialog(false);
  };

  // Convert Document Info Dialog
  const [showConvertDocumentInfoDialog, setShowConvertDocumentInfoDialog] = useState(false);
  const openConvertDocumentInfoDialog = params => {
    setConfirmedParams(params);
    setShowConvertDocumentInfoDialog(true);
  };
  const closeConvertDocumentInfoDialog = () => {
    setConfirmedParams(undefined);
    setShowConvertDocumentInfoDialog(false);
  };


  //* -------------------------------- ENTERED PAYMENT -------------------------------- *//

  const [enteredPaymentForEdit, setEnteredPaymentForEdit] = useState(uiHelpers.initialEnteredValues);
  const [showEnterPaymentEditDialog, setShowEnterPaymentEditDialog] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const handleCloseEnterPaymentEditDialog = () => {
    setEnteredPaymentForEdit(uiHelpers.initialEnteredValues);
    setShowEnterPaymentEditDialog(false);
    setSelectedPaymentId(null);
  };
  const handleOpenEnterPaymentEditDialog = () => {
    setShowEnterPaymentEditDialog(true);
    setSelectedPaymentId(null);
  };
  const handleOpenEditEnterPaymentEditDialog = values => {
    setSelectedPaymentId(values.id);
    setShowEnterPaymentEditDialog(true);
    setEnteredPaymentForEdit({
      ...values,
      date: moment(values.date, 'YYYYY-MM-DDT00:00:00.000Z').format('YYYY-MM-DD'),
    });
  };

  // Over Payment Info Dialog
  const [showOverPaymentInfoDialog, setShowOverPaymentInfoDialog] = useState({ show: false, price: 0, currency: '' });
  const openOverPaymentInfoDialog = (price, currency) => setShowOverPaymentInfoDialog({ show: true, price, currency });
  const closeOverPaymentInfoDialog = () => setShowOverPaymentInfoDialog({ show: false, price: 0, currency: '' });


  //* -------------------------------- SHIPPING -------------------------------- *//

  // Label Settings_Dialog
  const [showLabelSettingsDialog, setShowLabelSettingsDialog] = useState(false);
  const openLabelSettingDialog = id => {
    setDocumentId(id);
    setShowLabelSettingsDialog(true);
  };
  const closeLabelSettingsDialog = () => {
    setShowLabelSettingsDialog(false);
  };

  // Label Edit Dialog
  const [showLabelEditDialog, setShowLabelEditDialog] = useState(false);
  const [editLabelParams, setEditLabelParams] = useState(uiHelpers.INITIAL_SHIPPING);
  const openLabelEditDialog = param => {
    setEditLabelParams({
      ...param,
      delivery_date: UIDates.formatDateTime(param.delivery_date, 'YYYY-MM-DD'),
    });
    setShowLabelEditDialog(true);
  };
  const closeLabelEditDialog = () => {
    setEditLabelParams(uiHelpers.INITIAL_SHIPPING);
    setShowLabelEditDialog(false);
  };

  // Shipping
  const [showPrintLabel, setShowPrintLabel] = useState(false);
  const [isConvert, setIsConvert] = useState(null);
  const [shipperName, setShipperName] = useState(null);
  const [shippingType, setShippingType] = useState(null);
  const openPrintLabelsDialog = (service, documentId, isConvert, type, multiple) => {
    setDocumentId(documentId);
    setShipperName(service);
    setIsConvert(isConvert);
    setShowPrintLabel(true);
    setShippingType(type);
  };
  const closePrintLabelsDialog = () => {
    // setShipperName(null);
    setIsConvert(null);
    setShowPrintLabel(false);
  };

  // Shipping delete modal
  const [showDeleteLabel, setShowDeleteLabel] = useState(false);
  const [deleteLabelParams, setDeleteLabelParams] = useState(null);
  const openDeleteLabelDialog = (params) => {
    setShowDeleteLabel(true);
    setDeleteLabelParams(params);
  };
  const closeDeleteLabelDialog = () => {
    setShowDeleteLabel(false);

  };

  // Label Settings Grouping Dialog
  const [showLabelGroupingDialog, setShowLabelGroupingDialog] = useState(false);
  const openLabelGroupingDialog = service => {
    setShipperName(service);
    setShowLabelGroupingDialog(true);
  };
  const closeLabelGroupingDialog = () => {
    setShowLabelGroupingDialog(false);
  };

  // Labels Table
  const [showLabelsTable, setShowLabelsTable] = useState(false);
  const openLabelsTable = (documentId) => {
    setDocumentId(documentId);
    setShowLabelsTable(true);
  };
  const closeLabelsTable = () => {
    setDocumentId(null);
    setShowLabelsTable(false);
  };

  // Label send email modal
  const [showSendEmailLabel, setShowSendEmailLabel] = useState(false);
  const sendEmailLabelDialog = (documentId, shippingId) => {
    setShowSendEmailLabel(true);
    setDocumentId(documentId)
    setShipperId(shippingId);
  };
  const closeSendEmailLabelDialog = () => {
    setShowSendEmailLabel(false);
  };

  // Payment Warnings 
  const [showPaymentWarnings, setShowPaymentWarnings] = useState(false);
  const openPaymentWarningsDialog = (documentId) => {
    setDocumentId(documentId);
    setShowPaymentWarnings(true);
  };
  const closePaymentWarningsDialog = () => {
    setShowPaymentWarnings(false);
  };

  // delete payment Warnings
  const [showDeletePaymentWarnings, setShowDeletePaymentWarnings] = useState(false);
  const [deletePaymentWarningsId, setDeletePaymentWarningsId] = useState(null);
  const deletePaymentWarningsDialog = (id) => {
    setShowDeletePaymentWarnings(true);
    setDeletePaymentWarningsId(id);
  };
  const closeDeletePaymentWarningsDialog = () => {
    setShowDeletePaymentWarnings(false);
    setDeletePaymentWarningsId(null);
  };

  // Convert Document
  const [showConvertDocumentsDialog, setShowConvertDocumentsDialog] = useState(false);
  const openConvertDocumentsDialog = () => setShowConvertDocumentsDialog(true);
  const closeConvertDocumentsDialog = () => setShowConvertDocumentsDialog(false);

  // Archive 
  const [showDeleteDocumentDialog, setShowDeleteDocumentDialog] = useState(false);
  const openDeleteDocumentDialog = (id) => {
    setShowDeleteDocumentDialog(true);
    setDocumentId(id);
  };
  const closeDeleteDocumentDialog = () => {
    setShowDeleteDocumentDialog(false)
    setDocumentId(null);
  };

  // Print
  const [showPrintDocumentDialog, setShowPrintDocumentDialog] = useState(false);
  const openPrintDocumentDialog = () => {
    setShowPrintDocumentDialog(true);
  };
  const closePrintDocumentDialog = () => {
    setShowPrintDocumentDialog(false)
  };

  // Export
  const [showExportDocumentDialog, setShowExportDocumentDialog] = useState(false);
  const openExportDocumentDialog = (id) => {
    setShowExportDocumentDialog(true);
    setDocumentId(id);
  };
  const closeExportDocumentDialog = () => {
    setShowExportDocumentDialog(false)
    setDocumentId(null);
  };

  const initialDateRange = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };

  const [dateRange, setDateRange] = useState(
    initialDateRange
  );
  
  const changeDateRange = (date = initialDateRange) => setDateRange(date);

  const value = {
    ids,
    setIds,
    documentId,
    setDocumentId,
    customerId,
    setCustomerId,
    shipperId,

    showPaymentWarnings,
    openPaymentWarningsDialog,
    closePaymentWarningsDialog,

    showDeletePaymentWarnings,
    deletePaymentWarningsId,
    deletePaymentWarningsDialog,
    closeDeletePaymentWarningsDialog,

    openPrintLabelsDialog,
    closePrintLabelsDialog,
    shipperName,
    isConvert,
    showPrintLabel,
    setShippingType,
    shippingType,

    showLabelSettingsDialog,
    openLabelSettingDialog,
    closeLabelSettingsDialog,

    showLabelEditDialog,
    editLabelParams,
    openLabelEditDialog,
    closeLabelEditDialog,

    showDeleteLabel,
    deleteLabelParams,
    setDeleteLabelParams,
    openDeleteLabelDialog,
    closeDeleteLabelDialog,

    showLabelGroupingDialog,
    openLabelGroupingDialog,
    closeLabelGroupingDialog,

    showLabelsTable,
    openLabelsTable,
    closeLabelsTable,

    showSendEmailLabel,
    sendEmailLabelDialog,
    closeSendEmailLabelDialog,

    showSendEmailsDialog,
    handleOpenSendEmailsDialog,
    handleCloseSendEmailsDialog,
    reminder,
    setReminder,

    setupRequestedDocument,
    requestedDocument,

    positionTableRowId,
    showProductEditDialog,
    handleCloseProductEditDialog,
    handleOpenProductEditDialog,
    showProductSelectDialog,
    handleCloseProductSelectDialog,
    handleOpenProductSelectDialog,
    showVariantSelectDialog,
    selectedProduct,
    handleCloseVariantSelectDialog,
    handleOpenVariantSelectDialog,

    showCustomerEditDialog,
    handleCloseCustomerEditDialog,
    handleOpenCustomerEditDialog,

    showCustomerAddressDialog,
    handleCloseCustomerAddressDialog,
    handleOpenCustomerAddressDialog,

    showServerRespondDialog,
    handleOpenServerRespondDialog,
    handleCloseServerRespondDialog,

    showEnterPaymentEditDialog,
    enteredPaymentForEdit,
    selectedPaymentId,
    handleCloseEnterPaymentEditDialog,
    handleOpenEnterPaymentEditDialog,
    handleOpenEditEnterPaymentEditDialog,

    showConvertDocumentAlertDialog,
    confirmedParams,
    openConvertDocumentAlertDialog,
    closeConvertDocumentAlertDialog,

    showCustomerDetailsDialog,
    closeCustomerDetailsDialog,
    openCustomerDetailsDialog,

    showDocumentDetailsDialog,
    closeDocumentDetailsDialog,
    openDocumentDetailsDialog,

    showConvertDocumentsDialog,
    openConvertDocumentsDialog,
    closeConvertDocumentsDialog,

    showDeleteDocumentDialog,
    openDeleteDocumentDialog,
    closeDeleteDocumentDialog,

    showPrintDocumentDialog,
    openPrintDocumentDialog,
    closePrintDocumentDialog,

    openConvertDocumentInfoDialog,
    closeConvertDocumentInfoDialog,
    showConvertDocumentInfoDialog,

    showOverPaymentInfoDialog,
    openOverPaymentInfoDialog,
    closeOverPaymentInfoDialog,

    showExportDocumentDialog,
    openExportDocumentDialog,
    closeExportDocumentDialog,

    //Document UI Events
    newDocumentButtonClick: () => history.push(ModuleRoutes.DOCUMENT_NEW_FN(requestedDocument.type)),
    openEditDocumentDialog: id => history.push(ModuleRoutes.DOCUMENT_UI_CONTEXT_EDIT_FN(requestedDocument.type, id)),

    copyDocumentForCreateNewOne: copyId => history.push(ModuleRoutes.DOCUMENT_UI_CONTEXT_COPY_FN(requestedDocument.type, copyId)),

    openPaymentReminderDialog: (id, type) => history.push(ModuleRoutes.DOCUMENT_UI_CONTEXT_REMINDER_FN(requestedDocument.type, id, type)),

    openEnterPaymentDialog: id => history.push(ModuleRoutes.DOCUMENT_UI_CONTEXT_PAYMENT_FN(requestedDocument.type, id)),

    openDocumentConvertToCredit: (id, from) => history.push(ModuleRoutes.DOCUMENT_UI_CONTEXT_CONVERT_CREDIT_FN(id, from)),
    openDocumentConvertToWaybill: (id, from) => history.push(ModuleRoutes.DOCUMENT_UI_CONTEXT_CONVERT_WAYBILL_FN(id, from)),
    openDocumentConvertToOrder: (id, from) => history.push(ModuleRoutes.DOCUMENT_UI_CONTEXT_CONVERT_ORDER_FN(id, from)),
    openDocumentConvertToInvoice: (id, from) => history.push(ModuleRoutes.DOCUMENT_UI_CONTEXT_CONVERT_INVOICE_FN(id, from)),

    pushConvertDocument: (id, convertFrom, convertTo) => history.push(ModuleRoutes.DOCUMENT_UI_CONTEXT_OPEN_CONVERT_DIALOG_FN(id, convertFrom, convertTo)),

    setDateRange,
    dateRange,
    changeDateRange,
  };

  return (
    <DocumentsUIContext.Provider value={value}>
      {children}
    </DocumentsUIContext.Provider>
  );
}
