import { UTILS } from '_metronic/_helpers';



export const VARIANT_ACTION_TYPES = {
  OPEN_GENERATE_VARIANT_DIALOG: 'fn/open/generateVariant',
  CLOSE_GENERATE_VARIANT_DIALOG: 'fn/close/generateVariant',
  OPEN_EDIT_VARIANT_DIALOG: 'fn/open/editVariant',
  CLOSE_EDIT_VARIANT_DIALOG: 'fn/close/editVariant',
  OPEN_ADD_SINGLE_VARIANT_DIALOG: 'fn/open/addSingleVariant',
  CLOSE_ADD_SINGLE_VARIANT_DIALOG: 'fn/close/addSingleVariant',
  SET_FORM_VALUES: 'set/form/values',

};

export const initialNewVariants = {
  showGenerateVariantDialog: false,
  showEditVariantDialog: false,
  showAddSingleVariantDialog: false,
  formValues: {},
  variantId: null,
};

/* export const variantOptions = [
  {
    value: 'color',
    label: 'Color',
    aspects: [
      {
        value: 'red',
        label: 'Red',
      },
      {
        value: 'blue',
        label: 'Blue',
      },
      {
        value: 'green',
        label: 'Green',
      },
    ],
  },
  {
    value: 'size',
    label: 'Size',
    aspects: [
      {
        value: 'S',
        label: 'Small',
      },
      {
        value: 'M',
        label: 'Medium',
      },
      {
        value: 'L',
        label: 'Large',
      },
    ],
  },
  {
    value: 'material',
    label: 'Material',
    aspects: [
      {
        value: 'cotton',
        label: 'Cotton',
      },
      {
        value: 'wool',
        label: 'Wool',
      },
    ],
  },
]; */

export const generateVariants = (variants, formValues) => {
  /**
   * variants = [ { "name": "color", "values": [ "red", "blue" ] }, { "name": "material", "values": [] }, { "name": "size", "values": [ "S", "M" ] } ]
   * options = ["color", "material", "size"]
   * aspects = [["red", "blue"], [], ["S", "M"]]
   * output = [["S", "Rot"], ["S", "Blau"], ["S", "Grün"], ["M", "Rot"], ["M", "Blau"], ["M", "Grün"], ["L", "Rot"], ["L", "Blau"], ["L", "Grün"]]
   */

  //# if variants is empty, return empty array
  if (!Array.isArray(variants) || variants.length === 0) {
    // toast.warning(<FormattedMessage id='PRODUCT.VARIANT.SELECTED' />);
    return [[], variants];
  }

  const options = variants.map(variant => variant.name);
  const aspects = variants.map(variant => variant.values);

  //# if variants is not empty, and length of values is 1
  if (variants.length === 1) {
    const option = options[0];
    const newVariants = aspects[0].map((aspect, i) => {
      const specs = [{ name: option, value: aspect }];
      const newVariant = new Variant({
        sku: formValues.sku ? `${formValues.sku}_${i}` : UTILS.generateSKU(),
        specs,
        pricing: {
          gross_price: formValues.pricing.gross_price || 0,
          purchase_price: formValues.pricing.purchase_price || 0,
        },
      });
      return newVariant;
    });
    return [newVariants, variants];
  }

  //# if variants is not empty and length of values is more than 1 (2 or more), then generate cartesian product
  const copyAspects = aspects.splice(0, aspects.length);
  let f = (a, b) => [].concat(...a.map(a => b.map(b => [].concat(a, b))));
  let cartesian = (a, b, ...c) => (b ? cartesian(f(a, b), ...c) : a);
  const output = cartesian(...copyAspects);
  const newVariants = [];
  for (let i = 0; i < output.length; i++) {
    const item = output[i]; // item = ["S", "Rot"]
    const specs = item.map((el, i) => {
      return { name: options[i], value: el };
    });
    const newVariant = new Variant({
      sku: formValues.sku ? `${formValues.sku}_${i}` : UTILS.generateSKU(),
      specs,
      pricing: {
        gross_price: formValues.pricing.gross_price || 0,
        purchase_price: formValues.pricing.purchase_price || 0,
      },
    });
    newVariants.push(newVariant);
  }
  return [newVariants, variants];
};

export const getVariantSpecs = variants => {
  const specs = {};
  for (const variant of variants) {
    for (const spec of variant.specs) {
      if (specs[spec.name]) {
        if (!specs[spec.name].includes(spec.value)) {
          specs[spec.name].push(spec.value);
        }
      } else {
        specs[spec.name] = [spec.value];
      }
    }
  }
  const origin = Object.keys(specs).map(key => {
    return { name: key, values: specs[key] };
  });
  return origin;
};

export class Variant {
  constructor({
    id = UTILS.uuidv4(),
    sku = UTILS.generateSKU(),
    ean = '',
    asin = '',
    pricing = {
      /*   net_price: 0, */
      gross_price: 0,
      purchase_price: 0,
    },
    specs = [],
    quantity = 0,
    image_urls = [],
  }) {
    this.id = id;
    this.sku = sku;
    this.ean = ean;
    this.asin = asin;
    this.pricing = pricing;
    this.specs = specs;
    this.quantity = quantity;
    this.image_urls = image_urls;
  }
}


export const getVariants = (productVariants, variantOptions) => {
  const specs = getVariantSpecs(productVariants);
  const selectedOptions = [];
  for (const spec of specs) {
    const selectedOption = variantOptions?.find(item => item.name === spec.name);
    if (selectedOption) {
      selectedOptions.push(selectedOption);
    }
  }
  return selectedOptions;
};

/* export const getVariantSpecValues = specs => {
  const selectedOptions = [];
  for (const spec of specs) {
    const selectedOption = variantOptions.find(item => item.value === spec.name);
    if (selectedOption) {
      selectedOptions.push(selectedOption);
    }
  }
  return selectedOptions;
}; */

/**
 * Get all variant spec names from variants
 * @param {Array} variants
 * @returns {string[]} variant spec names - (e.g. ["color", "size", "material"])
 */
export const getAllVariantSpecNames = variants => {
  const variantSpecs = [];
  for (const variant of variants) {
    for (const spec of variant.specs) {
      if (!variantSpecs.includes(spec.name)) {
        variantSpecs.push(spec.name);
      }
    }
  }
  return variantSpecs;
};
