import React from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls'
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { Icon } from '_metronic/_icons'



export function UsageReportCard({ usageReport }) {

  const { formatMessage: intl } = useIntl()

  const { orders, invoices, credits, waybills, offers, archives, customers, suppliers, shipping, supports, products, transactions, connections, connection_count } = usageReport;

  const USAGE_REPORTS = [
    {
      label: "DOCUMENT.ORDER_TITLE",
      icon: <Icon.Order />,
      content:
        orders ?
          archives?.order_archived_count > 0
            ? <OverlayTrigger placement="top" overlay={
              <Tooltip id="order-tooltip">
                <ul className="list-unstyled mb-0">
                  <li className='text-left pb-0'>
                    {intl({ id: 'DOCUMENT.ORDER_TITLE' }) + ": " + orders}
                  </li>
                  <li className='text-left pb-0'>
                    {intl({ id: 'GENERAL.ARCHIVED' }) + ": " + archives?.order_archived_count}
                  </li>
                </ul>
              </Tooltip>}>
              <span>
                {`${archives?.order_archived_count} / ${orders}`}
              </span>
            </OverlayTrigger>
            : <span>{orders}</span>
          : 0,
    },
    {
      label: "DOCUMENT.INVOICE_TITLE",
      icon: <Icon.Document />,
      content: invoices ?? 0,
    },
    {
      label: "DOCUMENT.OFFER_TITLE",
      icon: <Icon.Document />,
      content:
        offers ?
          archives?.offer_archived_count > 0
            ? <OverlayTrigger placement="top" overlay={
              <Tooltip id="offer-tooltip">
                <ul className="list-unstyled mb-0">
                  <li className='text-left pb-0'>
                    {intl({ id: 'DOCUMENT.OFFER_TITLE' }) + ": " + offers}
                  </li>
                  <li className='text-left pb-0'>
                    {intl({ id: 'GENERAL.ARCHIVED' }) + ": " + archives?.offer_archived_count}
                  </li>
                </ul>
              </Tooltip>}>
              <span>
                {`${archives?.offer_archived_count} / ${offers}`}
              </span>
            </OverlayTrigger>
            : <span>{offers}</span>
          : 0,
    },
    {
      label: "DOCUMENT.CREDIT_TITLE",
      icon: <Icon.Document />,
      content: credits ?? 0,
    },
    {
      label: "DOCUMENT.WAYBILL_TITLE",
      icon: <Icon.Document />,
      content: waybills ?? 0,
    },
    {
      label: "SHIPPING.TITLE",
      icon: <Icon.Truck />,
      content:
        shipping?.total ? (
          <OverlayTrigger placement="top" overlay={
            <Tooltip id="shipping-tooltip">
              {shipping?.services?.map((item, index) => (
                <ul key={index}>
                  <li className='text-left pb-0'>
                    {item.service + ": " + item.count}
                  </li>
                </ul>
              ))}
            </Tooltip>
          }>
            <span>
              {shipping?.total}
            </span>
          </OverlayTrigger>
        ) : 0,
    },
    {
      label: "PRODUCT.TITLE_PLURAL",
      icon: <Icon.BoxSeam />,
      content: products ?? 0,
    },
    {
      label: "CUSTOMER.TITLE_PLURAL",
      icon: <Icon.Customer />,
      content: customers ?? 0,
    },
    {
      label: "SUPPLIER.TITLE",
      icon: <Icon.Buildings />,
      content: suppliers ?? 0,
    },
    {
      label: "SUPPORT.TITLE",
      icon: <Icon.ChatLeft />,
      content:
        supports?.support_count ? (
          <OverlayTrigger placement="top" overlay={
            <Tooltip id="support-tooltip">
              <ul className="list-unstyled mb-0">
                {supports?.support_status?.open && (
                  <li className='text-left pb-0'>
                    {intl({ id: 'GENERAL.OPEN' }) + ": " + supports?.support_status?.open}
                  </li>
                )}
                {supports?.support_status?.reopened && (
                  <li className='text-left pb-0'>
                    {intl({ id: 'GENERAL.REOPENED' }) + ": " + supports?.support_status?.reopened}
                  </li>
                )}
                {supports?.support_status?.completed && (
                  <li className='text-left pb-0'>
                    {intl({ id: 'GENERAL.COMPLETED' }) + ": " + supports?.support_status?.completed}
                  </li>
                )}
                {supports?.support_status?.cancelled && (
                  <li className='text-left pb-0'>
                    {intl({ id: 'GENERAL.CANCELLED' }) + ": " + supports?.support_status?.cancelled}
                  </li>
                )}
                {supports?.support_status?.archived && (
                  <li className='text-left pb-0'>
                    {intl({ id: 'GENERAL.ARCHIVED' }) + ": " + supports?.support_status?.archived}
                  </li>
                )}
              </ul>
            </Tooltip>
          }>
            <span>
              <span className='text-danger'>
                {(supports?.support_status?.open || supports?.support_status?.reopened) && (supports?.support_status?.open ?? 0) + (supports?.support_status?.reopened ?? 0)}
              </span> / {supports?.support_count}
            </span>
          </OverlayTrigger>
        ) : 0,
    },
    {
      label: "GENERAL.TRANSACTIONS",
      icon: <Icon.FileText />,
      content: transactions ?? 0,
    },
    {
      label: "GENERAL.NUMBER_OF_API",
      icon: <Icon.Connection />,
      content:
        connection_count ? (
          <OverlayTrigger placement="top" overlay={
            <Tooltip id="api-tooltip">
              {['marketplace', 'shop', 'shipper'].map((type, index) => (
                <React.Fragment key={type}>
                  {connections
                    ?.filter((item) => item.type === type)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => (
                      <li key={item.name} className="text-left pb-0">
                        {item.name}
                      </li>
                    ))}
                  {index < 2 && <span className="text-left pb-0">&nbsp;</span>}
                </React.Fragment>
              ))}
            </Tooltip>
          }>
            <span>
              {connection_count ?? 0}
            </span>
          </OverlayTrigger>
        ) : 0,
    },
  ];


  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader icon={<Icon.Clipboard2Data />} title={<FormattedMessage id="GENERAL.USAGE_REPORTS" />} />

      <CardBody className="px-0 pt-0">
        <Table responsive className="table-head-custom table-head-bg table-vertical-center">
          <thead>
            <tr className="text-left text-uppercase">

              <th className="rounded-0 pl-5 pl-md-10" style={{ width: "40%" }}>
                <FormattedMessage id="ADMIN.USER.TYPE_OF_USAGE" />
              </th>

              <th className="rounded-0 text-right pr-5 pr-md-10" style={{ width: "60%" }}>
                <FormattedMessage id="GENERAL.COUNT" />
              </th>

            </tr>
          </thead>

          <tbody>
            {USAGE_REPORTS.map((data, index) =>
              <tr key={index}>

                <td className='text-left pl-5 pl-md-10'>
                  <div className="d-flex align-items-center">
                    <div className="text-center svg-icon svg-icon-md text-dark-50 mr-4">
                      {data?.icon}
                    </div>
                    <div>
                      <div className="font-weight-bolder text-dark-75 font-size-lg">
                        {<FormattedMessage id={data?.label} />}
                      </div>
                    </div>
                  </div>
                </td>

                <td className="text-right pr-5 pr-md-10">
                  <div className="text-dark-75 font-weight-bolder font-size-lg">
                    {data?.content}
                  </div>
                </td>

              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>

    </Card>
  )
}
