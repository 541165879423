import React from 'react';
import UserInfoDropdown from '_metronic/_partials/user-info/UserInfoDropdown';
import clsx from 'clsx';



export function Checkbox({
  id,
  form,
  field,
  label,
  size = "md",
  info,
  parentClass,
  withFeedbackLabel,
  feedbackLabel,
  disabled,
  ...props
}) {
  return (
    <div className='d-flex'>

      <label className={clsx(`checkbox checkbox-${size} checkbox-single mb-3`, parentClass)}>
        <input type="checkbox" checked={field.value} {...field} {...props} id={`checkbox_${id ?? field.name}`} disabled={disabled} />
        <span />
        <div className="d-flex align-items-center font-weight-bold ml-2">
          {label}
        </div>
      </label>

      {info && <UserInfoDropdown size="w-75" description={info} />}

    </div>
  );
}
