import React, { useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { usePdfMaker, usePreventPrintKeyHandler } from 'app/hooks';
import { useEmailLogsUIContext } from '../../_context/EmailLogsUIContext';
import { DocumentActions } from 'app/modules/documents/_redux/actions';
import { PdfViewer } from '_metronic/_partials/components';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { Portal } from 'react-portal';



export function PrintDocumentDialog() {

  // Prevent (ctrl+p) from opening print dialog in browser (Chrome)
  usePreventPrintKeyHandler();
  const dispatch = useDispatch();

  const { documentPDF, loading } = useSelector(state => ({
    documentPDF: state.documents.documentPDF,
    loading: state.documents.printLoading,
    // labels: state.shippings.labels,
  }), shallowEqual);

  const UIContext = useEmailLogsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showPrintDocumentDialog,
    onHide: UIContext.closePrintDocumentDialog,
  }), [
    UIContext.showPrintDocumentDialog,
    UIContext.closePrintDocumentDialog,
  ]);

  const [pdf, error, resetPdfMaker] = usePdfMaker(documentPDF);

  const onHideHandler = () => {
    dispatch(DocumentActions.cleanStatesInStore({ documentPDF: {} }));
    resetPdfMaker();
    UIProps.onHide();
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={onHideHandler} keyboard={false} aria-labelledby="example-modal-sizes-title-lg" size="xl">

        <Modal.Header className="py-3">
          <Modal.Title>
            <FormattedMessage id="GENERAL.PRINT_PREVIEW" />
          </Modal.Title>
          <CloseButton onClick={onHideHandler} />
        </Modal.Header>

        {loading
          ? <div className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
            <div className='spinner spinner-primary spinner-xl mr-16' />
          </div>
          : pdf
            ? <PdfViewer url={pdf} />
            : <div className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <h3 className='text-muted'> {error ?? <FormattedMessage id="GENERAL.DOCUMENT_NOT_FOUNT" />}</h3>
            </div>
        }

      </Modal>
    </Portal>
  );
}
