import React, { useRef, useMemo, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SHIPPER_LABEL_OPTIONS } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { useUserSettingsUIContext } from '../../_context/UserSettingsUIContext';
import { INITIAL_DEFAULT_SHIPPING } from '../../_context/SettingsHelper';
import { maxValidation, minValidation, UTILS } from '_metronic/_helpers';
import { ShippingSettingsForm } from '../ShippingSettingsForm';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { UserInfoDropdown } from '_metronic/_partials';
import { SettingsActions } from '../../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';



export function ShippingSettingsDialog() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showShippingSettingsDialog,
    onHide: UIContext.closeShippingSettingsDialog,
    shippingId: UIContext.shippingId,
  }),
    [UIContext]);
  //delivery address değişirse güncel addresi gönder
  const { id: settingsId, settings_type, default_shipping } = useSelector(state => state.settings.shippingSettings, shallowEqual);
  const [toggleLabels, setToggleLabels] = useState(true);
  const foundShipping = default_shipping?.find(item => item?.id === UIProps?.shippingId);

  useEffect(() => {
    if (UIProps.shippingId && foundShipping) {
      setToggleLabels(!foundShipping.unlabeled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.shippingId, foundShipping]);



  const validationSchema = Yup.object().shape({
    service: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' })),
    profile_name: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' }))
      .max(30, maxValidation("30"))
      .min(3, minValidation("3"))
      .test('unique-profile-name', intl({ id: 'SETTINGS.DOCUMENT.SHIPPING.PROFILE_NAME_UNIQUE' }), function (value) {
        // const { service } = this.parent; 
        if (UIProps.shippingId) {
          return true;
        }
        const isProfileNameUnique = default_shipping?.every(item => item.profile_name?.toLowerCase() !== value?.toLowerCase());
        // const isProfileNameUnique = default_shipping?.every(item => { //eger baska servislerde ayni profil ismi girebilsin istenirse
        //   return item.service !== service || item.profile_name?.toLowerCase() !== value?.toLowerCase();
        // });
        return isProfileNameUnique;
      }),
    price: Yup.number()
      .min(0, intl({ id: 'PRODUCT.GENERAL.PRICE_THAN' }))
      .max(999999, intl({ id: 'DOCUMENT.SHIPPING.MAX_PRICE' })),
    weight: Yup.number()
      .transform((originalValue, originalObject) => {
        const cleanedValue = originalValue ? originalValue.toString().replace(',', '.') : originalValue;
        return isNaN(cleanedValue) ? undefined : parseFloat(cleanedValue);
      })
      .required(intl({ id: 'GENERAL.REQUIRED' }))
      .min(0.001, intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.LESS_THAN_NOT_0001' }))
      .max(1000, intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.GREATER_THAN_NOT_1000' }))
      .test('no-trailing-zeros', intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.TRAILING_ZEROS' }), function (value) {
        if (value) {
          const weightString = value.toString();
          return !weightString.includes('.') || !weightString.split('.')[1].endsWith('0');
        }
        return true;
      })
      .test('max-decimal-places', intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.DECIMAL_PLACES' }), function (value) {
        if (value) {
          const decimalPlaces = (value.toString().split('.')[1] || '').length;
          return decimalPlaces <= 3;
        }
        return true;
      })
      .when(['service'], {
        is: (service) => (service === 'dhl_warenpost' || service === 'dhl_new_warenpost'),
        then: Yup.number()
          .typeError(intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.MUST_A_NUMBER' }))
          .max(1, intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.GREATER_THAN_1' })),
      }),
    dp_product_id: Yup.string()
      .when('service', {
        is: (service) => toggleLabels && service === 'deutsche_post',
        then: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' })),
        otherwise: Yup.string(),
      }).nullable(),
  });

  const submitByRef = useRef();
  const submitByRefClick = () => {
    submitByRef.current && submitByRef.current.click();
  };


  const saveShipping = (values) => {

    const updateExistingShipping = (values) => {
      const updatedShippingList = default_shipping.map(item =>
        item.id === values.id
          ? { ...values, updated_at: new Date() } // updated_at alanını ekle
          : { ...item, default: values.default ? false : item.default }
      );
      dispatch(SettingsActions.patchUserSettings(settingsId, settings_type, { default_shipping: updatedShippingList }));
    };


    const addNewShipping = (values) => {
      const { id, ...rest } = values;
      const newValues = {
        id: UTILS.uuidv4(),
        ...rest,
        created_at: new Date(),
      };

      // Eğer yeni eklenen gönderim "default" ise, mevcut tüm gönderimleri "default: false" olarak güncelle
      const updatedDefaultShipping = newValues.default
        ? default_shipping.map(item => ({ ...item, default: false }))
        : default_shipping;

      if (settingsId && settings_type === 'shipping') {
        const defaultShipping = [...updatedDefaultShipping, newValues];
        if (toggleLabels) {
          dispatch(SettingsActions.patchUserSettings(settingsId, settings_type, { default_shipping: defaultShipping }));
        } else {
          dispatch(SettingsActions.patchUserSettings(settingsId, settings_type, { default_shipping: [...updatedDefaultShipping, { ...newValues, unlabeled: true }] }));
        }
      } else {
        if (toggleLabels) {
          const labeledShipping = {
            settings_type: 'shipping',
            default_shipping: [newValues]
          };
          dispatch(SettingsActions.createUserSettings(labeledShipping));
        } else {
          const unLabeledShipping = {
            settings_type: 'shipping',
            default_shipping: [{ ...newValues, unlabeled: true }]
          };
          dispatch(SettingsActions.createUserSettings(unLabeledShipping));
        }
      }
    };

    if (UIProps.shippingId) {
      const { id, ...rest } = values;
      const updatedValues = {
        ...rest,
        id: UIProps.shippingId,
      };
      updateExistingShipping(updatedValues);
    } else {
      addNewShipping(values);
    }

    UIProps.onHide();
  };


  return (
    <Modal show={UIProps.show} onHide={UIProps.onHide} size="md" backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>

      <Modal.Header className='py-3'>

        <Modal.Title>
          <FormattedMessage id='SETTINGS.DOCUMENT.SHIPPING.DEFAULT_SHIPPING' />
          <UserInfoDropdown description={<FormattedMessage id='SETTINGS.DOCUMENT.SHIPPING.DEFAULT_SHIPPING.INFO' />} />
        </Modal.Title>

        <Dropdown className="dropdown-inline" drop="down">

          <Dropdown.Toggle variant="outline-primary" size='sm' className="font-weight-bold text-left" id="dropdown-service">
            <span className="d-none d-sm-inline">
              <FormattedMessage id={toggleLabels ? SHIPPER_LABEL_OPTIONS[0]?.label : SHIPPER_LABEL_OPTIONS[1]?.label} />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu id="dropdown_service_options" className="w-100">
            <ul className="navi navi-hover">
              {SHIPPER_LABEL_OPTIONS?.map((opt, index) => (
                <li className="navi-item" key={index}>
                  <Dropdown.Item className="navi-link" onClick={() => setToggleLabels(opt.value === "labeled")} id={`dropdown_item_${opt.label}`}>
                    <span className="navi-text text-nowrap font-weight-bold">
                      {opt.label}
                    </span>
                  </Dropdown.Item>
                </li>
              ))}
            </ul>
          </Dropdown.Menu>

        </Dropdown>

      </Modal.Header>

      <Modal.Body>

        <Formik enableReinitialize={true} initialValues={UIProps.shippingId ? foundShipping : INITIAL_DEFAULT_SHIPPING} validationSchema={validationSchema} onSubmit={saveShipping}>

          <Form autoComplete="off">

            <ShippingSettingsForm toggleLabels={toggleLabels} shippingId={UIProps.shippingId} />
            <button type="submit" className='d-none' ref={submitByRef} />

          </Form>
        </Formik>

      </Modal.Body>

      <Modal.Footer>

        <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant='primary' className="ml-2" onClick={submitByRefClick} id="btn_save_modal">
          <FormattedMessage id="GENERAL.SAVE" />
        </Button>

      </Modal.Footer>

    </Modal>
  );
}
