import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Form as BootstrapForm, Modal, Row } from 'react-bootstrap';
import { useUserUIContext } from '../../_context/UserUIContext';
import { SVFormTextarea } from '_metronic/_partials/controls';
import { AdminActions } from 'app/admin/_redux/actions';
import { maxValidation } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';



export function NoteEditDialog() {

  const dispatch = useDispatch();

  const UIContext = useUserUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showUserNoteDialog,
    onHide: UIContext.closeUserNoteDialog,
    user: UIContext.user,
  }), [UIContext]);

  const validationSchema = Yup.object().shape({
    note: Yup.string()
      .max(2000, maxValidation("2000"))
  });

  const handleSubmit = (values) => {
    dispatch(AdminActions.updateUser(UIProps.user.id, values));
    UIProps.onHide();
  };


  return (
    <Modal size="md" show={UIProps.show} onHide={UIProps.onHide} keyboard={false} aria-labelledby="example-modal-sizes-title-lg" centered>

      <Modal.Header>
        <Modal.Title>
          <Icon.Clipboard className="mr-3" />
          <FormattedMessage id="DOCUMENT.TABLE.COLUMN.NOTE" />
        </Modal.Title>
      </Modal.Header>

      <Formik enableReinitialize={true} initialValues={{ note: UIProps?.user?.note || '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ submitForm, values }) => (
          <BootstrapForm>
            <Modal.Body>
              <Row>
                <Col lg="12">
                  <Field component={SVFormTextarea}
                    {...{
                      name: "note",
                      size: "lg",
                      rows: 6,
                      maxlength: 10,
                      withFeedbackLabel: false,
                      feedbackLabel: (
                        <span className={`${values?.note?.length <= 1600 ? 'text-success' : values?.note?.length <= 2000 ? 'text-warning' : 'text-danger'}`}>
                          <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: 2000 - values?.note?.length }} />
                        </span>
                      ),
                    }}
                  />
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
                <FormattedMessage id="GENERAL.CANCEL" />
              </Button>

              <Button variant='primary' type="button" id='btn_update_modal' onClick={submitForm}>
                <FormattedMessage id="GENERAL.SAVE" />
              </Button>
            </Modal.Footer>
          </BootstrapForm>
        )}
      </Formik>

    </Modal>
  );
}
