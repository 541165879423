import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { DigitalProductsUIProvider } from './_context/DigitalProductsUIContext';
import ProductsKeys from './product-keys/ProductsKeys';
import InvoicesToEbay from './invoices-to-ebay/InvoicesToEbay';
import { ContentRoute } from '_metronic/layout';



export default function DigitalProducts() {
  return (
    <DigitalProductsUIProvider>

      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.DIGITAL_PRODUCT} />
        <ContentRoute path={ModuleRoutes.DIGITAL_PRODUCT_KEYS} component={ProductsKeys} />
        <ContentRoute path={ModuleRoutes.DIGITAL_PRODUCT_INVOICE} component={InvoicesToEbay} />
      </Switch>

    </DigitalProductsUIProvider>
  );
}