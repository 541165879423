import React, { useMemo, useState } from 'react';
import { ProductKeysGrouping } from './ProductKeysGrouping';
import { SearchControl } from '_metronic/_partials/controls';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { SelectCounter } from '_metronic/_partials';
import { useProductEditUIContext } from 'app/modules/products/_context/ProductEditUIContext';
import { FormattedMessage } from 'react-intl';
import { KEY_STATUS } from 'app/modules/digital-product/_context/DigitalProductsUIHelpers';
import { Icon } from '_metronic/_icons';
import { theme } from '_metronic/_helpers';



export function ProductKeysFilter({ handleSearch, setFilterQuery }) {

  const [search, setSearch] = useState('');
  const [filterParamsBase, setFilterParamsBase] = useState({
    key_status: null,
  });

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    keyIds: UIContext.keyIds,
    openDeleteProductKeysDialog: UIContext.openDeleteProductKeysDialog,
    setKeyStatus: UIContext.setKeyStatus,
  }), [UIContext.openDeleteProductKeysDialog, UIContext.keyIds, UIContext.setKeyStatus]);

  const handleSearchKeys = (value) => {
    setSearch(value);
    handleSearch(value);
  }

  const selectedKeyStatus = KEY_STATUS.find(item => item.value === filterParamsBase.key_status);

  return (
    <Row className='mb-3'>

      <Col md="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearchKeys} />
      </Col>

      <Col md="8" className='d-flex align-items-start px-md-0'>

        {/* ----------------------------------------Key status filter start----------------------------------------------- */}
        <Dropdown className="dropdown-inline symbol mr-2 mb-2" drop="down">
          <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-tag">
            <span className="svg-icon svg-icon-sm mr-3">{selectedKeyStatus?.icon ?? <Icon.Sliders />}</span>
            {selectedKeyStatus?.label ?? <FormattedMessage id="PRODUCT.KEYS.KEY_STATUS" />}
          </Dropdown.Toggle>

          {filterParamsBase?.key_status && <i className="symbol-badge bg-danger"></i>}

          <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down' id='dropdown_document_tag'>
            <ul className="navi navi-hover">
              {KEY_STATUS.map((status, index) => (
                <li className="navi-item" key={index}>
                  <Dropdown.Item className='navi-link font-weight-bold' onClick={() => { UIProps.setKeyStatus(status.value); setFilterParamsBase({key_status:status.value}) }} id={`key_status_${status.value}`}>
                    <span className="svg-icon svg-icon-md mr-2">
                      {status.icon}
                    </span>
                    <span className="navi-text">
                      {status.label}
                    </span>
                  </Dropdown.Item>
                </li>
              ))}

              <Dropdown.Divider />
              <li className="navi-item">
                <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' onClick={() => {UIProps.setKeyStatus('');setFilterParamsBase({key_status:null})}} id='clear_key_status_filter' >
                  <FormattedMessage id='GENERAL.CLEAR' />
                </Dropdown.Item>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>

        {/* ----------------------------------------Key status filter ended----------------------------------------------- */}
        <SelectCounter count={UIProps.keyIds.length} />

        <ProductKeysGrouping UIProps={UIProps} />
      </Col>

    </Row>
  );
}
