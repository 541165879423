import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeaderToolbar, CardSubHeader } from '_metronic/_partials/controls';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ACCOUNT_STATUS } from 'app/admin/_context/AdminUIHelper';
import { CardItems } from '_metronic/_partials/components';
import { SettingsWarningDialog } from './SettingsDialog';
import { GeneralEditDialog } from './GeneralEditDialog';
import { LicenseBadge } from '_metronic/_partials';
import { Icon } from '_metronic/_icons';



export function General({ user, connections }) {

  const [show, setShow] = useState(false)

  const accountStatus = ACCOUNT_STATUS?.find(item => item.value === user.status);

  const [action, setAction] = useState(false);

  const EbayConnections = connections?.filter(item => item.name === 'ebay');

  const onHandleSubmitTrading = (connection) => {
    setAction({ show: true, type: 'migration', connection });
  };

  const handleCheckbox = (e) => {
    setAction({ show: true, type: 'cron', checked: e.target.checked });
  };

  const onHide = () => {
    setAction({ show: false, type: "", checked: false, connection: {} });
  };


  const USER_INFO = [
    {
      label: "AUTH.REGISTER.NAME",
      content: user.name ?? '',
    },
    {
      label: "AUTH.REGISTER.SURNAME",
      content: user.surname ?? '',
    },
    {
      label: "ADMIN.USER.ACCOUNT_STATUS",
      content: <label className={`label label-inline label-lg label-rounded label-outline-${accountStatus?.className} font-weight-bold`}>
        <span className={`label label-xl label-dot label-${accountStatus?.className} mr-2`} />
        {accountStatus?.label ?? ''}
      </label>,
    },
    {
      label: "ADMIN.USER.LICENSE_TYPE",
      content: <LicenseBadge license={user?.license} />,
    },
    {
      label: "Cron",
      info: "ADMIN.SETTINGS.CRONJOB_INFO",
      content: (
        <label className="switch switch-sm switch-icon d-flex align-items-center" key="cron">
          <input type="checkbox" name="cron" id="cron" checked={user.cron !== undefined ? user.cron : true} onChange={(e) => handleCheckbox(e)} />
          <span />
        </label>
      ),
    },
    ...(EbayConnections && EbayConnections.length > 0 ? [{
      label: "Ebay Migration",
      info: "ADMIN.SETTINGS.EBAY_MIGRATIONS_WARNING",
      content: (
        EbayConnections.map((item, i) => (
          <Button key={i} variant="light-primary" size='sm' className="svg-icon svg-icon-sm font-weight-bold mr-2 mb-2" onClick={() => onHandleSubmitTrading(item)} id={`btn_sync_${i}`}>
            <Icon.ArrowRepeat className="mr-2" />
            {item.market_name}
          </Button>
        ))
      ),
    }] : []),
  ];


  return (
    <>
      <Card className="card-stretch gutter-b">

        <CardSubHeader icon={<Icon.Tag />} title={<FormattedMessage id="GENERAL.GENERAL" />}>
          <CardHeaderToolbar>
            <OverlayTrigger overlay={<Tooltip id="document-print-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
              <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={() => setShow(true)} id="btn_edit_general">
                <Icon.PencilSquare />
              </Button>
            </OverlayTrigger>
          </CardHeaderToolbar>
        </CardSubHeader>

        <CardBody>
          <CardItems Content={USER_INFO} />
        </CardBody>

      </Card>

      <GeneralEditDialog show={show} onHide={() => setShow(false)} user={user} />

      <SettingsWarningDialog userId={user.id} action={action} onHide={onHide} />

    </>
  );
}