import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Card, CardBody, CardSubHeader, Checkbox, DropDownControl, RadioControl, SVFormControl } from '_metronic/_partials/controls'
import { DATE_OPTION, EXPORT_OPTIONS, TYPE_OPTIONS } from '../_context/ImportExportUIHelper'
import { FormattedMessage, useIntl } from 'react-intl'
import { importExportActions } from '../_redux/actions'
import { Spinner } from "_metronic/_partials";
import { Field, Formik, Form } from 'formik'
import { UIDates } from '_metronic/_helpers'
import { useLang } from '_metronic/i18n';
import { Button } from 'react-bootstrap'
import { Icon } from '_metronic/_icons';
import * as Yup from "yup";



const initialValues = {
  export_select: "",
  date_range: "none",
  include_archived: true,
  file_type: "csv",
}


const INPUT = [
  {
    name: "export_select",
    label: 'IMPORT_EXPORT.EXPORT.SELECT_TYPE',
    options: EXPORT_OPTIONS,
    component: DropDownControl,
    required: true
  },
  {
    name: "date_range",
    label: 'IMPORT_EXPORT.EXPORT.SELECT_DATE_RANGE',
    options: DATE_OPTION,
    component: DropDownControl,
  },
  {
    name: "date_range.start_date",
    label: 'IMPORT_EXPORT.EXPORT.START_DATE',
    type: "date",
    max: UIDates.getCurrentDate(),
    required: true
  },
  {
    name: "date_range.end_date",
    label: 'IMPORT_EXPORT.EXPORT.END_DATE',
    type: "date",
    max: UIDates.getCurrentDate(),
    required: true
  },
  {
    name: "include_archived",
    type: "checkbox",
    component: Checkbox,
    label: 'IMPORT_EXPORT.EXPORT.INCLUDE_ARCHIVED',
    // info: 'IMPORT_EXPORT.EXPORT.INCLUDE_ARCHIVED_INFO',
  },
  {
    name: "file_type",
    label: "IMPORT_EXPORT.FILE_TYPE",
    component: RadioControl,
    options: TYPE_OPTIONS,
    required: true,
  },
]



export default function ExportSelect({ loading, queryParams }) {

  const locale = useLang();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();


  const handleSubmit = ({ export_select, date_range, include_archived, file_type }) => {

    const dateRange = date_range === "none"
      ? { title: "none", start_date: "", end_date: "" } :
      date_range

    dispatch(importExportActions.getExport({
      export_select,
      file_type: file_type,
      date_range: dateRange,
      language: locale,
      ...(['offers', 'orders'].includes(export_select) && {
        include_archived
      }),
      queryParams
    }));

  }

  const validationSchema = Yup.object().shape({
    export_select: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' })),
    // date_range: Yup.object().shape({
    //   start_date: Yup.string()
    //     .required(intl({ id: 'GENERAL.REQUIRED' })),
    //   end_date: Yup.string()
    //     .required(intl({ id: 'GENERAL.REQUIRED' })),
    // }),
  });

  useEffect(() => {
    return () => {
      dispatch(importExportActions.cleanUpReducer());
    };
  }, [dispatch]);


  return (
    <Card>
      <CardSubHeader title={<FormattedMessage id='IMPORT_EXPORT.EXPORT_SETTINGS' />} icon={<Icon.Settings />} />

      <CardBody>
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values }) => (

            <Form className='d-flex flex-column'>
              {INPUT.map((item, i) => (
                values.export_select === 'categories' && ["date_range.start_date", "date_range.end_date", 'date_range'].includes(item.name) ? null :
                  !(values.date_range.title !== "custom" && ["date_range.start_date", "date_range.end_date"].includes(item.name)) && (
                    item.name === 'include_archived' && !['orders', 'offers'].includes(values.export_select) ? null :
                      item.name === 'file_type' && values.export_select !== 'invoices' ? null :
                      <Field component={item.component ?? SVFormControl} key={i}
                        {...{
                          name: item.name,
                          type: item.type,
                          label: intl({ id: item.label }),
                          options: item.options,
                          max: item.max,
                          withFeedbackLabel: true,
                          feedbackLabel: true,
                          required: item.required,
                        }}
                      />
                  )
              ))}

              <Button variant='primary' type="submit" className='svg-icon font-weight-bold mt-3' disabled={loading} id='btn_export'>
                <FormattedMessage id="IMPORT_EXPORT.EXPORT" />
                <Spinner loading={loading} />
              </Button>

            </Form>

          )}
        </Formik>
      </CardBody>

    </Card>
  )
}
