import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsActions } from '../_redux/actions';
import { StandardSettings } from './StandardSettings';
import VariantSettings from './VariantSettings';
import { StockControl } from './StockControl';
import { Row, Col } from 'react-bootstrap';
import { Form, Formik } from 'formik';



export function ProductSettings({ productBtnRef }) {

  const dispatch = useDispatch();
  const productSettings = useSelector(state => state.settings.productSettings);

  const handleSubmit = (values) => {

    const { default_product, stock_control, variant_options } = values;

    const newValues = {
      settings_type: productSettings.settings_type,
      default_product,
      stock_control,
      variant_options
    };

    productSettings.id
      ? dispatch(SettingsActions.updateUserSettings(productSettings.id, newValues))
      : dispatch(SettingsActions.createUserSettings(newValues))
  };

  return (
    <>
      <Formik enableReinitialize={true} initialValues={productSettings} onSubmit={handleSubmit}>
        <Form>

          <Row>
            <Col lg={6}>
              <StandardSettings />
            </Col>
            <Col lg={6}>
              <StockControl />
            </Col>
          </Row>

          <button type="submit" className='d-none' ref={productBtnRef} />

        </Form>
      </Formik>

      <VariantSettings />
    </>
  );
}
