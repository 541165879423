import { API } from 'constants/apiUrl';
import { paginationParams, searchParams } from '../_helpers/query-params';
import { searchKeys } from './params';



export const shippingsMiddleware = {


  deleteShipping: async function (accessToken, shippingId) {
    return await API.delete(`/shipping/all/${shippingId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getShippings: async function (accessToken, { pagination, search, filter, order }) {
    const {date_from, date_to, connections, attrib, ...rest} = filter; 
    return await API.get(`/shipping`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            tracking_id: { neq: "" },
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...((date_from && date_to) && {
              created_at: { between: [`${date_from}T00:00:00.001Z`, `${date_to}T23:59:59.999Z`] }
            }),
            ...((connections && Object.keys(connections).length > 0) && {
              service: { inq: Object.keys(connections) },
            }),
            ...((attrib && Object.keys(attrib).length > 0) && {
              ...(attrib.api && !attrib.manuel && { unlabeled: { exists: false } }),
              ...(attrib.manuel && !attrib.api && { unlabeled: true }),
              ...(attrib.send && !attrib.return && { type: 'shipment' }),
              ...(attrib.return && !attrib.send && { type: 'return' }),
            }),
            ...rest,
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getShipping: async function (accessToken, shippingIds, type) {
    return await API.get(`/shipping/${shippingIds}${type ? `?type${type}` : ''}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCount: async function (accessToken, { search, filter }) {
    const {date_from, date_to, connections, attrib, ...rest} = filter;
    return await API.get(`/shipping/count`, {
      params: {
        where: {
          tracking_id: { neq: "" },
          ...(search && {
            ...searchParams(searchKeys, search),
          }),
          ...((date_from && date_to) && {
            created_at: { between: [`${date_from}T00:00:00.001Z`, `${date_to}T23:59:59.999Z`] }
          }),
          ...((connections && Object.keys(connections).length > 0) && {
            service: { inq: Object.keys(connections) },
          }),
          ...((attrib && Object.keys(attrib).length > 0) && {
            ...(attrib.api && !attrib.manuel && { unlabeled: { exists: false } }),
            ...(attrib.manuel && !attrib.api && { unlabeled: true }),
            ...(attrib.send && !attrib.return && { type: 'shipment' }),
            ...(attrib.return && !attrib.send && { type: 'return' }),
          }),
          ...rest,
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};