import { INITIAL_CUSTOMER } from '../_context/CustomersUIHelpers';
import ActionTypes from './actionTypes';


const INITIAL_STATE = {
  loading: false,
  entities: [],
  countCustomers: 0,
  countDocuments: {},
  totalCustomers: 0,
  customerForEdit: INITIAL_CUSTOMER,
  customer: {},
  findCustomerForMatching: '',
  error: '',
  success: false
};



export function customersReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    // Get Customers
    case ActionTypes.GET_CUSTOMERS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: payload.customers,
        countCustomers: payload.count.count,
        totalCustomers: payload.count.total,
        marketplaces: payload.count.filter,
      };

    case ActionTypes.GET_CUSTOMERS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };



    // Get Customer By Id
    case ActionTypes.GET_CUSTOMER_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_CUSTOMER_BY_ID_SUCCESS: {
      const customer = payload.customer;
      const address = payload.customer.address;

      if (customer.active === false) {
        return {
          ...state,
          loading: false,
          customerForEdit: customer,
        };
      }

      return {
        ...state,
        loading: false,
        customerForEdit: {
          ...customer,
          address
        },
      };
    }

    case ActionTypes.GET_CUSTOMER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };



    // Find Customer Property
    case ActionTypes.FIND_CUSTOMER_BY_PROPERTY:
      return {
        ...state,
        findPropertyLoading: true,
      };

    case ActionTypes.FIND_CUSTOMER_BY_PROPERTY_SUCCESS:
      return {
        ...state,
        findPropertyLoading: false,
        findCustomerForMatching: payload.customer,
      };

    case ActionTypes.FIND_CUSTOMER_BY_PROPERTY_FAIL:
      return {
        ...state,
        findPropertyLoading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };



    // Delete Customer
    case ActionTypes.DELETE_CUSTOMER:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: state.entities.filter(customer => !payload.ids.includes(customer.id)),
        countCustomers: state.countCustomers - payload?.ids.length,
        totalCustomers: state.totalCustomers - payload?.ids.length,
      };

    case ActionTypes.DELETE_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: 'CUSTOMER.ERROR_MESSAGE_DELETE',
      };



    // Create Customer
    case ActionTypes.CREATE_CUSTOMER:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ActionTypes.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        entities: [
          payload.customer,
          ...state.entities,
        ],
        countCustomers: state.countCustomers + 1,
        totalCustomers: state.totalCustomers + 1,
      };

    case ActionTypes.CREATE_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: 'CUSTOMER.ERROR_MESSAGE_CREATE',
      };



    // Update Customer
    case ActionTypes.UPDATE_CUSTOMER:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ActionTypes.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case ActionTypes.UPDATE_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: 'CUSTOMER.ERROR_MESSAGE_UPDATE',
      };



    // Get Documents
    // case ActionTypes.GET_DOCUMENTS_BY_CUSTOMER_ID:
    //   return {
    //     ...state,
    //     documentListLoading: true,
    //   };

    // case ActionTypes.GET_DOCUMENTS_BY_CUSTOMER_ID_SUCCESS:
    //   return {
    //     ...state,
    //     documentListLoading: false,
    //     documents: payload.documents,
    //     countDocuments: payload.count,
    //   };

    // case ActionTypes.GET_DOCUMENTS_BY_CUSTOMER_ID_FAIL:
    //   return {
    //     ...state,
    //     documentListLoading: false,
    //     error: 'GENERAL.ERROR_MESSAGE_GENERAL',
    //   };

    // Get Customers
    case ActionTypes.GET_CUSTOMER_DOCUMENT_COUNTS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_CUSTOMER_DOCUMENT_COUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        countDocuments: payload.counts,
      };

    case ActionTypes.GET_CUSTOMER_DOCUMENT_COUNTS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };


    // Reset Customer Form
    case ActionTypes.RESET_CUSTOMER_FORM:
      return {
        ...state,
        customerForEdit: INITIAL_CUSTOMER,
        customer: {},
        success: false,
      };

    // Clean Customer Form
    case ActionTypes.CLEAN_CUSTOMER:
      return {
        ...state,
        entities: [],
      };

    // Clean Error Handler
    case ActionTypes.CLEAN_ERROR_HANDLER:
      return {
        ...state,
        error: '',
      };

    case ActionTypes.CLEAR_LOADING_EFFECTS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.CLEAN_STATES_IN_STORE:
      return {
        ...state,
        ...payload.cleanedStates,
      };

    default:
      return state;
  }
}
