import React, { createContext, useState, useContext, useCallback } from 'react';
import { UIDates } from '_metronic/_helpers';
import { useSearchQuery } from 'app/hooks';
import { useIntl } from 'react-intl';


const DashboardUIContext = createContext();
export const DashboardUIConsumer = DashboardUIContext.Consumer;
export const useDashboardUIContext = () => useContext(DashboardUIContext);



export function DashboardUIProvider({ children }) {

  const { formatMessage: intl } = useIntl()

  const toggleHandler = () => { setToggle(!toggle); };
  const [toggle, setToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  // query params
  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'id DESC',
    search: '',
    filter: {
      date_from: UIDates.dayCalculation(-30),
      date_to: UIDates.getCurrentDate(),
    },
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), [])

  const { setFilterQuery } = useSearchQuery(setQueryParams);

  // Date Range
  const initialDateRange = {
    id: "last-30-days",
    label: intl({ id: "DASHBOARD.DATE.LAST_30_DAYS" }),
    date_from: UIDates.dayCalculation(-30),
    date_to: UIDates.getCurrentDate(),
  };

  const [dateRange, setDateRange] = useState(initialDateRange);
  const changeDateRange = (date = initialDateRange) => setDateRange(date);


  const value = {
    isLoading,
    toggle,
    toggleHandler,
    setIsLoading,
    setFilterQuery,
    queryParams,
    setQueryBase,
    changeDateRange,
    dateRange,
    initialDateRange,
    setDateRange,
  };

  return (
    <DashboardUIContext.Provider value={value}>
      {children}
    </DashboardUIContext.Provider>
  );
}
