export default class ActionTypes {
  static get PREFIX() {
    return '[digitalProducts]';
  }


  // Get digital product keys action types
  static get GET_DIGITAL_PRODUCT_KEYS() {
    return `${ActionTypes.PREFIX}GET_DIGITAL_PRODUCT_KEYS`;
  }
  static get GET_DIGITAL_PRODUCT_KEYS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_DIGITAL_PRODUCT_KEYS_SUCCESS`;
  }
  static get GET_DIGITAL_PRODUCT_KEYS_FAIL() {
    return `${ActionTypes.PREFIX}GET_DIGITAL_PRODUCT_KEYS_FAIL`;
  }


  // Get digital product invoices action types
  static get GET_DIGITAL_PRODUCT_INVOICES() {
    return `${ActionTypes.PREFIX}GET_DIGITAL_PRODUCT_INVOICES`;
  }
  static get GET_DIGITAL_PRODUCT_INVOICES_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_DIGITAL_PRODUCT_INVOICES_SUCCESS`;
  }
  static get GET_DIGITAL_PRODUCT_INVOICES_FAIL() {
    return `${ActionTypes.PREFIX}GET_DIGITAL_PRODUCT_INVOICES_FAIL`;
  }


  static get GET_CUSTOMER_DETAILS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_DETAILS`;
  }
  static get GET_CUSTOMER_DETAILS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_DETAILS_SUCCESS`;
  }
  static get GET_CUSTOMER_DETAILS_FAIL() {
    return `${ActionTypes.PREFIX}GET_CUSTOMER_DETAILS_FAIL`;
  }


  static get SEND_MAIL_KEY() {
    return `${ActionTypes.PREFIX}SEND_MAIL_KEY`;
  }
  static get SEND_MAIL_KEY_SUCCESS() {
    return `${ActionTypes.PREFIX}SEND_MAIL_KEY_SUCCESS`;
  }
  static get SEND_MAIL_KEY_FAIL() {
    return `${ActionTypes.PREFIX}SEND_MAIL_KEY_FAIL`;
  }


  static get DELETE_KEY_RESERVE() {
    return `${ActionTypes.PREFIX}DELETE_KEY_RESERVE`;
  }
  static get DELETE_KEY_RESERVE_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_KEY_RESERVE_SUCCESS`;
  }
  static get DELETE_KEY_RESERVE_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_KEY_RESERVE_FAIL`;
  }

  /**
 * *Print Document
 * @return action types for print document
 */
  static get PRINT_DOCUMENT() {
    return `${ActionTypes.PREFIX}PRINT_DOCUMENT`;
  }
  static get PRINT_DOCUMENT_SUCCESS() {
    return `${ActionTypes.PREFIX}PRINT_DOCUMENT_SUCCESS`;
  }
  static get PRINT_DOCUMENT_FAIL() {
    return `${ActionTypes.PREFIX}PRINT_DOCUMENT_FAIL`;
  }
  /**
 * *Print Document
 * @return action types for print document
 */
  static get PREPARATION_DOCUMENT() {
    return `${ActionTypes.PREFIX}PREPARATION_DOCUMENT`;
  }
  static get PREPARATION_DOCUMENT_SUCCESS() {
    return `${ActionTypes.PREFIX}PREPARATION_DOCUMENT_SUCCESS`;
  }
  static get PREPARATION_DOCUMENT_FAIL() {
    return `${ActionTypes.PREFIX}PREPARATION_DOCUMENT_FAIL`;
  }

  static get CLEAR_LOADING_EFFECTS() {
    return `${ActionTypes.PREFIX}CLEAR_LOADING_EFFECTS`;
  }

  static get CLEAN_STATES_IN_STORE() {
    return `${ActionTypes.PREFIX}CLEAN_STATES_IN_STORE`;
  }

}