import React, { useMemo } from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls'
import { useUserSettingsUIContext } from '../../_context/UserSettingsUIContext'
import { UserInfoDropdown } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import { Icon } from '_metronic/_icons';



export default function PasswordSettings() {

  const userSettingsUIContext = useUserSettingsUIContext();

  const UIProps = useMemo(() => ({
    openPasswordEditDialog: userSettingsUIContext.openPasswordEditDialog,
  }),
    [userSettingsUIContext.openPasswordEditDialog]
  );


  return (
    <Card className='card card-stretch gutter-b'>

      <CardSubHeader
        title={<FormattedMessage id='SETTINGS.LOGIN.PASSWORD_TITLE' />}
        icon={<Icon.Password />}
        info={<UserInfoDropdown description={<FormattedMessage id='USER_GUIDE.SETTINGS.LOGIN_PASSWORD' />} />}
      />

      <CardBody>

        <div className='d-flex justify-content-between align-items-center'>

          <div className='d-flex flex-column mr-3'>
            <span className='font-weight-bolder mb-1'>
              <FormattedMessage id='AUTH.INPUT.PASSWORD' />
            </span>
            <span>
              ***********
            </span>
          </div>

          <Button variant='primary' size='sm' className='font-weight-bold' onClick={UIProps.openPasswordEditDialog}>
            <FormattedMessage id='SETTINGS.LOGIN.CHANGE_PASSWORD' />
          </Button>

        </div>

      </CardBody>

    </Card>
  )
}
