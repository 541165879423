import React from 'react';
import { InvoicesToEbayCard } from './InvoicesToEbayCard';
import { InvoicesToEbayDialog } from './invoices-to-ebay-dialog/InvoicesToEbayDialog';
import { SendEmailDialog } from './invoices-to-ebay-dialog/SendEmailDialog';
import { PrintDocument } from './invoices-to-ebay-dialog/PrintDocument';
import { SearchQueryProvider } from '../_context/SearchQueryProvider';



export default function InvoicesToEbay() {
  return (
    <SearchQueryProvider>
      <InvoicesToEbayCard />
      <PrintDocument />
      <SendEmailDialog />
      <InvoicesToEbayDialog />
    </SearchQueryProvider>
  );
}