import React from 'react';
import { SearchQueryProvider } from '../_context/SearchQueryProvider';
import { ProductsKeysCard } from './ProductsKeysCard';
import { CustomerDetailsDialog } from './product-keys-dialog/CustomerDetailsDialogs';
import { SendEmailsKeyDialog } from './product-keys-dialog/SendEmailsKeyDialog';
import { KeyReserveDeleteDialog } from './product-keys-dialog/KeyReserveDeleteDialog';



export default function ProductsKeys() {
  return (
    <SearchQueryProvider>
      <ProductsKeysCard />
      <CustomerDetailsDialog />
      <SendEmailsKeyDialog />
      <KeyReserveDeleteDialog />
    </SearchQueryProvider>
  );
}