import { API } from "constants/apiUrl";
import { paginationParams } from "middlewares/_helpers/query-params";



export const importExportMiddleware = {

  getImportExports: async function (accessToken, type, { pagination, filter, order, search }) {
    const { data_type, ...rest } = filter;

    return await API.get(`/transfer/${type}`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [order ? order : 'created_at DESC'],
          where: {
            ...(filter && {
              ...rest,
              ...(data_type !== 'all_types' && {
                module: data_type,
              }),
            }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getImportExportsCount: async function (accessToken, type, { pagination, filter, order, search }) {
    const { data_type, ...rest } = filter;

    return await API.get(`/transfer/${type}/count`, {
      params: {
        where: {
          ...(filter && {
            ...rest,
            ...(data_type !== 'all_types' && {
              module: data_type,
            }),
          }),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  importExample: async function (accessToken, data) {
    return await API.post(`/transfer/export-example`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getExport: async function ({ export_select, date_range, file_type, language, include_archived, ids }, accessToken) {
    return await API.post(`/transfer/export-${export_select}`, { date_range, ids, file_type }, {
      params: {
        language,
        include_archived
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  importExportDownload: async function (accessToken, id) {
    return await API.get(`/transfer/download/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  importProductCreate: async function (accessToken, data, language) {
    return await API.post(`/transfer/import-products`, data, {
      params: {
        language
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  importCustomerCreate: async function (accessToken, data, language) {
    return await API.post(`/transfer/import-customers`, data, {
      params: {
        language
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  importCategoryCreate: async function (accessToken, data, language) {
    return await API.post(`/transfer/import-categories`, data, {
      params: {
        language
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  checkData: async function (accessToken, module) {
    return await API.get(`/${module}/check`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  importExportDelete: async function (accessToken, id) {
    return await API.delete(`/transfer/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};