import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Importer, ImporterField, enUS, deDE, trTR } from 'react-csv-importer';
import { importExportActions } from '../../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { UTILS } from '_metronic/_helpers';
import { useLang } from '_metronic/i18n';
import { toast } from 'react-toastify';



export function ImportProduct() {

  const locale = useLang();
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();


  const langList = {
    "de": deDE,
    "en": enUS,
    "tr": trTR
  }

  const { error, loading, importPreparation } = useSelector(state => ({
    loading: state.importExport.loading,
    error: state.importExport.error,
    importPreparation: state.importExport.importPreparation,
  }), shallowEqual);

  const [productsToSave, setProductsToSave] = useState([]);

  const processChunk = async (rows) => {
    const products = rows
      .filter(row => !row.sku.includes("(SKU) *"))
      .map(row => ({ ...row, id: UTILS.uuidv4() }));
    setProductsToSave((prevProducts) => [...prevProducts, ...products]);
  };


  useEffect(() => {
    if (error) {
      toast.error(<FormattedMessage id={error} />);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])


  useEffect(() => {
    return () => {
      dispatch(importExportActions.cleanUpReducer());
    };
  }, [dispatch]);


  return (
    <Importer onComplete={({ file, preview, fields, columnFields }) => {

      if (!file.name.toLowerCase().endsWith('.csv')) {
        toast.error(<FormattedMessage id="IMPORT_EXPORT.INVALID_FILE" />);
        return;
      }

      if (file.size > 300000) {
        toast.warning(<FormattedMessage id="IMPORT_EXPORT.INVALID_LENGTH_SIZE" />);
        return;
      }

      if (productsToSave.length > 500) {
        toast.warning(<FormattedMessage id="IMPORT_EXPORT.INVALID_LENGTH_MAX" />);
        return;
      }

      if (productsToSave.length === 0) {
        toast.warning(<FormattedMessage id="IMPORT_EXPORT.INVALID_LENGTH_MIN" />)
        return;
      }

      dispatch(importExportActions.importPreparation(productsToSave, "products"));

    }}

      locale={langList[locale]}
      assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
      restartable={false} // optional, lets user choose to upload another file when import is complete
      onStart={({ file, preview, fields, columnFields }) => { }}
      processChunk={processChunk}
      onClose={({ file, preview, fields, columnFields }) => {
        importPreparation.length > 0 && history.push(ModuleRoutes.IMPORT_PRODUCT);
      }}
      onPageChange={() => { }}
    // CSV options passed directly to PapaParse if specified:
    // delimiter={...}
    // newline={...}
    // quoteChar={...}
    // escapeChar={...}
    // comments={...}
    // skipEmptyLines={...}
    // delimitersToGuess={...}
    // chunkSize={...} // defaults to 10000
    // encoding={...} // defaults to utf-8, see FileReader API
    >


      <ImporterField name="sku" label={intl({ id: 'IMPORT.CSV.PRODUCT.SKU' })} />
      <ImporterField name="name" label={intl({ id: 'IMPORT.CSV.PRODUCT.ITEM_NAME' })} />


      <ImporterField name="isVariant" label={intl({ id: 'IMPORT.CSV.PRODUCT.IS_VARIANT' })} optional />
      <ImporterField name="variantSku" label={intl({ id: 'IMPORT.CSV.PRODUCT.VARIANT_SKU' })} optional />
      <ImporterField name="variantName" label={intl({ id: 'IMPORT.CSV.PRODUCT.VARIANT_NAME' })} optional />
      <ImporterField name="variantValue" label={intl({ id: 'IMPORT.CSV.PRODUCT.VARIANT_VALUE' })} optional />

      <ImporterField name="brand" label={intl({ id: 'IMPORT.CSV.PRODUCT.BRAND' })} optional />
      <ImporterField name="quantity" label={intl({ id: 'IMPORT.CSV.PRODUCT.QUANTITY' })} />


      <ImporterField name="purchase_price" label={intl({ id: 'IMPORT.CSV.PRODUCT.PURCHASE_PRICES' })} optional />
      <ImporterField name="gross_price" label={intl({ id: 'IMPORT.CSV.PRODUCT.SALES_PRICE_GROSS' })} optional />
      <ImporterField name="currency" label={intl({ id: 'PRODUCT.GENERAL.CURRENCY' })} optional />
      <ImporterField name="tax" label={intl({ id: 'IMPORT.CSV.PRODUCT.VAT_TAX' })} />

      <ImporterField name="ean" label="EAN" optional />
      <ImporterField name="asin" label="ASIN" optional />
      <ImporterField name="barcode" label="Barcode" optional />
      <ImporterField name="isbn" label="ISBN" optional />
      <ImporterField name="gtin" label="GTIN" optional />
      <ImporterField name="mpn" label="MPN" optional />
      <ImporterField name="upc" label="UPC" optional />

      <ImporterField name="weight" label={intl({ id: 'IMPORT.CSV.PRODUCT.WEIGHT' })} optional />
      <ImporterField name="width" label={intl({ id: 'IMPORT.CSV.PRODUCT.WIDTH' })} optional />
      <ImporterField name="height" label={intl({ id: 'IMPORT.CSV.PRODUCT.HEIGHT' })} optional />
      <ImporterField name="length" label={intl({ id: 'IMPORT.CSV.PRODUCT.DEPTH' })} optional />

      <ImporterField name="description" label={intl({ id: 'IMPORT.CSV.PRODUCT.DESCRIPTION' })} optional />
      {/* <ImporterField name="condition" label="Zustand" optional /> */}
      <ImporterField name="path" label={intl({ id: 'IMPORT.CSV.PRODUCT.CATEGORY' })} optional />
      <ImporterField name="unit" label={intl({ id: 'IMPORT.CSV.PRODUCT.UNIT' })} optional />

      <ImporterField name="img1" label={intl({ id: 'IMPORT.CSV.PRODUCT.PICTURE1' })} optional />
      <ImporterField name="img2" label={intl({ id: 'IMPORT.CSV.PRODUCT.PICTURE2' })} optional />
      <ImporterField name="img3" label={intl({ id: 'IMPORT.CSV.PRODUCT.PICTURE3' })} optional />
      <ImporterField name="img4" label={intl({ id: 'IMPORT.CSV.PRODUCT.PICTURE4' })} optional />
      <ImporterField name="img5" label={intl({ id: 'IMPORT.CSV.PRODUCT.PICTURE5' })} optional />
      <ImporterField name="img6" label={intl({ id: 'IMPORT.CSV.PRODUCT.PICTURE6' })} optional />
      <ImporterField name="img7" label={intl({ id: 'IMPORT.CSV.PRODUCT.PICTURE7' })} optional />
      <ImporterField name="img8" label={intl({ id: 'IMPORT.CSV.PRODUCT.PICTURE8' })} optional />
      <ImporterField name="img9" label={intl({ id: 'IMPORT.CSV.PRODUCT.PICTURE9' })} optional />
      <ImporterField name="img10" label={intl({ id: 'IMPORT.CSV.PRODUCT.PICTURE10' })} optional />

      <ImporterField name="features1" label={intl({ id: 'IMPORT.CSV.PRODUCT.FEATURE1' })} optional />
      <ImporterField name="features2" label={intl({ id: 'IMPORT.CSV.PRODUCT.FEATURE2' })} optional />
      <ImporterField name="features3" label={intl({ id: 'IMPORT.CSV.PRODUCT.FEATURE3' })} optional />
      <ImporterField name="features4" label={intl({ id: 'IMPORT.CSV.PRODUCT.FEATURE4' })} optional />
      <ImporterField name="features5" label={intl({ id: 'IMPORT.CSV.PRODUCT.FEATURE5' })} optional />
      <ImporterField name="features6" label={intl({ id: 'IMPORT.CSV.PRODUCT.FEATURE6' })} optional />

    </Importer>
  );
}
