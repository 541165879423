import React from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { CardItems } from '_metronic/_partials/components';
import { flags, UIDates } from "_metronic/_helpers";
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export default function Info({ tempUser }) {

  const currentLanguage = flags.find((x) => x.lang === tempUser?.language);

  const USER_INFO = [
    {
      label: "ADMIN.LANGUAGE",
      content: <>
        <span className="svg-icon svg-icon-md mr-2">{currentLanguage?.flag}</span>
        <span>{currentLanguage?.name}</span>
      </>,
    },
    {
      label: "GENERAL.REGISTRATION_DATE",
      content: UIDates.formatDateTime(tempUser?.created_at),
    },
    {
      label: "ADMIN.USER.EMAIL_SENT.DATE",
      content: UIDates.formatDateTime(tempUser?.email_verification?.sending_date),
    },
  ];


  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader icon={<Icon.PersonInfo />} title={<FormattedMessage id="ADMIN.USER.INFO" />} />

      <CardBody>
        <CardItems Content={USER_INFO} />
      </CardBody>

    </Card>
  );
}
