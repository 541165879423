import React from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls'
import { AddressEditDialog } from './edit/AddressEditDialog'
import { UserInfoDropdown } from '_metronic/_partials'
import { AddressFilter } from './AddressFilter'
import { FormattedMessage } from 'react-intl'
import { AddressTable } from './AddressTable'
import { Icon } from '_metronic/_icons';



export function AddressCard() {
  return (
    <>
      <Card className="gutter-b">

        <CardSubHeader
          icon={<Icon.GeoAlt />}
          title={<FormattedMessage id="GENERAL.ADDRESS" />}
          info={<UserInfoDropdown description={<FormattedMessage id='USER_GUIDE.CUSTOMER_ADDRESS' />} />}
        >
          <AddressFilter />
        </CardSubHeader>

        <CardBody className="pt-0 px-0">
          <AddressTable />
        </CardBody>

      </Card>
      <AddressEditDialog />
    </>
  )
}
