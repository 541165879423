import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { sortCaret, headerSortingClasses, NoRecordsFoundMessage, getSelectRow, UIDates, UTILS, getCountryList } from '_metronic/_helpers';
import { useCustomersUIContext } from '../_context/CustomersUIContext';
import { RemotePagination } from '_metronic/_partials/controls';
import * as columnFormatters from './ActionsColumnFormatter';
import * as HELPERS from '../_context/CustomersUIHelpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';
import { FormattedMessage, useIntl } from 'react-intl';



export function CustomersTable({ entities, countCustomers, loading, queryParams, setPaginationQuery, setOrderQuery, setSearchQuery, setFilterQuery }) {

  const locale = useLang();
  const { formatMessage: intl } = useIntl();

  // Customers UI Context
  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    openCustomerDetailsDialog: UIContext.openCustomerDetailsDialog
  }), [UIContext]);


  const COLUMNS = [
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap pl-4',
      classes: 'font-weight-bold p-2',
      headerStyle: { minWidth: "140px", width: '30%' },
      formatter: (cell, row) => (
        <div className="d-flex align-items-start text-left">

          <OverlayTrigger overlay={<Tooltip id="customer-details-tooltip" className='font-weight-bold'><FormattedMessage id="DOCUMENT.CUSTOMER_TITLE" /></Tooltip>}>
            <div type="button" className="symbol symbol-40 symbol-light mr-3" onClick={() => UIProps.openCustomerDetailsDialog(row.id)} id='btn_customer_preview'>
              <span className="symbol-label font-size-h5 text-dark-25 text-uppercase">
                {UTILS.getFirstLetter(row?.name)}
              </span>
            </div>
          </OverlayTrigger>

          <div>
            <Link to={ModuleRoutes.EDIT_CUSTOMER_FN(row.id)} type="button" className=" text-decoration-none text-dark text-hover-primary text-nowrap preview" id='btn_customer_edit'>
              <div className='d-flex align-items-center'>
                <span className='font-weight-bolder text-dark-75 mr-2'>
                  {row?.name}
                </span>
                <span className="text-dark-50">
                  {row.company}
                </span>
                <span className='d-flex text-dark-25 svg-icon svg-icon-sm invisible pl-2'>
                  <Icon.Pencil />
                </span>
              </div>
            </Link>

            <div className="font-size-sm text-dark-50">
              {row?.number}
            </div>

          </div>

        </div>
      ),
    },
    {
      dataField: 'email',
      text: intl({ id: 'GENERAL.EMAIL' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { width: '15%' },
      formatter: (cell, row) => (<a href={`mailto:${cell}`} className="text-decoration-none font-weight-bold text-dark-75 text-hover-primary">{cell}</a>)
    },
    {
      dataField: 'phone',
      text: intl({ id: 'GENERAL.PHONE' }),
      headerStyle: { width: '15%' },
      formatter: (cell, row) => (
        <div className='font-weight-bold '>
          <a href={`tel:${cell}`} className="d-block text-decoration-none text-dark-75 text-hover-primary mb-1">{cell}</a>
          <a href={`tel:${row.mobile}`} className="d-block text-decoration-none text-dark-75 text-hover-primary">{row.mobile}</a>
        </div>
      )
    },
    {
      dataField: 'address[0].country_code',
      text: intl({ id: 'GENERAL.ADDRESS' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      headerStyle: { width: '10%' },
      formatter: (cell, row) => {
        const address = row.address.find((item) => item.type === "address_delivery") ?? row.address[0];
        return (
          <OverlayTrigger overlay={<Tooltip id="address-tooltip">
            <div className='font-weight-bold text-dark-75 text-left'>
              <div className='font-weight-bolder'>{address?.name}</div>
              <div className='font-weight-bolder text-dark-50'>{address?.company}</div>
              <hr className='my-1' />
              <div>{address?.address_line1} {address?.address_line2}</div>
              <div>{address?.post_code} {address?.city}</div>
              <div>{getCountryList(locale).find(code => code.value === address?.country_code)?.label}</div>
            </div>
          </Tooltip>}>
            <div className='d-flex flex-column font-weight-bold mr-1'>
              <span className='text-nowrap'>
                {address?.city}
              </span>
              <span className='text-dark-50'>
                {address?.country_code + '-' + address?.post_code}
              </span>
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'discount',
      text: intl({ id: 'GENERAL.DISCOUNT' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '50px', width: "5%" },
      headerClasses: 'text-center text-nowrap pl-5',
      classes: 'text-center text-nowrap pr-6',
      formatter: (cell, row) => (cell > 0 ?
        <div className='label label-xl label-inline label-rounded font-weight-bolder label-light-warning text-warning px-2'>
          <span className="label label-xl label-dot mr-2 label-warning"></span>
          {cell}%
        </div>
        : null),
    },
    {
      dataField: 'source.marketplace',
      text: intl({ id: 'CUSTOMER.TABLE.SOURCE' }),
      headerClasses: 'text-center',
      headerStyle: { minWidth: '50px', width: '5%' },
      classes: 'text-center',
      formatter: columnFormatters.SourceColumnFormatter,
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.REGISTRATION_DATE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap pl-5',
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-center',
      formatter: (cell, row) => (
        <div className='font-size-sm text-dark-50 font-weight-bold'>
          {UIDates.formatDateTime(cell)}
        </div>
      )
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      headerClasses: 'text-right pr-3',
      headerStyle: { minWidth: '100px', width: '10%' },
      classes: 'text-right text-nowrap pr-0',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: (id) => ModuleRoutes.EDIT_CUSTOMER_FN(id),
        openDeleteCustomerDialog: (id) => ModuleRoutes.DELETE_CUSTOMER_FN(id),
        openSetDocumentDialog: (id) => ModuleRoutes.SET_DOCUMENT_CUSTOMER_FN(id),
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={entities || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        striped
        bordered={false}
        condensed
        defaultSorted={HELPERS.defaultSorted}
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { setSearchQuery(''); setFilterQuery('DELETE_PARAMS', ["marketplace"]) }} />}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: entities,
        })}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={countCustomers}
        entitiesCount={entities?.length}
        loading={loading}
      />
    </>
  );
}
