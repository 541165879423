import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls";
import { useSearchQueryContext } from "./_context/SearchQueryProvider";
import { ShippingsFilter } from "./shippings-filter/ShippingsFilter";
import { UserGuideHelper } from "app/user-guides/UserGuideHelper";
import { ShippingsTable } from "./shippings-table/ShippingsTable";
import { BlankMessage } from "_metronic/_partials/components";
import { LoadingDialog } from "_metronic/_partials/loading";
import { ShippingsActions } from "./_redux/actions";
import { Icon } from '_metronic/_icons';



export function ShippingsCard() {

  const dispatch = useDispatch();

  const { entities, loading, countShipping, totalShipping } = useSelector(state => ({
    entities: state.shippings.entities,
    loading: state.shippings.loading,
    countShipping: state.shippings.countShipping,
    totalShipping: state.shippings.totalShipping,
  }));

  const queryContext = useSearchQueryContext();
  const { queryParams, setOrderQuery, setPaginationQuery, setSearchQuery, setFilterQuery } = useMemo(() => ({
    queryParams: queryContext.queryParams,
    setOrderQuery: queryContext.setOrderQuery,
    setPaginationQuery: queryContext.setPaginationQuery,
    setSearchQuery: queryContext.setSearchQuery,
    setFilterQuery: queryContext.setFilterQuery,
  }), [queryContext]);

  useEffect(() => {
    let delayDebounceFn;
    if (queryParams.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(ShippingsActions.getShippings(queryParams))
      }, 350);
    } else {
      dispatch(ShippingsActions.getShippings(queryParams))
    }

    return () => { clearTimeout(delayDebounceFn) };
  }, [dispatch, queryParams]);


  /*   useEffect(() => {
      return () => { 
        dispatch(ShippingsActions.cleanCustomer()) 
      };
    }, [dispatch]);  */

  return (
    <Card className="card-height">
      <CardHeader
        icon={<Icon.Supplier />}
        title={<FormattedMessage id="SHIPPING.TITLE" />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_shipping'
      >
      </CardHeader>

      <CardBody>
        {totalShipping === 0
          ? <BlankMessage
            icon={<Icon.Supplier />}
            title={<FormattedMessage id='SHIPPING.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='SHIPPING.BLANK_MESSAGE' />}
            className='min-50-height'
            loading={loading}
          />
          : <>
            <ShippingsFilter setSearchQuery={setSearchQuery} setFilterQuery={setFilterQuery} queryParams={queryParams} />
            <ShippingsTable
              loading={loading}
              entities={entities}
              countShipping={countShipping}
              queryParams={queryParams}
              setPaginationQuery={setPaginationQuery}
              setOrderQuery={setOrderQuery}
              setSearchQuery={setSearchQuery}
            />
          </>}
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}
