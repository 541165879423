import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useDigitalProductsUIContext } from '../../_context/DigitalProductsUIContext';
import { useSearchQueryContext } from '../../_context/SearchQueryProvider';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { convertPriceCurrency } from '_metronic/_helpers';
import { digitalProductsActions } from '../../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { CloseButton } from '_metronic/_partials';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';
import clsx from 'clsx';



export function InvoicesToEbayDialog() {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const { loading, preparation } = useSelector(state => ({
    loading: state.digitalProduct.loading,
    preparation: state.digitalProduct.preparation,
  }), shallowEqual);


  const queryContext = useSearchQueryContext();
  const UIContext = useDigitalProductsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showInvoicesToEbayDialog,
    onHide: UIContext.closeInvoicesToEbayDialog,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
    queryParams: queryContext.queryParams,
  }), [
    UIContext.closeInvoicesToEbayDialog,
    UIContext.showInvoicesToEbayDialog,
    UIContext.openPrintDocumentDialog,
    queryContext.queryParams
  ]);


  const openPrintDocumentDialog = () => {
    if (preparation) {

      const { total_net_price, total_tax_price, orders_count, ...rest } = preparation
      const data = { ...rest, ebay_invoice_action: 'create' }

      dispatch(digitalProductsActions.printDocument(data, UIProps.queryParams));

      UIProps.onHide();
      UIProps.openPrintDocumentDialog();
    };
  }

  const totalPrice = [
    {
      title: intl({ id: 'DOCUMENT.ORDER_PLURAL' }),
      count: preparation?.orders_count
    },
    {
      title: intl({ id: 'DOCUMENT.POSITION_TOTAL_NET' }),
      price: preparation?.total_net_price
    },
    {
      title: intl({ id: 'DOCUMENT.POSITION_TOTAL_ITEM' }),
      price: preparation?.total_tax_price
    },
    {
      title: intl({ id: 'DOCUMENT.POSITION_TOTAL_BRUT' }),
      price: (preparation?.total_net_price + preparation?.total_tax_price)
    },
  ];


  const handleOnHide = () => {
    UIProps.onHide();
  };


  useEffect(() => {
    return () => {
      dispatch(digitalProductsActions.cleanStatesInStore({ preparation: {} }));
    };
  }, [dispatch]);


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.show} onHide={handleOnHide} aria-labelledby="example-modal-sizes-title-md" size="md" centered>

        <CloseButton onClick={handleOnHide} className="position-absolute top-0 right-0 mr-10 mt-10" />

        <div className="d-flex justify-content-center icon-4x pt-10">
          <Icon.EbayLong className="text-info" />
        </div>

        <Modal.Body className={clsx(loading && "overlay overlay-block")}>

          <ListGroup variant="flush" style={{ minWidth: "400px" }}>
            {totalPrice.map((item, i) => (
              <ListGroup.Item key={i} className="d-flex justify-content-between text-muted border-0">
                <span className='font-weight-bold text-uppercase'>{item.title}</span>
                <span className='font-weight-boldest'>{item.count ? item.count : convertPriceCurrency(+item.price)}</span>
              </ListGroup.Item>
            ))}
          </ListGroup>

          {loading && <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-track spinner-lg spinner-primary" />
          </div>}

        </Modal.Body>

        <Modal.Footer>

          <Button variant='light' style={loading ? { pointerEvents: "none" } : null} className="mx-2" onClick={handleOnHide} disabled={loading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>

          <Button variant='primary' onClick={() => openPrintDocumentDialog(false)} disabled={loading || !preparation} style={(loading || !preparation) ? { pointerEvents: "none" } : null} id='btn_convert_modal'>
            <FormattedMessage id="DOCUMENT.CONVERT.CREATE_INVOICE" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}

