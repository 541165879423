import React from 'react'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import packagejson from "../../../../package.json";
import { useLang } from '_metronic/i18n';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// The localization data for Vietnamese
import en_US from '@react-pdf-viewer/locales/lib/en_US';
import de_DE from '@react-pdf-viewer/locales/lib/de_DE';



export default function PdfViewer({ url, height = "90vh" }) {

  const locale = useLang(); // "de" | "en" | "tr"

  const transform = (slot) => ({
    ...slot,
    Open: () => <></>,
    EnterFullScreen: () => <></>,
    SwitchTheme: () => <></>,
  });

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {renderDefaultToolbar(transform)}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar,
  });

  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;


  return (
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${packagejson.dependencies["pdfjs-dist"]}/build/pdf.worker.min.js`}>
      <div style={{ height }}>
        <Viewer
          fileUrl={url}
          localization={locale === "en" ? en_US : de_DE}
          plugins={[defaultLayoutPluginInstance]}
        />
      </div>
    </Worker>
  )
}
