import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useDocumentsUIContext } from './_context/DocumentsUIContext';
import { LayoutSplashScreen } from '_metronic/layout';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { DocumentActions } from './_redux/actions';
import { Icon } from '_metronic/_icons';
import Document from './Document';



export const documentContents = [
  {
    type: "offers",
    title: "DOCUMENT.OFFER_TITLE",
    plural: "DOCUMENT.OFFER_PLURAL",
    icon: <Icon.Document />,
    route: ModuleRoutes.DOCUMENT_OFFER,
    inputHeaderText: "Vielen Dank für Ihr Interesse an unserem Angebot.",
    inputFooterText: "Bei Fragen oder Problemen zu unserem Angebot helfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
  },
  {
    type: "orders",
    title: "DOCUMENT.ORDER_TITLE",
    plural: "DOCUMENT.ORDER_PLURAL",
    icon: <Icon.Order />,
    route: ModuleRoutes.DOCUMENT_ORDER,
    inputHeaderText: "Vielen Dank für Ihre Bestellung bei uns.",
    inputFooterText: "Bei Fragen oder Problemen zu Ihrer Bestellung helfen wir Ihnen gerne weiter.\nWie wünschen Ihnen einen schönen Tag."
  },
  {
    type: "invoices",
    title: "DOCUMENT.INVOICE_TITLE",
    plural: "DOCUMENT.INVOICE_PLURAL",
    icon: <Icon.Document />,
    route: ModuleRoutes.DOCUMENT_INVOICE,
    inputHeaderText: "Vielen Dank, dass Sie sich bei uns entschieden haben.",
    inputFooterText: "Bei Fragen oder Problemen zu Ihrer Rechnung helfen wir Ihnen gerne weiter.\nWie wünschen Ihnen einen schönen Tag."
  },
  {
    type: "credits",
    title: "DOCUMENT.CREDIT_TITLE",
    plural: "DOCUMENT.CREDIT_PLURAL",
    icon: <Icon.Document />,
    route: ModuleRoutes.DOCUMENT_CREDIT,
    inputHeaderText: "Vielen Dank, dass Sie sich bei uns entschieden haben.",
    inputFooterText: "Bei Fragen oder Problemen zu Ihrer Gutschrifthelfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
  },
  {
    type: "waybills",
    title: "DOCUMENT.WAYBILL_TITLE",
    plural: "DOCUMENT.WAYBILL_PLURAL",
    icon: <Icon.Document />,
    route: ModuleRoutes.DOCUMENT_WAYBILL,
    inputHeaderText: "Vielen Dank für Ihre Bestellung bei uns.",
    inputFooterText: "Bei Fragen oder Problemen zu Ihrer Bestellung helfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
  },
]


export default function GenerateDocument() {

  const history = useHistory();
  const dispatch = useDispatch();
  const { doc } = useParams();

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    requestedDocument: UIContext.requestedDocument,
    setupRequestedDocument: UIContext.setupRequestedDocument,
  }),
    [UIContext.requestedDocument, UIContext.setupRequestedDocument]
  );

  const getRequestedDocument = useSelector((state) => state.documents.requestedDocument);

  useEffect(() => {

    const getDocument = documentContents.find(item => item.type === doc);

    if (!getDocument) {
      history.push(ModuleRoutes.DOCUMENT_ORDER);
    }

    UIProps.setupRequestedDocument(getDocument);
    dispatch(DocumentActions.setupRequestedDocument(getDocument));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, doc]);


  return !UIProps.requestedDocument || !(UIProps.requestedDocument.type === getRequestedDocument.type)
    ? <LayoutSplashScreen />
    : <Document key={UIProps.requestedDocument.type} name={UIProps.requestedDocument.type} />

}
