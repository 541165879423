/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { RemotePagination } from '_metronic/_partials/controls';
import { sortCaret, headerSortingClasses, NoRecordsFoundMessage, convertPriceCurrency, UIDates } from '_metronic/_helpers';
import { useDigitalProductsUIContext } from '../../_context/DigitalProductsUIContext';
import * as columnFormatters from './column-formatters/ColumnFormatters';
import { digitalProductsActions } from '../../_redux/actions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function InvoicesToEbayTable({ invoicesEbay, totalInvoicesEbay, loading, queryParams, setPaginationQuery, setOrderQuery, setSearchQuery }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const UIContext = useDigitalProductsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
    sendEmailInvoiceDialog: UIContext.sendEmailInvoiceDialog,
    openInvoicesToEbayDialog: UIContext.openInvoicesToEbayDialog,
  }),
    [UIContext]
  );


  const openPrintDocumentDialog = (row, ebay_invoice_action) => {
    const { preparation, date_from, date_to, market_name, ebay_invoice_id } = row;
    const data = { date_from, date_to, market_name, preparation }

    if (ebay_invoice_action === 'preparation') {
      data.ebay_invoice_id = ebay_invoice_id
      dispatch(digitalProductsActions.preparationDocument(data));
      UIProps.openInvoicesToEbayDialog()
    } else if (ebay_invoice_action === 'preview') {
      data.ebay_invoice_action = ebay_invoice_action
      dispatch(digitalProductsActions.printDocument(data, queryParams));
      UIProps.openPrintDocumentDialog();
    } else if (ebay_invoice_action === 'print') {
      dispatch(digitalProductsActions.printDocument({ ebay_invoice_action, ebay_invoice_id }, queryParams));
      UIProps.openPrintDocumentDialog();
    }
  };


  const COLUMNS = [
    {
      dataField: 'no',
      text: intl({ id: "GENERAL.NO" }),
      headerClasses: 'text-nowrap  text-center',
      classes: 'text-nowrap  text-center',
      headerStyle: { width: '5%' },
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: 'market_name',
      text: intl({ id: "DOCUMENT.TABLE.COLUMN.MARKETPLACE" }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-left',
      classes: 'text-nowrap text-left',
      headerStyle: { width: '10%' },
      formatter: (cell, row) => cell
    },
    {
      dataField: 'date_from',
      text: intl({ id: 'DOCUMENT.DETAIL_DIALOG.ORDER.DATE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center',
      classes: 'text-nowrap text-center pr-6',
      headerStyle: { width: '18%' },
      formatter: (cell, row) => UIDates.getCurrentDateUtc(cell) + ' - ' + UIDates.getCurrentDateUtc(row.date_to),
    },
    {
      dataField: 'orders_count',
      text: intl({ id: "GENERAL.COUNT" }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center',
      classes: 'text-nowrap text-center pr-6',
      headerStyle: { width: '8%' },
      formatter: (cell, row) => {
        return (
          <OverlayTrigger overlay={<Tooltip id="market-tooltip">{<FormattedMessage id="DOCUMENT.ORDER_TITLE" />}</Tooltip>}>
            <Link className="text-dark-75 text-hover-primary"
              to={{
                pathname: ModuleRoutes.DOCUMENT_ORDER,
                search: `page=1&filter.date_from=${UIDates.formatFilterDate(row.date_from)}&filter.date_to=${UIDates.formatFilterDate(row.date_to)}&filter.invoice_ebay=${row.market_name}&filter.doc_type=orders&filter.status=all-orders`,
                hash: '',
              }}
              id="btn_orders_digital_product"
            >
              {cell}
            </Link>
          </OverlayTrigger>)
      },
    },
    {
      dataField: 'total_tax_price',
      text: intl({ id: 'DOCUMENT.POSITION_TAX_FIELD' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-right',
      classes: 'text-nowrap text-right pr-6',
      headerStyle: { width: '10%' },
      formatter: (cell, row) => convertPriceCurrency(cell, 'EUR'),
    },
    {
      dataField: 'total_net_price',
      text: intl({ id: 'DOCUMENT.POSITION_GP_NET' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-right',
      classes: 'text-nowrap text-right pr-6',
      headerStyle: { width: '10%' },
      formatter: (cell, row) => convertPriceCurrency(cell, 'EUR'),
    },
    {
      dataField: 'total_brut_price',
      text: intl({ id: 'DOCUMENT.POSITION_GP_BRUT' }),
      headerClasses: 'text-nowrap text-right',
      classes: 'text-nowrap text-right',
      headerStyle: { width: '10%' },
      formatter: (cell, row) => convertPriceCurrency((+row.total_tax_price + +row.total_net_price), 'EUR'),
    },
    {
      dataField: 'ebay_invoice_id',
      text: intl({ id: 'DOCUMENT.INVOICE_TITLE' }),
      headerClasses: 'text-nowrap text-center',
      classes: 'text-nowrap text-center',
      headerStyle: { width: '13%' },
      formatter: (cell, row) => {
        return (
          cell && <OverlayTrigger overlay={<Tooltip id="invoice-tooltip"><div className='font-weight-bold'>{row.preparation ? <FormattedMessage id="DIGITAL_PRODUCT.INVOICE_WARNING" /> : cell}</div></Tooltip>}>
            <div type='button' className='svg-icon text-dark-50 text-hover-primary'>
              {row.preparation ? <Icon.ExclamationCircle /> : <Icon.FileEarmarkCheck />}
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'email_sended_date',
      text: intl({ id: 'GENERAL.EMAIL' }),
      headerClasses: 'text-center',
      headerStyle: { width: '6%', minWidth: '70px' },
      classes: 'text-center',
      formatter: (cell, row) => {
        return (
          cell && <OverlayTrigger overlay={<Tooltip id="quick-category-tooltip"><FormattedMessage id='DOCUMENT.REDUX.RETURN_LABEL.EMAIL_SUCCESS' /> <div>{UIDates.formatDateTime(cell)}</div></Tooltip>}>
            <Icon.CheckCircleFill className="icon-md text-success" />
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'action',
      text: "...",
      headerStyle: { width: '10%' },
      headerClasses: 'font-size-h3 text-center pt-0 pr-4',
      classes: 'text-center text-nowrap',
      formatter: columnFormatters.ActionFormatter,
      formatExtraData: {
        openPrintDocumentDialog,
        mailInvoiceHandler: UIProps.sendEmailInvoiceDialog,
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={invoicesEbay || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        striped
        condensed
        defaultSorted={[{ dataField: 'order_date', order: 'desc' }]}
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => setSearchQuery('')} />}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={totalInvoicesEbay}
        entitiesCount={invoicesEbay?.length}
        loading={loading}
      />
    </>
  );
}