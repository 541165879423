import React, { useMemo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { SelectCounter } from '_metronic/_partials';
import { useDigitalProductsUIContext } from '../../_context/DigitalProductsUIContext';
import { useSearchQueryContext } from '../../_context/SearchQueryProvider';
import { digitalProductsActions } from '../../_redux/actions';
import { Portal } from 'react-portal';



export function KeyReserveDeleteDialog() {

  const dispatch = useDispatch();

  const { loading } = useSelector(state => ({
    loading: state.digitalProduct.loading
  }), shallowEqual);


  const UIContext = useDigitalProductsUIContext();
  const queryContext = useSearchQueryContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    show: UIContext.showReserveDialog,
    handleClose: UIContext.closeReserveDialog,
    queryParams: queryContext.queryParams,
  }),
    [UIContext, queryContext]
  );


  const deleteKeyReserve = (ids) => {
    if (ids.length) {
      const deleteKeyIds = ids.filter(item => !item.confirmationDate)?.map(item => item.id);
      dispatch(digitalProductsActions.deleteKeyReserve(deleteKeyIds, UIProps.queryParams));
      UIProps.handleClose()
      UIProps.setIds([]);
    }
  };


  useEffect(() => {
    return () => {
      dispatch(digitalProductsActions.cleanStatesInStore({ error: "" }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.ids]);

  const openConfirmDateIds = UIProps.ids.filter(item => item.confirmationDate).map(item => item.orderId);


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={UIProps.handleClose} aria-labelledby="example-modal-sizes-title-standard" centered>

        <Modal.Body className='px-10 px-6'>

          <h6 className="text-center font-weight-bold">
            <FormattedMessage id="DIGITAL_PRODUCT.KEY_DELETE.CONTENT" />
            {openConfirmDateIds.length > 0 && (
              <div className="text-danger mt-2">
                <FormattedMessage id="DIGITAL_PRODUCT.KEY_DELETE.WARNING" />
                <div>{openConfirmDateIds.join(', ')}</div>
              </div>
            )}
          </h6>

        </Modal.Body>

        <Modal.Footer>

          <SelectCounter count={UIProps.ids.length} />

          <Button variant='light' className='mr-2 ml-auto' onClick={UIProps.handleClose} disabled={loading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>


          <Button variant='primary' onClick={() => deleteKeyReserve(UIProps.ids)} disabled={loading} id="btn_send_email">
            <FormattedMessage id="GENERAL.SEND" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
