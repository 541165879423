import React from "react";
import { Icon } from '_metronic/_icons';
import { ModuleRoutes } from "./moduleRoutes";
import { IsDevDomainFn } from "./apiUrl";



// TODO: License kontrolü yapılacak ancak şu an daha yapılmadı
const developmentLicense = ["development"];
//const testerLicense = ["development", "tester"];
const pilotLicense = ["development", "tester", "test_user"];
const productionLicense = ['development', 'tester', 'test_user', 'trial', 'standard', 'premium', 'admin',];


export const CONNECTION_SETTINGS = [
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "marketplace",
    name: "ebay",
    label: "Ebay",
    icon: <Icon.Ebay />,
    iconLong: <Icon.EbayLong />,
    listingRoute: (id) => ModuleRoutes.EBAY_LISTINGS_FN(id),
    product: (license) => true,
  },
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "marketplace",
    name: "kaufland",
    label: "Kaufland",
    icon: <Icon.Kaufland />,
    iconLong: <Icon.KauflandLong />,
    listingRoute: (id) => ModuleRoutes.KAUFLAND_LISTINGS_FN(id),
    product: (license) => license //Development license ile kullanılabilecek
  },
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "marketplace",
    name: "amazon",
    label: "Amazon",
    icon: <Icon.Amazon />,
    iconLong: <Icon.AmazonLong />,
    listingRoute: (id) => ModuleRoutes.AMAZON_LISTINGS_FN(id),
    product: (license) => license //Development license ile kullanılabilecek
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "marketplace",
    name: "amazon_prime",
    label: "Amazon Prime",
    icon: <Icon.AmazonPrime />,
    iconLong: <Icon.AmazonPrime />,
    listingRoute: (id) => ModuleRoutes.AMAZON_LISTINGS_FN(id),
    product: (license) => license //Development license ile kullanılabilecek
  },
  {
    active: true,
    license: developmentLicense,
    multiple: false,
    type: "marketplace",
    name: "hood",
    label: "Hood (Alpha)",
    icon: <Icon.Hood />,
    iconLong: <Icon.HoodLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: false,
    license: developmentLicense,
    multiple: false,
    type: "marketplace",
    name: "check24",
    label: "Check24 (Deprecated)",
    icon: <Icon.Check24 />,
    iconLong: <Icon.Check24Long />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: developmentLicense,
    multiple: false,
    type: "marketplace",
    name: "manomano",
    label: "Manomano (Alpha)",
    icon: <Icon.Manomano />,
    iconLong: <Icon.ManomanoLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: productionLicense,
    type: "shop",
    multiple: true,
    name: "prestashop",
    label: "PrestaShop",
    icon: <Icon.PrestaShop />,
    iconLong: <Icon.PrestaShopLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "shop",
    name: "shopware5",
    label: "Shopware 5",
    icon: <Icon.Shopware />,
    iconLong: <Icon.ShopwareLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "shop",
    name: "shopware6",
    label: "Shopware 6",
    icon: <Icon.Shopware />,
    iconLong: <Icon.Shopware6Long />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: pilotLicense,
    multiple: false,
    type: "marketplace",
    name: "otto",
    label: "Otto (Beta)",
    icon: <Icon.Otto />,
    iconLong: <Icon.OttoLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "shop",
    name: "woocommerce",
    label: "Woocommerce",
    icon: <Icon.Woocommerce />,
    iconLong: <Icon.WoocommerceLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "shop",
    name: "shopify",
    label: "Shopify",
    icon: <Icon.Shopify />,
    iconLong: <Icon.ShopifyLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: IsDevDomainFn(),
    license: developmentLicense,
    multiple: true,
    type: "marketplace",
    name: "etsy",
    label: "Etsy (Beta)",
    icon: <Icon.Etsy />,
    iconLong: <Icon.EtsyLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "dhl",
    label: "DHL",
    icon: <Icon.DhlOld className='rounded' />,
    iconLong: <Icon.DhlOldLong />,
    product: (license) => true,
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "dhl_new",
    label: "DHL (Neue API)",
    icon: <Icon.Dhl className='rounded' />,
    iconLong: <Icon.DhlLong />,
    product: (license) => true
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "dhl_warenpost",
    label: "DHL Warenpost",
    icon: <Icon.DhlOldWarenpost className='rounded' />,
    iconLong: <Icon.DhlOldWarenpostLong />,
    product: (license) => true,
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "dhl_new_warenpost",
    label: "DHL Warenpost (Neue API)",
    icon: <Icon.DhlWarenpost className='rounded' />,
    iconLong: <Icon.DhlWarenpostLong />,
    product: (license) => true
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "gls",
    label: "GLS (Beta)",
    icon: <Icon.Gls className='rounded' />,
    iconLong: <Icon.GlsLong />,
    product: (license) => true,
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "dpd",
    label: "DPD",
    icon: <Icon.Dpd className='rounded' />,
    iconLong: <Icon.DpdLong />,
    product: (license) => true,
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "deutsche_post",
    label: "Deutsche Post",
    icon: <Icon.DeutschePost className='rounded' />,
    iconLong: <Icon.DeutschePostLong />,
    product: (license) => true,
  },
  {
    // Dummy data sadece customer table için eklendi
    active: false,
    license: productionLicense,
    multiple: false,
    type: "shop",
    name: "salevali",
    label: "SaleVali",
    icon: <Icon.ShopWindow className="text-dark-75" />,
    iconLong: <Icon.Shop />,
    product: (license) => false,
  }
]

export const getConnection = (name) => (CONNECTION_SETTINGS.find(market => market.name === name))
