import React, { useCallback, useMemo, useState } from 'react';
import { Card, CardHeader, CardBody, CardHeaderToolbar } from '_metronic/_partials/controls';
import { EbayListingsFilter } from './ebay-listings-filter/EbayListingsFilter';
import { EbayListingsTable } from './ebay-listings-table/EbayListingsTable';
import { UserGuideHelper } from '../../../user-guides/UserGuideHelper';
import { useProductListingsUIContext } from '../_context/ProductListingsUIContext';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useGetProductsEffect, useSearchQuery } from 'app/hooks';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BlankMessage } from '_metronic/_partials/components';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { ListingActions } from '../_redux/actions';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function EbayListingsCard() {

  const dispatch = useDispatch();

  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'id DESC',
    search: '',
    filter: null,
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), []);
  const { setPaginationQuery, setSearchQuery, setOrderQuery } = useSearchQuery(setQueryParams);

  const { products, count, totalCount, isLoading, actionTracker: { message } } = useSelector((state) => state.listings, shallowEqual);

  useGetProductsEffect(queryParams, ListingActions.getProductsRequest, message);

  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    market_id: UIContext.market_id,
  }),
    [UIContext.ids, UIContext.market_id]
  );

  const onHandleSubmit = () => {
    dispatch(ListingActions.getEbayProducts(UIProps.market_id));
  };


  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Shop />}
        title={<FormattedMessage id="PRODUCT_LISTINGS.TITLE" values={{ shopName: 'Ebay' }} />}
        info={<UserGuideHelper />}
        className="display-5"
        id="title_listing_ebay"
      >

        <CardHeaderToolbar>
          <OverlayTrigger placement="top" overlay={<Tooltip id="quick-actions-tooltip"><FormattedMessage id="PRODUCT_LISTINGS.FETCH_PRODUCTS" values={{ shopName: 'Ebay' }} /></Tooltip>}>
            <Button
              variant="primary"
              className={`svg-icon svg-icon-sm ${UIProps.ids.length > 0 || isLoading ? 'cursor-default' : 'cursor-pointer'}`}
              onClick={onHandleSubmit}
              disabled={UIProps.ids.length > 0 || isLoading}
              id="btn_sync"
            >
              <Icon.ArrowRepeat />
              <span className="d-none d-sm-inline font-weight-bold ml-2">
                <FormattedMessage id="GENERAL.SYNC" />
              </span>
            </Button>
          </OverlayTrigger>
        </CardHeaderToolbar>

      </CardHeader>

      <CardBody>
        {totalCount === 0
          ? <BlankMessage
            icon={<Icon.BoxSeam />}
            title={<FormattedMessage id='PRODUCT.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='PRODUCT.BLANK_MESSAGE' />}
            button={{ link: ModuleRoutes.NEW_PRODUCT, label: "PRODUCT.PRODUCT_NEW" }}
            className='min-50-height'
            loading={isLoading}
          />
          : <>
            <EbayListingsFilter
              queryParams={queryParams}
              setSearchQuery={setSearchQuery}
            />
            <EbayListingsTable
              setPaginationQuery={setPaginationQuery}
              queryParams={queryParams}
              setSearchQuery={setSearchQuery}
              setOrderQuery={setOrderQuery}
              products={products}
              count={count}
              isLoading={isLoading}
            />
          </>
        }
      </CardBody>

    </Card>
  );
}
