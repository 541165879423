import React, { useMemo } from 'react';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls'
import { useUserSettingsUIContext } from '../../_context/UserSettingsUIContext'
import ClearCacheDialog from '../login-settings-dialog/ClearCacheDialog';
import { UserInfoDropdown } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import { Icon } from '_metronic/_icons';



export default function ClearCache() {

  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    openClearCacheDialog: UIContext.openClearCacheDialog,
    showClearCacheDialog: UIContext.showClearCacheDialog,
    closeClearCacheDialog: UIContext.closeClearCacheDialog,
  }),
    [UIContext]
  );

  return (
    <Card className='card card-stretch gutter-b'>

      <CardSubHeader
        title={<FormattedMessage id='SETTINGS.LOGIN.CLEAR_CACHE' />}
        icon={<Icon.Eraser />}
        info={<UserInfoDropdown description={<FormattedMessage id='SETTINGS.LOGIN.CLEAR_CACHE.USER_GUIDE' />} />}
      />

      <CardBody>

        <div className='d-flex justify-content-between align-items-center'>

          <div className='d-flex flex-column mr-3'>
            <span className='font-weight-bolder mb-1'>
              <FormattedMessage id='SETTINGS.LOGIN.CLEAR_CACHE' />
            </span>
            <span>
              <FormattedMessage id='SETTINGS.LOGIN.CLEAR_CACHE.DESCRIPTION' />
            </span>
          </div>

          <Button variant='primary' size='sm' className='font-weight-bold text-nowrap' onClick={UIProps.openClearCacheDialog}>
            <FormattedMessage id='SETTINGS.LOGIN.CLEAR_CACHE' />
          </Button>

        </div>

      </CardBody>
      <ClearCacheDialog UIProps={UIProps} />
    </Card>
  )
}
