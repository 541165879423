import React, { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { AddSingleVariantDialog } from './add-single-variant-dialog/AddSingleVariantDialog';
import { VariantsGenerateDialog } from './variant-generate-dialog/VariantGenerateDialog';
import { VariantConfirmDialog } from './variant-generate-dialog/VariantConfirmDialog';
import { EditVariantDialog } from './edit-variant-dialog/EditVariantDialog';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { useProductEditUIContext } from '../../_context/ProductEditUIContext';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { VariantTable } from './variant-table/VariantTable';
import { UserInfoDropdown } from '_metronic/_partials';
import { VARIANT_ACTION_TYPES } from './core/helpers';
import { VariantFilter } from './VariantFilter';
import { Icon } from '_metronic/_icons';



export function Variant({ loading }) {

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const closeDeleteLabelDialog = () => {
    setShowDeleteDialog(false);
  };

  const { productVariants } = useSelector(state => ({
    productVariants: state.products.variants,
  }), shallowEqual);

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    dispatchNewVariants: UIContext.dispatchNewVariants,
  }),
    [UIContext.dispatchNewVariants]
  );
  const openGenerateVariantsDialog = () => {
    if (productVariants.length > 0) {
      setShowDeleteDialog(true);
      return;
    }
    UIProps.dispatchNewVariants({
      type: VARIANT_ACTION_TYPES.OPEN_GENERATE_VARIANT_DIALOG
    })
  };

  const addVariantHandler = () => {
    UIProps.dispatchNewVariants({
      type: VARIANT_ACTION_TYPES.OPEN_ADD_SINGLE_VARIANT_DIALOG,
    });
  };


  return (
    <Card className="sub-card-height gutter-b">

      <CardSubHeader
        title={<FormattedMessage id="PRODUCT.VARIANTS" />}
        icon={<Icon.Diagram3 />} id="variants"
        info={<UserInfoDropdown description={<FormattedHTMLMessage id={productVariants?.length ? 'USER_GUIDE.PRODUCT.VARIANT_INFO' : 'USER_GUIDE.PRODUCT.VARIANT_GENERATE_INFO'} />} />}
      >

        {productVariants.length > 0
          ? <Dropdown as={ButtonGroup} drop='down' size='sm'>
            <Button variant='outline-primary' size="sm" type="button" className="svg-icon font-weight-bold svg-icon-sm" onClick={addVariantHandler} disabled={loading} id='btn_add_variant'>
              <Icon.Plus />
              <span className="d-none d-sm-inline ml-2">
                <FormattedMessage id="PRODUCT.VARIANT.SINGLE_ADD" />
              </span>
            </Button>

            <Dropdown.Toggle split variant="outline-primary" id="dropdown-split-basic" disabled={loading} />
            <Dropdown.Menu>
              <Dropdown.Item type='button' onClick={openGenerateVariantsDialog} id='dropdown_item_add_variant'>
                <FormattedMessage id="PRODUCT.VARIANT.PROCESS" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          : <Button variant='outline-primary' size="sm" type="button" className="svg-icon font-weight-bold svg-icon-sm" onClick={openGenerateVariantsDialog} disabled={loading} id='btn_add_variant'>
            <Icon.Plus />
            <span className="d-none d-sm-inline ml-2">
              <FormattedMessage id="PRODUCT.VARIANT.PROCESS" />
            </span>
          </Button>
        }
      </CardSubHeader>

      <CardBody className="p-0">

        <VariantsGenerateDialog />
        <AddSingleVariantDialog />
        <EditVariantDialog />
        <VariantTable loading={loading} />
        <VariantFilter />
        <VariantConfirmDialog show={showDeleteDialog} onHide={closeDeleteLabelDialog} addVariantHandler={addVariantHandler} />

      </CardBody>

    </Card>
  );
}
