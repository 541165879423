import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsActions } from '../_redux/actions';
import { Text, footerLength, headerLength } from './Text';
import { PaymentsMethod } from './PaymentsMethod';
import { NumberCircle } from './NumberCircle';
import { maxValidation } from '_metronic/_helpers';
import { PrintDetails } from './PrintDetails';
import { Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { DigitalProductSettings } from '../product-settings/DigitalProductSettings';
import { toast } from 'react-toastify';



export function DocumentSettings({ docBtnRef }) {

  const { docs } = useParams();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const { loading, orderSettings, creditSettings, waybillSettings, offerSettings, invoiceSettings, digitalProductSettings } = useSelector(state => ({
    creditSettings: state.settings.creditSettings,
    orderSettings: state.settings.orderSettings,
    invoiceSettings: state.settings.invoiceSettings,
    offerSettings: state.settings.offerSettings,
    waybillSettings: state.settings.waybillSettings,
    digitalProductSettings: state.settings.digitalProductSettings,
    loading: state.settings.loading
  }));

  const [documentSettings, setDocumentSettings] = useState(orderSettings);

  useEffect(() => {
    switch (docs) {
      case 'orders':
        setDocumentSettings(orderSettings);
        break;
      case 'invoices':
        setDocumentSettings(invoiceSettings);
        break;
      case 'offers':
        setDocumentSettings(offerSettings);
        break;
      case 'waybills':
        setDocumentSettings(waybillSettings);
        break;
      case 'credits':
        setDocumentSettings(creditSettings);
        break;
      case 'digital_product':
        setDocumentSettings(digitalProductSettings);
        break;
      default:
        break;
    }
  }, [documentSettings, docs, orderSettings, creditSettings, waybillSettings, offerSettings, invoiceSettings, digitalProductSettings]);

  const handleSubmit = (values) => {

    const { default_number_range, default_payment, default_subtitle, print_details, digital_product } = values;

    if (digital_product?.mail_template?.length > 2000) {
      return toast.warning('Mail template must be at most 2000 characters')
    }

    const newValues = {
      settings_type: docs,
      default_number_range,
      default_subtitle,
      ...(docs === 'invoices' && { print_details }),
      ...(docs === 'digital_product' ? { digital_product } : { default_payment })
    };

    documentSettings.id
      ? dispatch(SettingsActions.updateUserSettings(documentSettings.id, newValues))
      : dispatch(SettingsActions.createUserSettings(newValues))
  };

  const documentValidationSchema = Yup.object().shape({
    default_number_range: Yup.object().shape({
      prefix: Yup.string()
        .max(10, maxValidation("10")),
      next_value: Yup.string()
        .max(10, maxValidation("10"))
        .test('is-number-format', intl({ id: 'SETTINGS.FIRST_LOGIN.SETTINGS.NEXT_VALUE_INVALID' }), (value) => !value || /^[0-9]+$/.test(value)),
    }),
    default_subtitle: Yup.object().shape({
      header: Yup.string()
        .max(headerLength, maxValidation(`${headerLength}`)),
      footer: Yup.string()
        .max(footerLength, maxValidation(`${footerLength}`)),
    })
  });

  return (
    <Formik enableReinitialize={true} key={JSON.stringify(documentSettings)} initialValues={documentSettings} validationSchema={documentValidationSchema} onSubmit={handleSubmit}>
      <Form>
        <Row>

          <Col lg={12}>

            {docs !== 'orders' && <NumberCircle id={documentSettings.id} loading={loading} documentType={docs} />}

          </Col>

          <Col lg={12}>
            <Text loading={loading} />
          </Col>

          <Col lg={12}>
            {docs === 'digital_product' && <DigitalProductSettings />}
          </Col>

          <Col lg={6}>
            {docs !== 'digital_product' && <PaymentsMethod loading={loading} />}
          </Col>

          <Col lg={6}>
            {docs === 'invoices' && <PrintDetails loading={loading} />}
          </Col>

        </Row>

        <button type="submit" className='d-none' ref={docBtnRef} />

      </Form>
    </Formik>
  );
}
