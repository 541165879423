import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { headerSortingClasses, sortCaret } from '_metronic/_helpers';
import { getBrowserIcon, MODULE_TRANSLATIONS } from '../../_context/UserUIHelpers';
import { RemotePagination } from '_metronic/_partials/controls';
import { BlankMessage } from '_metronic/_partials/components';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { UIDates } from '_metronic/_helpers'
import { Icon } from '_metronic/_icons';
import ReactJson from 'react-json-view';
import clsx from 'clsx';



export function LogsTable({ queryParams, setPaginationQuery, log_entries, total_log_entries, pageSize }) {

  const { formatMessage: intl } = useIntl();

  const columns = [
    {
      dataField: 'ip_address',
      text: intl({ id: "ADMIN.USER.LOGS.IP" }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerClasses: 'text-nowrap pl-7',
      classes: 'font-weight-bold pl-7',
      formatter: (cell, row) => {
        const browserType = getBrowserIcon(row.details?.browser_type);
        const deviceType = getBrowserIcon(row.details?.device_type);
        return (
          <>
            <OverlayTrigger overlay={<Tooltip id="type-tooltip" className='font-weight-bold'>{deviceType?.label}</Tooltip>}>
              <span className='svg-icon svg-icon-md text-muted mr-3'>
                {deviceType?.icon}
              </span>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip id="browser-tooltip" className='font-weight-bold'>{browserType?.label}</Tooltip>}>
              <span className='svg-icon svg-icon-md text-muted mr-3'>
                {browserType?.icon}
              </span>
            </OverlayTrigger>

            <samp className='text-dark-75'>
              {cell}
            </samp>
          </>
        );
      },
    },
    {
      dataField: 'module',
      text: intl({ id: "ADMIN.USER.LOGS.MODULE" }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-left',
      classes: 'font-weight-bold text-left font-size-lg',
      formatter: (cell, row) => {
        const tid = {};

        if (row?.method_name === "createFulfillment") {
          tid.found_action = "fulfillment";
        } else if (row?.method_name?.toLowerCase().includes("file")) {
          tid.found_action = "file";
        } else {
          tid.found_action = row?.details?.url?.match(/([A-Z][a-z]*)/g)?.[0];
        }

        return tid.found_action
          ? MODULE_TRANSLATIONS[tid?.found_action?.toLowerCase()]
          : "-";
      },
    },
    {
      dataField: 'method',
      text: intl({ id: "ADMIN.USER.LOGS.METHOD" }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center pl-7',
      classes: 'font-weight-bold text-center',
      formatter: (cell, row) => {
        let method = row?.method?.toUpperCase() || "";
        let color = "";

        switch (method) {
          case "GET":
            color = "success";
            break;
          case "POST":
            color = "warning";
            break;
          case "PUT":
            color = "info";
            break;
          case "PATCH":
            color = "info";
            break;
          case "DELETE":
            color = "danger";
            break;
          default:
            color = "secondary";
        }
        return (
          <label className={`label label-lg label-inline label-rounded font-weight-bolder label-outline-${color}`}>
            {method}
          </label>
        );
      },
    },
    {
      dataField: 'request',
      text: intl({ id: "ADMIN.USER.LOGS.REQUEST" }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      formatter: (cell, row) => {
        const shortened = cell ? JSON.stringify(cell).substring(0, 30) + "..." : "No Request";
        return (
          <OverlayTrigger placement="bottom" overlay={
            <Tooltip id="request-tooltip" className={clsx(shortened === "No Response" ? "d-none" : "d-flex")}>
              <div style={{ maxHeight: '500px', overflowY: 'auto', textAlign: 'left' }}>
                <ReactJson src={cell} />
              </div>
            </Tooltip>
          } trigger={['click']} rootClose>
            <pre type="button" className='mb-0'>
              {shortened}
            </pre>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'response',
      text: intl({ id: "ADMIN.USER.LOGS.RESPONSE" }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      formatter: (cell, row) => {
        try {
          const shortened = cell ? JSON.stringify(cell).substring(0, 30) + "..." : "No Response";
          return (
            <OverlayTrigger placement="bottom" overlay={
              <Tooltip id="response-tooltip" className={clsx(shortened === "No Response" ? "d-none" : "d-flex")}>
                <div style={{ maxHeight: '500px', overflowY: 'auto', textAlign: 'left' }}>
                  <ReactJson src={cell} />
                </div>
              </Tooltip>
            } trigger={['click']} rootClose>
              <pre type="button" className='mb-0'>
                {shortened}
              </pre>
            </OverlayTrigger>
          );
        } catch (e) {
          return e.message;
        }
      },
    },
    {
      dataField: 'created_at',
      text: intl({ id: "ADMIN.USER.LOGS.CREATED_AT" }),
      sort: true,
      sortCaret,
      // onSort: setLogsQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center pl-7',
      classes: 'font-weight-bold text-center text-dark-50 font-size-sm',
      formatter: (cell, row) => UIDates.formatDateTime(row?.created_at || row?.timestamp),
    },
    {
      dataField: 'status',
      text: intl({ id: "ADMIN.USER.LOGS.RESULT" }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center pl-4',
      classes: 'font-weight-bold text-center py-3 px-4',
      headerStyle: { width: '5%' },
      formatter: (cell, row) => {
        const result = row?.response?.[0]?.error;
        const renderTooltip = (props) => (
          <Tooltip id="response-tooltip" {...props}>
            <div style={{ maxHeight: '500px', overflowY: 'auto', textAlign: 'left' }}>
              <ReactJson src={result} />
            </div>
          </Tooltip>
        );

        return (
          <OverlayTrigger placement="bottom" overlay={result && renderTooltip} trigger={result ? ['click'] : null} rootClose>
            <label className={`label label-lg label-inline label-rounded font-weight-bold label-${row?.response?.[0]?.error ? 'danger' : 'success'}`}>
              <FormattedMessage id={row?.response?.[0]?.error ? "AUTH.GENERAL.ERRORS.TITLE" : "GENERAL.SUCCESS"} />
            </label>
          </OverlayTrigger>
        );
      },
    },
  ];


  return (
    log_entries?.length > 0
      ? <>
        <BootstrapTable
          keyField="id"
          data={log_entries || []}
          columns={columns}
          wrapperClasses="table-responsive"
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          bordered={false}
          striped
          condensed
          hover
          defaultSorted={[{ dataField: 'created_at', order: 'desc' }]}
        />
        <RemotePagination
          queryParams={queryParams.pagination}
          setQueryParams={setPaginationQuery}
          totalCount={total_log_entries}
          paginationSize={pageSize}
          entitiesCount={log_entries?.length}
        />
      </>
      : <BlankMessage
        icon={<Icon.Order />}
        message={<FormattedMessage id="ADMIN.USER.LOGS.BLANK_MESSAGE" />}
      />
  );
}