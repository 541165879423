import React from "react";
import { Card, CardBody } from "_metronic/_partials/controls";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { convertPriceCurrency, getCountryList } from "_metronic/_helpers";
import { BlankMessage } from "_metronic/_partials/components";
import ShowHideButton from "../components/ShowHideButton";
import { UserInfoDropdown } from "_metronic/_partials";
import { useToggleDisplayCard } from "app/hooks";
import { FormattedMessage } from "react-intl";
import { useLang } from "_metronic/i18n";
import { Icon } from '_metronic/_icons';
import { truncate } from "lodash";



export function TableTopCustomer({ data, total_invoice_count, currency }) {

  const locale = useLang();
  const [toggleProperty, dispatchProperty] = useToggleDisplayCard("top_customers")

  return (
    <Card className="card-stretch gutter-b">

      <div className="d-flex justify-content-between pt-8 px-8 pb-5">
        <div className="d-flex align-items-start flex-column">
          <h4 className="d-flex align-items-center font-weight-bolder text-dark">
            <FormattedMessage id="DASHBOARD.TOP_CUSTOMERS" />
            <UserInfoDropdown description={<FormattedMessage id='USER_GUIDE.DASHBOARD.TOP_CUSTOMERS' />} />
          </h4>
          <span className="text-muted font-weight-bold font-size-sm">
            {data.count
              ? <FormattedMessage id="DASHBOARD.TOP_CUSTOMERS.MORE_THAN" values={{ count: data.count > 10 ? Math.floor(data.count / 10) * 10 : data.count }} />
              : <>
                <FormattedMessage id="DASHBOARD.TOP_CUSTOMERS" /> : {data?.count}
              </>
            }
          </span>
        </div>
        <div className="align-items-start">
          <ShowHideButton toggleProperty={toggleProperty} dispatchProperty={dispatchProperty} id={"cardTop10CustomersShow"} />
        </div>
      </div>

      <CardBody className="px-0 pb-5 pt-0">
        {!(!toggleProperty || !data?.list?.length > 0)
          ? <Table responsive className="table-head-custom table-head-bg table-vertical-center">
            <thead>
              <tr className="text-left text-uppercase">

                <th className="text-center svg-icon rounded-0 pl-5 pl-md-10" style={{ width: "10%" }}>
                  <OverlayTrigger overlay={<Tooltip id="quick-actions-tooltip"><FormattedMessage id="PRODUCT.GENERAL.QUANTITY" /></Tooltip>}>
                    <Icon.GraphUp />
                  </OverlayTrigger>
                </th>

                <th className="px-0 px-md-3" style={{ minWidth: "150px" }}>
                  <FormattedMessage id="GENERAL.NAME" />
                </th>

                <th className="text-right rounded-0 pr-5 pr-md-10" style={{ width: "20%" }}>
                  <FormattedMessage id="GENERAL.SALE" />
                </th>

              </tr>
            </thead>

            <tbody>

              {data.list?.map((data, index) => (
                <tr key={index}>

                  <td className="text-left pl-5 pl-md-10">
                    <OverlayTrigger placement="left" overlay={<Tooltip id="quick-actions-tooltip">{data?.previous_index ? `Previous index: ${data?.previous_index}` : 'New'}</Tooltip>}>
                      <div type="button" className="svg-icon text-dark-75 font-weight-bolder font-size-lg d-flex flex-column align-items-center">
                        {data?.previous_index
                          ? index < data?.previous_index
                            ? <>
                              <Icon.CaretUpFill className="text-success" />
                              {data.order_count}
                            </>
                            : <>
                              {data.order_count}
                              <Icon.CaretDownFill className="text-danger" />
                            </>
                          : <>
                            <Icon.CaretUpFill className="text-success" />
                            {data.order_count}
                          </>
                        }
                      </div>
                    </OverlayTrigger>
                  </td>

                  <td className="px-0 px-md-3 py-3">
                    <div className="d-flex align-items-start">
                      <OverlayTrigger overlay={<Tooltip id="address-tooltip">
                        <div className='font-weight-bold text-dark-75 text-left'>
                          <div className='font-weight-bolder'>{data?.customer_address?.name}</div>
                          <div className='font-weight-bolder text-dark-50'>{data?.customer_address?.company}</div>
                          <hr className='my-1' />
                          <div>{data?.customer_address?.address_line1} {data?.customer_address?.address_line2}</div>
                          <div>{data?.customer_address?.post_code} {data?.customer_address?.city}</div>
                          <div>{getCountryList(locale).find(code => code.value === data?.customer_address?.country_code)?.label}</div>
                        </div>
                      </Tooltip>}>

                        <div type="button" className="text-decoration-none text-nowrap" id='btn_customer_preview'>
                          <div className="d-flex align-items-center flex-column flex-md-row text-dark-75 font-weight-bolder text-hover-primary">
                            <span className=" font-size-lg mr-2">{data?.customer_address?.name}</span>
                            {data?.customer_address?.company ? <span className="font-weight-bold text-dark-25" title={data.customer_address.company}>
                              {truncate(data.customer_address.company, { length: 30, separator: ' ' })}
                            </span> : ''}
                          </div>
                          <div className="text-muted font-weight-bold">
                            {getCountryList(locale).find(code => code.value === data?.customer_address?.country_code)?.label} {data?.customer_address?.post_code}
                          </div>
                        </div>

                      </OverlayTrigger>

                    </div>
                  </td>

                  <td className="text-right pr-5 pr-md-10">
                    <div className="text-dark-75 font-weight-bolder font-size-lg">
                      {convertPriceCurrency(+data?.total_sales, currency)}
                    </div>
                  </td>

                </tr>
              ))}

            </tbody>
          </Table>
          : <BlankMessage
            icon={<Icon.Customer />}
            message={toggleProperty && <FormattedMessage id={(total_invoice_count === 0) ? 'DASHBOARD.BLANK_MESSAGE' : 'DASHBOARD.BLANK_MESSAGE.NO_FILTER'} />}
          />
        }
      </CardBody>

    </Card>
  );
}
