import React from "react";
import { Card, CardBody, CardHeaderToolbar, CardSubHeader } from "_metronic/_partials/controls";
import { FormattedMessage } from "react-intl";
import { Alert, Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ListingActions } from "app/modules/product-listings/_redux/actions";
import { Icon } from "_metronic/_icons";



export function ConnectionPoliciesCard() {

  const dispatch = useDispatch();
  const ebayConnection = useSelector((state) => state.connections.ebayForEdit || {});

  const isPolicies = Object.values(ebayConnection?.policies || {}).some(array => Array.isArray(array) && array.length > 0);

  const { policies } = ebayConnection;

  const getPolicies = () => {
    dispatch(ListingActions.updateEbayPolicies(ebayConnection?.id));
  };

  const POLICIES = [
    {
      label: 'PRODUCT.EBAY.POLICY_FULFILLMENT',
      options: policies?.fulfillmentPolicyId?.map(p => ({ value: p.id, label: p.name })),
    },
    {
      label: 'PRODUCT.EBAY.POLICY_PAYMENT',
      options: policies?.paymentPolicyId?.map(p => ({ value: p.id, label: p.name })),
    },
    {
      label: 'PRODUCT.EBAY.POLICY_RETURN',
      options: policies?.returnPolicyId?.map(p => ({ value: p.id, label: p.name })),
    },
    {
      label: 'PRODUCT.EBAY.LOCATION',
      options:
        policies?.locations?.map(item => {
          const { merchantLocationKey, location: { address } } = item;
          return { value: merchantLocationKey, label: merchantLocationKey + ' - ' + Object.values(address).join(', ') };
        }),
    },
  ];


  return (
    <Card className="gutter-b">

      <CardSubHeader icon={<Icon.Compass />} title={<FormattedMessage id="PRODUCT.EBAY.POLICY" />}>
        <CardHeaderToolbar>
          <Button variant='outline-primary' size="sm" className="svg-icon" onClick={getPolicies} id='btn_policies'>
            <Icon.ArrowRepeat />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id="PRODUCT_LISTINGS.EBAY.POLICIES" />
            </span>
          </Button>
        </CardHeaderToolbar>
      </CardSubHeader>

      <CardBody>
        <Row>
          {isPolicies
            ? POLICIES.map((item, i) => (item.options?.length > 0 &&
              <Col lg="3" key={i} className="mb-3">
                <div className="d-flex flex-column">
                  <h6 className="text-dark-50 mw-200px mb-3">
                    <FormattedMessage id={item.label} />
                  </h6>

                  {item.options.length > 0 &&
                    <ul className='pl-5'>
                      {item.options.map((option, index) => (
                        <li key={index} className="mw-50px mb-2">
                          {option.label}
                        </li>
                      ))}
                    </ul>
                  }
                </div>
              </Col>
            ))
            : <Alert variant='light-warning' className='alert-custom alert-notice flex-column mx-3'>
              <FormattedMessage id='CONNECTIONS.SHOP.EBAY_POLICIES.WARNING' />
            </Alert>
          }
        </Row>
      </CardBody>

    </Card>
  );
}