import React from 'react'
import { useDispatch } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import { Card, CardBody, CardSubHeader, CardHeaderToolbar, RemotePagination } from '_metronic/_partials/controls'
import { headerSortingClasses, NoRecordsFoundMessage, sortCaret, UIDates } from '_metronic/_helpers';
import { allQueryKeys, DATE_OPTION } from '../../_context/ImportExportUIHelper';
import { BlankMessage } from "_metronic/_partials/components";
import * as columnFormatters from './ActionsColumnFormatter';
import { ExportFilter } from '../export-filter/ExportFilter';
import { importExportActions } from "../../_redux/actions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage, useIntl } from 'react-intl'
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';



export default function ExportTable({ exportData, loading, UIProps, count, totalCount }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const locale = useLang();

  const importDownload = (row) => dispatch(importExportActions.importExportDownload(row, locale));
  const importDelete = (id) => dispatch(importExportActions.importExportDelete(id, "export", UIProps.queryParams));

  const COLUMNS = [
    {
      dataField: 'module',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'pl-7',
      classes: 'pl-0 pl-md-5 py-3',
      headerStyle: { minWidth: '100px' },
      style: { height: '50px' },
      formatter: columnFormatters.NameColumnFormatter,
      formatExtraData: {
        importDownload,
      }
    },
    {
      dataField: 'date_range',
      text: intl({ id: 'IMPORT_EXPORT.EXPORT.DATE_RANGE' }),
      sort: true,
      sortCaret,
      headerClasses: 'text-center text-nowrap',
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'text-center',
      formatter: (cell, row) => (
        <div className="text-dark-75 font-weight-bold">
          {row.date_range?.title === "none" ? null : row.date_range?.title === "custom"
            ? UIDates.dateFormatter(row.date_range?.start_date) + " - " + UIDates.dateFormatter(row.date_range?.end_date)
            : <OverlayTrigger overlay={<Tooltip id="products-download-tooltip" className='font-weight-bold'>{UIDates.dateFormatter(row.date_range?.start_date) + " - " + UIDates.dateFormatter(row.date_range?.end_date)}</Tooltip>}>
              <span>
                {DATE_OPTION.find(x => x.value.title === row.date_range?.title)?.label}
              </span>
            </OverlayTrigger>
          }
        </div>
      ),
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'IMPORT_EXPORT.EXPORT.AT' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'text-center',
      headerClasses: 'text-center text-nowrap pl-5',
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) => (
        <div className="text-dark-50 font-weight-bold">
          {UIDates.formatDateTime(cell)}
        </div>
      ),
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      headerStyle: { minWidth: '100px', width: "5%" },
      headerClasses: 'text-center',
      classes: 'text-center',
      editable: false,
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        importDownload,
        importDelete,
      },
    },
  ];


  return (
    <Card className='gutter-b'>

      <CardSubHeader title={<FormattedMessage id='IMPORT_EXPORT.EXPORT_LIST' />} icon={<Icon.TextIndentLeft />}>
        <CardHeaderToolbar>
          <ExportFilter UIProps={UIProps} exportData={exportData} />
        </CardHeaderToolbar>
      </CardSubHeader>

      <CardBody>
        {totalCount === 0 && !loading
          ? <BlankMessage
            icon={<Icon.TextIndentLeft />}
            title={<FormattedMessage id='IMPORT_EXPORT.EXPORT.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='IMPORT_EXPORT.EXPORT.BLANK_MESSAGE' />}
            className="my-10"
            loading={loading}
          />
          : <>
            <BootstrapTable
              keyField="id"
              data={exportData || []}
              columns={COLUMNS}
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center table-sm overflow-hidden"
              bootstrap4
              bordered={false}
              condensed
              striped
              noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { UIProps.setFilterQuery('DELETE_PARAMS', allQueryKeys); }} />}
            />
            {exportData.length ? <RemotePagination
              queryParams={UIProps.queryParams?.pagination}
              setQueryParams={UIProps.setPaginationQuery}
              totalCount={count}
              entitiesCount={exportData?.length}
              loading={loading}
            /> : null}
          </>

        }
      </CardBody>

    </Card>
  )
}
