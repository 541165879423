import React from "react";
import { useHistory } from "react-router-dom";
import { ModuleRoutes } from "constants/moduleRoutes";
import { FormattedMessage } from "react-intl";
import { theme } from "_metronic/_helpers";
import { Button } from "react-bootstrap";
import { Icon } from '_metronic/_icons';



export function CustomersGrouping({ UIProps }) {

  const history = useHistory();

  const deleteAllSelectedCustomer = () => {
    const ids = UIProps.ids.join("&");
    history.push(ModuleRoutes.DELETE_CUSTOMER_LISTS_FN(ids))
  };


  return (
    <>

      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold text-nowrap ml-auto mr-2"
        style={{ height: theme.units.input.height['sm'], cursor: !UIProps.ids || (UIProps.ids.length <= 0 && 'not-allowed') }}
        onClick={() => UIProps.openExportCustomerDialog()}
        disabled={!UIProps.ids || UIProps.ids.length <= 0}
        id='btn_export'
      >
        <Icon.CloudDownload />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="IMPORT_EXPORT.EXPORT" />
        </span>
      </Button>

      <Button variant="light-primary" type="button" className="svg-icon svg-icon-sm font-weight-bold text-nowrap"
        style={{ height: theme.units.input.height['sm'] }}
        onClick={deleteAllSelectedCustomer}
        disabled={UIProps.ids.length <= 0}
        id="btn_multiple_delete"
      >
        <Icon.Trash />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="GENERAL.DELETE" />
        </span>
      </Button>

    </>
  );
}
