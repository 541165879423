import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UIDates } from '_metronic/_helpers';
import moment from 'moment';



export default function RemainingDays(targetDate) {

  if (!targetDate) return null
  const remainingDays = moment(targetDate).diff(moment(), 'days');

  const remainingColors = (day) => {
    return day > 0
      ? day < 7
        ? "warning"
        : day < 3
          ? "danger"
          : "success"
      : "danger";
  }

  return (
    <OverlayTrigger overlay={<Tooltip id="remaining-day-tooltip" className='font-weight-bold'>{UIDates.formatDateTime(targetDate)}</Tooltip>}>
      <label className={`label label-inline label-lg svg-icon svg-icon-sm text-nowrap label-outline-${remainingColors(remainingDays)}`}>
        <span className={`label label-lg label-dot label-${remainingColors(remainingDays)} mr-2`}></span>
        <span className='font-weight-bold'>{remainingDays > 0 ? `${remainingDays} days left` : `Expired`}</span>
      </label>
    </OverlayTrigger>
  );
};
