import React from 'react'
import { EXPORT_TYPE_OPTIONS } from 'app/modules/documents/_context/DocumentsUIHelpers'
import { SVFormControl, SVReactSelect } from '_metronic/_partials/controls'
import { DocumentActions } from 'app/modules/documents/_redux/actions';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { validator } from '../column-formatters/validator';
import BootstrapTable from 'react-bootstrap-table-next'
import { FormattedMessage, useIntl } from 'react-intl'
import { UserInfoDropdown } from '_metronic/_partials'
import { useWindowSize } from 'app/hooks';
import { useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap'
import { Field } from 'formik'



export default function ShippingCustoms({ positions, export_type, export_description, service }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();
  const { windowSize } = useWindowSize();

  const tableRowHandler = (oldValue, newValue, row, column) => {

    if (oldValue === newValue) { return; }

    if (column.dataField === 'weight') {
      row.weight = +row.weight.replace(',', '.');
    }

    dispatch(DocumentActions.updatePosition(row, column));
  };

  const EXPORT_OPTIONS = [
    {
      name: 'customs.export_type',
      label: 'DOCUMENT.SHIPPING.DHL.CUSTOMS.EXPORT_TYPE',
      component: SVReactSelect,
      options: EXPORT_TYPE_OPTIONS || [],
      placeholder: 'DOCUMENT.SHIPPING.DHL.CUSTOMS.EXPORT_TYPE_PLACEHOLDER',
      required: true,
      info: 'DOCUMENT.SHIPPING.DHL.CUSTOMS.EXPORT_TYPE_INFO',
      size: "12",
      hide: true,
    },
    {
      name: 'customs.export_description',
      label: 'DOCUMENT.SHIPPING.DHL.CUSTOMS.EXPORT_DESCRIPTION',
      component: SVFormControl,
      feedbackLabel: export_description?.length
        ? <span className={`${export_description && export_description?.length <= (80 * 80 / 100) ? 'text-success' : export_description && export_description?.length <= 80 ? 'text-warning' : 'text-danger'}`}>
          <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: 80 - export_description?.length }} />
        </span>
        : true,
      required: true,
      size: "12",
      hide: export_type === 'OTHER',
    }
  ]

  const COLUMNS = [
    {
      dataField: '#',
      text: intl({ id: 'GENERAL.NO' }),
      isDummyField: true,
      headerStyle: { minWidth: '50px', width: "2%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75',
      editable: false,
      formatter: (cell, row, rowIndex) => (rowIndex + 1),
    },
    {
      dataField: 'title',
      text: intl({ id: 'GENERAL.DESCRIPTION' }),
      headerStyle: { minWidth: '250px', width: "10%" },
      classes: 'font-weight-bold text-dark-75',
      editable: false,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem" }),
      // validator: (cell, row, column) => validator.isValidDescription(cell, row, intl),
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'quantity',
      text: intl({ id: 'PRODUCT.GENERAL.QUANTITY' }),
      headerStyle: { minWidth: '60px', width: "6%" },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editable: false,
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'weight',
      text: intl({ id: 'GENERAL.WEIGHT' }),
      headerStyle: { width: "6%" },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editorStyle: () => ({ height: "34.7px", textAlign: "center" }),
      validator: (cell, row, column) => validator.isValidWeight(cell, service, intl),
      formatter: (cell, row) => <div type="button" className="edit-table">{cell ? +cell : 0}</div>,
    },
    {
      dataField: 'total_weight',
      text: intl({ id: 'GENERAL.TOTAL_WEIGHT' }),
      headerStyle: { width: "6%" },
      headerClasses: 'text-center',
      classes: 'font-weight-bolder text-center',
      editable: false,
      formatter: (cell, row) => (row?.weight && (row?.quantity * row?.weight)?.toFixed(2) + ' kg') || 0
    },
  ];


  return (
    <>
      <Col lg="12">
        <div className="separator separator-dashed my-2" />

        <label className='d-flex align-items-center font-weight-bold text-capitalize text-dark-75 mt-4'>
          <FormattedMessage id="DOCUMENT.SHIPPING.DHL.CUSTOMS_TITLE" />
          <UserInfoDropdown size="w-75" title={<FormattedMessage id='DOCUMENT.SHIPPING.DHL.CUSTOMS_INFO_TITLE' />} description={<FormattedMessage id='DOCUMENT.SHIPPING.DHL.CUSTOMS_INFO_DESCRIPTION' />} />
        </label>

        {/* <PerfectScrollbar className="scroll pr-5 mr-n5" style={{ maxHeight: '500px' }}> */}
        <BootstrapTable
          keyField="id"
          key={positions.reduce((sum, item) => sum + item.weight, 0)}
          data={positions || []}
          columns={COLUMNS}
          wrapperClasses="table-responsive pb-5"
          classes="table table-head-custom table-vertical-center"
          headerClasses="bg-info-o-10"
          bootstrap4
          bordered={false}
          deleteRow={true}
          condensed
          cellEdit={cellEditFactory({
            mode: windowSize < 576 ? 'click' : 'dbclick',
            blurToSave: true,
            afterSaveCell: tableRowHandler,
          })}
        />
        {/* </PerfectScrollbar> */}

      </Col>

      {EXPORT_OPTIONS.map((item) => (item.hide &&
        <Col lg={item.size || "12"} key={item.name}>
          <Field component={item.component}
            {...{
              name: item.name,
              type: item.type,
              label: item.label && intl({ id: item.label }),
              placeholder: item.placeholder && intl({ id: item.placeholder }),
              ...(item.noOptionsMessage && { noOptionsMessage: item.noOptionsMessage }),
              options: item.options,
              onBlur: item.onBlur,
              withFeedbackLabel: true,
              feedbackLabel: item.feedbackLabel ?? true,
              required: item.required,
              // disabled: item.disabled,
            }}
          />
        </Col>
      ))}
    </>
  )
}
