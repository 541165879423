import { call, put, select, takeEvery } from "redux-saga/effects";
import { documentMiddleware, tokenErrorHandler } from "middlewares";
import { digitalProductsMiddleware } from "middlewares/digital-products/middleware";
import { digitalProductsActions } from "./actions";
import ActionTypes from "./actionTypes";
import { AuthActions } from "app/pages/auth/_redux/actions";



// Get Digital Products keys
function* getDigitalProductKeys({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countDigitalProduct = yield call(digitalProductsMiddleware.getCount, accessToken, queryParams);
    const response = yield call(digitalProductsMiddleware.getDigitalProducts, accessToken, queryParams);
    yield put(digitalProductsActions.getDigitalProductKeysSuccess(response.data, countDigitalProduct.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, digitalProductsActions.getDigitalProductKeysFail());
  }
}

// Get Digital Products Invoices
function* getDigitalProductInvoices({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(digitalProductsMiddleware.getDigitalProductInvoices, accessToken, queryParams);

    yield put(digitalProductsActions.getDigitalProductInvoicesSuccess(response.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, digitalProductsActions.getDigitalProductInvoicesFail());
  }
}


// Get Customer Details
function* getCustomerDetails({ payload: { documentId } }) {
  try {

    const access = {
      accessToken: yield select(state => state.auth.access_token),
      documentModel: "orders",
    }

    const response = yield call(documentMiddleware.getDocumentById, access, documentId, 'customer_fields');
    yield put(digitalProductsActions.getCustomerDetailsSuccess(response.data));

  } catch (err) {
    yield put(digitalProductsActions.getCustomerDetailsFail(err));
  }
}


// Send Mail Key
function* sendMailKey({ payload: { ids, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(digitalProductsMiddleware.sendMailKey, accessToken, ids);
    yield put(digitalProductsActions.sendMailKeySuccess());
    yield put(digitalProductsActions.getDigitalProductKeys(queryParams));

  } catch (err) {
    yield call(tokenErrorHandler, err, digitalProductsActions.sendMailKeyFail());
  }
}


// Send Mail Key
function* deleteKeyReserve({ payload: { ids, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(digitalProductsMiddleware.deleteKeyReserve, accessToken, ids);
    yield put(digitalProductsActions.deleteKeyReserveSuccess());
    yield put(digitalProductsActions.getDigitalProductKeys(queryParams));

  } catch (err) {
    yield call(tokenErrorHandler, err, digitalProductsActions.deleteKeyReserveFail());
  }
}


function* printDocument({ payload: { data, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(documentMiddleware.printDocument, accessToken, data);

    if (response.data.status === 'error') {
      yield put(digitalProductsActions.printDocumentFail(response.data));
    } else {
      yield put(digitalProductsActions.getDigitalProductInvoices(queryParams));
      yield put(digitalProductsActions.printDocumentSuccess(response.data));
    }

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(digitalProductsActions.printDocumentFail(err));
  }
}

function* preparationDocument({ payload: { data } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(digitalProductsMiddleware.preparationDocument, accessToken, data);

    yield put(digitalProductsActions.preparationDocumentSuccess(response.data));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(digitalProductsActions.preparationDocumentFail(err));
  }
}


export function* digitalProductSaga() {
  yield takeEvery(ActionTypes.GET_DIGITAL_PRODUCT_KEYS, getDigitalProductKeys);
  yield takeEvery(ActionTypes.GET_DIGITAL_PRODUCT_INVOICES, getDigitalProductInvoices);
  yield takeEvery(ActionTypes.PRINT_DOCUMENT, printDocument);
  yield takeEvery(ActionTypes.PREPARATION_DOCUMENT, preparationDocument);
  yield takeEvery(ActionTypes.GET_CUSTOMER_DETAILS, getCustomerDetails);
  yield takeEvery(ActionTypes.SEND_MAIL_KEY, sendMailKey);
  yield takeEvery(ActionTypes.DELETE_KEY_RESERVE, deleteKeyReserve);
}