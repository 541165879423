import React, { createContext, useContext, useState } from 'react';
import { useIntl } from 'react-intl';



const DigitalProductUIContext = createContext();
export const useDigitalProductsUIContext = () => useContext(DigitalProductUIContext)


export const DigitalProductsUIProvider = ({ children }) => {

  const { formatMessage: intl } = useIntl()
  const [ids, setIds] = useState([]);
  const [documentId, setDocumentId] = useState(null);

  //* Customer details dialog
  const [showCustomerDetailsDialog, setShowCustomerDetailsDialog] = useState(false);
  const openCustomerDetailsDialog = id => {
    setDocumentId(id);
    setShowCustomerDetailsDialog(true);
  };
  const closeCustomerDetailsDialog = () => {
    setDocumentId(null);
    setShowCustomerDetailsDialog(false);
  };

  //* Send Mail Key dialog
  const [showSendMailKeyDialog, setShowSendMailKeyDialog] = useState(false);
  const openSendMailKeyDialog = ids => {
    setIds(ids);
    setShowSendMailKeyDialog(true);
  };
  const closeSendMailKeyDialog = () => {
    setIds([]);
    setShowSendMailKeyDialog(false);
  };

  //* Send Mail Key dialog
  const [showReserveDialog, setShowReserveDialog] = useState(false);
  const openReserveDialog = ids => {
    setIds(ids);
    setShowReserveDialog(true);
  };
  const closeReserveDialog = () => {
    setIds([]);
    setShowReserveDialog(false);
  };

  // Print
  const [showPrintDocumentDialog, setShowPrintDocumentDialog] = useState(false);
  const openPrintDocumentDialog = () => setShowPrintDocumentDialog(true);
  const closePrintDocumentDialog = () => setShowPrintDocumentDialog(false);


  // Label send email modal
  const [showSendEmailInvoice, setShowSendEmailInvoice] = useState(false);
  const sendEmailInvoiceDialog = (documentId) => {
    setDocumentId(documentId);
    setShowSendEmailInvoice(true);
  };
  const closeSendEmailInvoiceDialog = () => {
    setDocumentId(null);
    setShowSendEmailInvoice(false);
  };


  // Multi invoice Document
  const [showInvoicesToEbayDialog, setShowInvoicesToEbayDialog] = useState(false);
  const openInvoicesToEbayDialog = () => setShowInvoicesToEbayDialog(true);
  const closeInvoicesToEbayDialog = () => setShowInvoicesToEbayDialog(false);

  const initialDateRange = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };

  const [dateRange, setDateRange] = useState(initialDateRange);
  const changeDateRange = (date = initialDateRange) => setDateRange(date);

  const value = {
    ids,
    setIds,

    documentId,
    setDocumentId,

    showCustomerDetailsDialog,
    openCustomerDetailsDialog,
    closeCustomerDetailsDialog,

    showSendMailKeyDialog,
    openSendMailKeyDialog,
    closeSendMailKeyDialog,

    showReserveDialog,
    openReserveDialog,
    closeReserveDialog,

    changeDateRange,
    dateRange,
    initialDateRange,
    setDateRange,

    showPrintDocumentDialog,
    openPrintDocumentDialog,
    closePrintDocumentDialog,

    showSendEmailInvoice,
    sendEmailInvoiceDialog,
    closeSendEmailInvoiceDialog,

    showInvoicesToEbayDialog,
    openInvoicesToEbayDialog,
    closeInvoicesToEbayDialog,
  };

  return (
    <DigitalProductUIContext.Provider value={value}>
      {children}
    </DigitalProductUIContext.Provider>
  );
};