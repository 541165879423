import React from 'react';
import { Route } from 'react-router-dom';
import { TicketDeleteDialog } from './support-dialogs/TicketDeleteDialog';
import { TicketStatusDialog } from './support-dialogs/TicketStatusDialog';
import { TicketEdit } from './support-dialogs/TicketEdit';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { getLastSearch } from '_metronic/_helpers';
import { SupportCard } from './SupportCard';



export function SupportPage() {
  const lastSearch = getLastSearch()
  return (
    <>
      <SupportCard />
      <TicketStatusDialog />

      <Route path={ModuleRoutes.NEW_TICKET}>
        {({ history, match }) => (
          <TicketEdit
            show={match != null}
            id={match && match.params.id}
            onHide={() => history.push(ModuleRoutes.SUPPORT + lastSearch)}
          />
        )}
      </Route>
      <Route path={ModuleRoutes.DELETE_TICKET}>
        {({ history, match }) => (
          <TicketDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => history.push(ModuleRoutes.SUPPORT + lastSearch)}
          />
        )}
      </Route>
    </>
  );
}
