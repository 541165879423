import React from "react";
import { Card, CardBody, CardSubHeader, } from "_metronic/_partials/controls";
import { UserInfoDropdown } from "_metronic/_partials";
import { FormattedMessage } from "react-intl";
import { BASE_URL } from "constants/apiUrl";
import { Icon } from '_metronic/_icons';



export function LogoCard({ logo }) {
  return (
    <Card className="gutter-b">

      <CardSubHeader
        title={<FormattedMessage id='GENERAL.LOGO' />}
        icon={<Icon.Image />}
        info={<UserInfoDropdown description={<FormattedMessage id='USER_GUIDE.SETTINGS.COMPANY_LOGO' />} />}
      />

      <CardBody className="d-flex justify-content-center" style={{ minHeight: "160px" }}>
        {logo && <img width="300" src={`${BASE_URL}/${logo}`} alt="..." />}
      </CardBody>

    </Card>
  );
}
