import React from 'react'
import { Icon } from "_metronic/_icons";
import { ModuleRoutes } from "constants/moduleRoutes";
import { NewButton } from '_metronic/_partials';



export const SETTINGS_MENU = [
  {
    icon: <Icon.House />,
    title: "COMPANY.TITLE",
    route: ModuleRoutes.SETTINGS_COMPANY,
    toolbar: <NewButton link={ModuleRoutes.SETTINGS_COMPANY_EDIT} label="GENERAL.EDIT" className="ml-2" />,
  },
  {
    icon: <Icon.Bookmarks />,
    title: "CATEGORY.TITLE_PLURAL",
    route: ModuleRoutes.SETTINGS_CATEGORY,
  },
  {
    icon: <Icon.Lock />,
    title: "AUTH.GENERAL.ACCOUNT_SETTINGS",
    route: ModuleRoutes.SETTINGS_LOGIN,
  },
  {
    icon: <Icon.Box />,
    title: "PRODUCT.TITLE",
    route: ModuleRoutes.SETTINGS_PRODUCT,
  },
  {
    icon: <Icon.Truck />,
    title: "GENERAL.SHIPPING",
    route: ModuleRoutes.SETTINGS_SHIPPING,
  },
  {
    icon: <Icon.Password />,
    title: "MENU.ASIDE.SETTING.DIGITAL_PRODUCTS",
    route: ModuleRoutes.SETTINGS_DOCUMENT_FN('digital_product'),
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.ORDER_TITLE",
    route: ModuleRoutes.SETTINGS_DOCUMENT_FN('orders'),
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.INVOICE_TITLE",
    route: ModuleRoutes.SETTINGS_DOCUMENT_FN('invoices'),
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.OFFER_TITLE",
    route: ModuleRoutes.SETTINGS_DOCUMENT_FN('offers'),
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.WAYBILL_TITLE",
    route: ModuleRoutes.SETTINGS_DOCUMENT_FN('waybills'),
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.CREDIT_TITLE",
    route: ModuleRoutes.SETTINGS_DOCUMENT_FN('credits'),
  },
];


export const INITIAL_DEFAULT_SHIPPING = {
  id: '',
  profile_name: '',
  unlabeled: false,
  service: '',
  type: 'shipment',
  weight: 2,
  price: 0,
  default: true,
};

export const INITIAL_VALUES = {

  // Default Shipping Settings
  shipping_settings: {
    settings_type: 'shipping',
    default_shipping: [],
  },
  // Default Product Settings
  product_settings: {
    settings_type: 'products',
    default_product: {
      tax: "",
      category: "",
      unit: "",
      currency: "EUR",
    },
    stock_control: {
      kaufland: false,
      amazon: false,
      ebay: false,
      shopware5: false,
      shopware6: false,
    },
    variant_options: [],
  },

  // Default Document Settings
  order_settings: {
    settings_type: 'orders',
    default_subtitle: {
      header: "Vielen Dank für Ihre Bestellung bei uns.",
      footer: "Bei Fragen oder Problemen zu Ihrer Bestellung helfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
    },
    default_payment: {
      method: "",
    },
    default_number_range: {
      prefix: "",
      date: "YYYY",
      min_length: null,
      next_value: "",
    },
  },

  offer_settings: {
    settings_type: 'offers',
    default_subtitle: {
      header: "Vielen Dank, dass Sie sich bei uns entschieden haben.",
      footer: "Bei Fragen oder Problemen zu Ihrer Angebot helfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
    },
    default_payment: {
      method: "",
    },
    default_number_range: {
      prefix: "",
      date: "YYYY",
      min_length: null,
      next_value: "",
    },
  },

  invoice_settings: {
    settings_type: 'invoices',
    default_subtitle: {
      header: "Vielen Dank, dass Sie sich bei uns entschieden haben.",
      footer: "Bei Fragen oder Problemen zu Ihrer Rechnung helfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
    },
    default_payment: {
      method: "",
    },
    default_number_range: {
      prefix: "",
      date: "YYYY",
      min_length: null,
      next_value: "",
    },
    print_details: {
      payment_method: true,
    },
  },

  credit_settings: {
    settings_type: 'credits',
    default_subtitle: {
      header: "Vielen Dank, dass Sie sich bei uns entschieden haben.",
      footer: "Bei Fragen oder Problemen zu Ihrer Gutschrift helfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
    },
    default_payment: {
      method: "",
    },
    default_number_range: {
      prefix: "",
      date: "YYYY",
      min_length: null,
      next_value: "",
    },
  },

  waybill_settings: {
    settings_type: 'waybills',
    default_subtitle: {
      header: "Vielen Dank, dass Sie sich bei uns entschieden haben.",
      footer: "Bei Fragen oder Problemen zu Ihrem Lieferschein helfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
    },
    default_payment: {
      method: "",
    },
    default_number_range: {
      prefix: "",
      date: "YYYY",
      min_length: null,
      next_value: "",
    },
  },

  digital_product_settings: {
    settings_type: 'digital_product',
    default_subtitle: {
      header: "Vielen Dank, dass Sie sich bei uns entschieden haben.",
      footer: "Bei Fragen oder Problemen zu Ihrem digitales Product helfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
    },
    default_payment: {
      method: "",
    },
    default_number_range: {
      prefix: "",
      date: "YYYY",
      min_length: null,
      next_value: "",
    },
    digital_product: {
      mail_template: "Bitte teilen Sie Ihren Lizenzschlüssel mit niemandem und bewahren Sie ihn sicher auf. Ein Lizenzschlüssel ist wie ein digitaler Schlüssel zu Ihrem Produkt und sollte vertraulich behandelt werden, um unbefugte Nutzung zu verhindern.",
      status: false,
      trigger: "is_order",
      contact_email: "",
    },
  },


  // Default Dashboard Card View
  dashboard_settings: {
    id: '',
    settings_type: 'dashboard',
    default_card_view: {
      orders: true,
      sales: true,
      open_invoices: true,
      sales_by_channel: true,
      sales_by_country: true,
      sales_volume: true,
      top_products: true,
      top_customers: true,
    },
    currency: 'EUR',
  }
};

export const getSettingsByType = (settings) => {
  switch (settings.settings_type) {
    case 'shipping':
      return { shippingSettings: settings };
    case 'products':
      return { productSettings: settings };
    case 'dashboard':
      return { dashboardSettings: settings };
    case 'orders':
      return { orderSettings: settings };
    case 'offers':
      return { offerSettings: settings };
    case 'invoices':
      return { invoiceSettings: settings };
    case 'credits':
      return { creditSettings: settings };
    case 'waybills':
      return { waybillSettings: settings };
    case 'digital_product':
      return { digitalProductSettings: settings };
    default:
      return;
  }
};

export const setSettingsByType = (userSettingsArray) => {
  return userSettingsArray.reduce((result, userSettings) => {
    switch (userSettings.settings_type) {
      case 'shipping':
        return { ...result, shippingSettings: userSettings };
      case 'products':
        return { ...result, productSettings: userSettings };
      case 'dashboard':
        return { ...result, dashboardSettings: userSettings };
      case 'orders':
        return { ...result, orderSettings: userSettings };
      case 'offers':
        return { ...result, offerSettings: userSettings };
      case 'invoices':
        return { ...result, invoiceSettings: userSettings };
      case 'credits':
        return { ...result, creditSettings: userSettings };
      case 'waybills':
        return { ...result, waybillSettings: userSettings };
      case 'digital_product':
        return { ...result, digitalProductSettings: userSettings };
      default:
        return result;
    }
  }, {});
};

