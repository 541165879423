import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { NoRecordsFoundMessage, UIDates, getSelectRow, headerSortingClasses, sortCaret } from '_metronic/_helpers';
import { useProductsUIContext } from '../_context/ProductsUIContext';
import { RemotePagination } from '_metronic/_partials/controls';
import * as HELPERS from '../_context/ProductsUIHelpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as columnFormatters from './column-formatters';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { ProductActions } from '../_redux/actions';
import { validator } from './validators/validators';
import { useLoadingHandler } from 'app/hooks';
import { BASE_URL } from 'constants/apiUrl';
import { Icon } from '_metronic/_icons';
import { useIntl } from 'react-intl';
import { isEqual } from 'lodash';



export function ProductsTable({ queryParams, setPaginationQuery, setOrderQuery, entities, loading, countProducts, setSearchQuery, setFilterQuery }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const UIContext = useProductsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    openProductDetailsDialog: UIContext.openProductDetailsDialog,
  }),
    [UIContext]
  );

  //net gross price calculation
  const tableRowHandler = (oldValue, newValue, row, column) => {
    if (isEqual(oldValue, newValue)) {
      return;
    }

    if (['pricing.purchase_price', 'pricing.gross_price', 'quantity'].includes(column.dataField) && newValue.includes(",")) {
      newValue = newValue.replace(",", ".");
    }

    let updateProduct;
    if (column.dataField === 'pricing.tax') {
      updateProduct = { pricing: { ...row.pricing, tax: +newValue } };
    } else if (column.dataField === 'pricing.purchase_price') {
      updateProduct = { pricing: { ...row.pricing, purchase_price: +newValue } };
    } else if (column.dataField === 'pricing.gross_price') {
      updateProduct = { pricing: { ...row.pricing, gross_price: +newValue } };
    } else if (column.dataField === 'quantity') {
      updateProduct = { quantity: +newValue };
    } else if (column.dataField === 'name') {
      updateProduct = { name: newValue };
    }

    dispatch(ProductActions.updateProductFromTableField(row.id, updateProduct, queryParams));
  };

  const useLoading = useLoadingHandler(loading, ProductActions.clearLoadingEffects());
  const editorStyle = (cell, row, rowIndex, colIndex) => ({ height: '32px' });

  const COLUMNS = [
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '500px' },
      headerClasses: 'text-nowrap pl-7',
      classes: 'font-weight-bold p-2',
      editable: columnFormatters.editableFormatter2,
      editorStyle,
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          <div type="button" className="symbol symbol-40 symbol-light mr-3" onClick={() => UIProps.openProductDetailsDialog(row.id)} id={`btn_open_product_detail_${row.id}`}>
            <span className="symbol-label overflow-hidden">
              {row.images[0]?.thumbnail
                ? <img src={`${BASE_URL}/${row.images[0]?.thumbnail}`} className="h-100" alt={cell} />
                : <Icon.Image className="h-100 w-50" style={{ opacity: 0.3 }} />
              }
            </span>
          </div>
          <div>
            <Link className="font-weight-bolder text-dark-75 text-hover-primary preview mb-1" to={ModuleRoutes.EDIT_PRODUCT_FN(row.id)} id={`btn_edit_product_${row.id}`}>
              <span>{cell}</span>
              <span className="text-dark-25 svg-icon svg-icon-sm invisible pl-2">
                <Icon.Pencil />
              </span>
            </Link>

            <div className="font-size-sm font-weight-bold text-dark-50">
              {row.sku}
            </div>

          </div>
        </div>
      ),
    },
    {
      dataField: 'category.name',
      text: intl({ id: 'CATEGORY.TITLE' }),
      sort: true,
      sortCaret,
      headerStyle: { minWidth: '150px', },
      editable: false,
      editorStyle,
      formatter: (cell, row) => (cell &&
        <OverlayTrigger overlay={<Tooltip id="quick-category-tooltip" className="font-weight-bold">{row.category?.path}</Tooltip>}>
          <div className="label label-outline-warning label-inline">
            <span className="label label-dot label-warning mr-2" />
            <span className="font-weight-bold text-nowrap">{cell}</span>
          </div>
        </OverlayTrigger>
      ),
    },
    {
      dataField: 'inventories',
      text: intl({ id: 'GENERAL.PLATFORM' }),
      headerClasses: 'text-center',
      headerStyle: { minWidth: '100px', },
      classes: 'text-center',
      editable: false,
      editorStyle,
      formatter: columnFormatters.StatusColumnFormatter,
    },
    {
      dataField: 'quantity',
      text: intl({ id: 'PRODUCT.GENERAL.QUANTITY' }),
      // type: 'number',
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '60px', width: "10%" },
      headerClasses: 'text-center text-nowrap pl-5',
      classes: 'text-center text-nowrap',
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      editable: columnFormatters.editableQuantity,
      validator: (cell, row, intl) => validator.quantityIsValid(cell, row, intl),
      formatter: columnFormatters.QuantityFormatter,
    },
    {
      dataField: 'pricing.tax',
      text: intl({ id: 'PRODUCT.TABLE.TAX' }),
      type: 'number',
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '90px', width: "10%" },
      headerClasses: 'text-center pl-5',
      classes: 'font-weight-bolder text-center text-nowrap text-dark-75',
      editable: columnFormatters.editableFormatter2,
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      editor: { type: Type.SELECT, options: HELPERS.TAX_OPTIONS },
      validator: validator.priceIsValid,
      formatter: columnFormatters.TaxFormatter,
    },
    {
      dataField: 'pricing.purchase_price',
      text: intl({ id: 'PRODUCT.TABLE.EK' }),
      headerStyle: { minWidth: '100px', width: "10%" },
      headerClasses: 'text-right pr-4',
      classes: 'font-weight-bolder text-right text-nowrap text-dark-75',
      editable: columnFormatters.editableFormatter,
      editorStyle: () => ({ height: '32px', textAlign: 'end' }),
      validator: validator.priceIsValid,
      formatter: columnFormatters.Ek1PriceFormatter,
    },
    {
      dataField: 'pricing.net_price',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_NET' }),
      // type: 'number',
      // sort: true,
      // sortCaret,
      headerSortingClasses,
      headerStyle: { minWidth: '100px', width: "10%" },
      headerClasses: 'text-right text-nowrap',
      classes: 'font-weight-bolder text-right text-nowrap text-dark-75',
      editable: false,
      formatter: columnFormatters.SPNetFormatter,
    },
    {
      dataField: 'pricing.gross_price',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_GROSS' }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerStyle: { minWidth: '100px', width: "10%" },
      headerClasses: 'text-right text-nowrap',
      classes: 'font-weight-bolder text-right text-nowrap text-dark-75 pr-3',
      onSort: setOrderQuery,
      editable: columnFormatters.editableFormatter,
      editorStyle: () => ({ height: '32px', textAlign: 'end' }),
      validator: validator.priceIsValid,
      formatter: columnFormatters.SPGrossFormatter,
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.REGISTRATION_DATE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap pl-5',
      headerStyle: { minWidth: '100px', width: '8%' },
      classes: 'text-center',
      formatter: (cell, row) => (
        <div className='font-size-sm text-dark-50 font-weight-bold'>
          {UIDates.formatDateTime(cell)}
        </div>
      )
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      headerStyle: { minWidth: '100px' },
      headerClasses: 'text-center',
      classes: 'text-center text-nowrap',
      editable: false,
      editorStyle,
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditProductPage: (id) => ModuleRoutes.EDIT_PRODUCT_FN(id),
        openDeleteProductDialog: (id) => ModuleRoutes.DELETE_PRODUCT_FN(id),
        copyProductForCreateNewOne: (id) => ModuleRoutes.COPY_PRODUCT_FN(id),
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={entities || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        striped
        condensed
        defaultSorted={HELPERS.defaultSorted}
        noDataIndication={<NoRecordsFoundMessage loading={useLoading} onClick={() => { setSearchQuery(''); setFilterQuery("DELETE_PARAMS", HELPERS.allFilterKeys); }} />}
        cellEdit={cellEditFactory({
          mode: 'dbclick',
          blurToSave: true,
          autoSelectText: true,
          afterSaveCell: tableRowHandler,
        })}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: entities,
        })}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={countProducts}
        entitiesCount={entities?.length}
        loading={useLoading}
        countForPagination={countProducts}
        paginationSize={8}
      />
    </>
  );
}
