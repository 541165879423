/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import * as columnFormatters from './column-formatters/ColumnFormatters';
import { useDocumentsUIContext } from '../../_context/DocumentsUIContext';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UIDates, convertPriceCurrency, headerSortingClasses, sortCaret } from '_metronic/_helpers';
import * as UIHelpers from '../../_context/DocumentsUIHelpers';
import { BlankMessage } from '_metronic/_partials/components';
import { validator } from './column-formatters/validator';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormattedMessage, useIntl } from 'react-intl';
import { DocumentActions } from '../../_redux/actions';
import { Icon } from '_metronic/_icons';
import { toast } from 'react-toastify';
import { find } from 'lodash';



export function ShippingTable({ shipping, documentId, isConvert, documentType }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const { document, positionTotals } = useSelector(state => ({
    document: state.documents.documentForEdit,
    positionTotals: state.documents.positionTotals
  }), shallowEqual);

  const currency = document?.marketplace === "" ? positionTotals?.currency : document?.pricing_summary.currency;

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    openPrintLabelsEditDialog: UIContext.openPrintLabelsEditDialog,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
    openDeleteLabelDialog: UIContext.openDeleteLabelDialog,
    sendEmailLabelDialog: UIContext.sendEmailLabelDialog,
    openLabelEditDialog: UIContext.openLabelEditDialog,
  }), [UIContext]);


  const openPrintLabelsDialog = (row, type) => {
    const findMethod = find(UIHelpers.LABEL_OPTIONS, { value: row.service });
    if (findMethod) {
      dispatch(DocumentActions.getLabel([row.id], type));
      UIProps.openPrintLabelsDialog(findMethod.value, documentId, '', type);
    } else {
      toast.error(<FormattedMessage id="GENERAL.FAIL" />);
    }
  };

  const tableRowHandler = (oldValue, newValue, row, column) => {

    if (oldValue === newValue) { return; }

    if (column.dataField === 'weight') {
      row.weight = +row.weight.replace(',', '.');
    };

    if (column.dataField === 'price') {
      row.price = +row.price.replace(',', '.');
    };
    dispatch(DocumentActions.updateLabel({ ...row, documentId }))
  };

  const mailLabelHandler = (row) => {
    if (row.type === "return" && documentId) {
      UIProps.sendEmailLabelDialog(documentId, row.id);
    }
  };


  const COLUMNS = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true,
      sort: true,
      sortCaret,
      headerSortingClasses,
    },
    {
      dataField: '#',
      text: intl({ id: 'GENERAL.NO' }),
      isDummyField: true,
      headerStyle: { minWidth: '50px', width: "5%" },
      headerClasses: 'text-center pl-7',
      classes: 'text-center font-weight-bold text-dark-75 pl-7',
      editable: false,
      formatter: (cell, row, rowIndex) => (rowIndex + 1),
    },
    {
      dataField: 'label',
      text: intl({ id: 'GENERAL.LABEL' }),
      headerStyle: { minWidth: '60px', width: "25%" },
      headerClasses: 'text-left pl-4',
      classes: 'text-left font-weight-bold text-dark-75',
      editable: false,
      formatter: columnFormatters.LabelFormatter,
      formatExtraData: {
        documentId,
        openPrintLabelsDialog,
        openLabelEditDialog: UIProps.openLabelEditDialog
      }
    },
    {
      dataField: 'type',
      text: intl({ id: 'CONNECTIONS.TYPE' }),
      headerStyle: { width: "5%" },
      headerClasses: 'text-center',
      classes: 'text-center text-dark-75',
      editable: false,
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="type-tooltip"><FormattedMessage id={cell === "return" ? 'DOCUMENT.SHIPPING.TYPE_RETURN' : 'DOCUMENT.SHIPPING.TYPE_SHIPMENT'} /></Tooltip>}>
          <div className="svg-icon svg-icon-lg">
            {cell === "return" ? <Icon.Shipping className="text-danger" style={{ transform: "scaleX(-1)" }} /> : <Icon.Shipping />}
          </div>
        </OverlayTrigger>
      )
    },
    {
      dataField: 'dp_product_name',
      text: intl({ id: 'GENERAL.NAME' }),
      headerStyle: { minWidth: '60px', width: "20%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75',
      hidden: !(shipping?.[0]?.service === 'deutsche_post' && !shipping?.[0]?.unlabeled),
      editable: (cell, row) => row.unlabeled && !isConvert,
      style: (cell, row) => ({ cursor: row.unlabeled && !isConvert ? 'pointer' : 'default' }),
      editorStyle: () => ({ height: "32px", textAlign: "center" }),
      formatter: (cell, row) => <div className={` ${row.unlabeled && !isConvert ? `edit-table` : ""} `}>{cell}</div>,
    },
    {
      dataField: 'weight',
      text: intl({ id: 'GENERAL.WEIGHT' }),
      headerStyle: { width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75',
      editable: (cell, row) => row.unlabeled && !isConvert,
      style: (cell, row) => ({ cursor: row.unlabeled && !isConvert ? 'pointer' : 'default' }),
      editorStyle: (row) => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
      validator: (cell, row, column) => validator.isValidWeight(cell, row?.service, intl),
      formatter: (cell, row) => <div className={` ${row.unlabeled && !isConvert ? `edit-table ${cell ? "" : "form-control form-control-sm is-invalid"} ` : ""} `}>{cell}</div>,
    },
    {
      dataField: 'delivery_date',
      text: intl({ id: 'GENERAL.DELIVERY_DATE' }),
      type: 'date',
      headerStyle: { width: "15%" },
      headerClasses: 'text-center ',
      classes: 'text-center',
      editor: { type: Type.DATE },
      editable: (cell, row) => row.unlabeled && !isConvert,
      style: (cell, row) => ({ cursor: row.unlabeled && !isConvert ? 'pointer' : 'default' }),
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
      formatter: (cell, row) => <div className={`font-weight-bold ${row.unlabeled && !isConvert ? `edit-table ` : ""}`}>{UIDates.formatDate(cell)}</div>
    },
    {
      dataField: 'price',
      text: intl({ id: 'DOCUMENT.SHIPPING_PRICE' }),
      headerStyle: { minWidth: '140px', width: "15%" },
      headerClasses: 'text-right pr-4',
      classes: 'text-right font-weight-bolder text-dark-75',
      style: (cell, row) => ({ cursor: (document?.marketplace === "" && !isConvert && documentType !== "waybills") ? 'pointer' : 'default' }),
      editable: (cell, row) => (document?.marketplace === "" && !isConvert && documentType !== "waybills"),
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "right" }),
      validator: (cell, row, column) => validator.isValidPrice(cell, intl),
      formatter: (cell, row) => {
        const isEditable = document?.marketplace === "" && documentType !== "waybills" && row.unlabeled && !isConvert;
        return (
          <div className={isEditable ? "edit-table" : "pr-4"}>
            {convertPriceCurrency(+cell, currency)}
          </div>
        );
      }
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.CREATE_DATE' }),
      type: 'date',
      headerStyle: { minWidth: '80px', width: "15%" },
      headerClasses: 'text-center ',
      classes: 'text-center',
      editable: false,
      formatter: (cell, row) => (
        <div className='font-size-sm text-dark-50 font-weight-bold'>
          {cell ? UIDates.formatDateTime(cell) : row.delivery_date ? UIDates.formatDate(row.delivery_date) : null}
        </div>
      )
    },
    {
      dataField: 'custom',
      text: intl({ id: 'DOCUMENT.SHIPPING.DHL.CUSTOMS' }),
      headerStyle: { minWidth: '50px', width: "5%" },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editable: false,
      hidden: !shipping?.some(item => item.custom),
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
      formatter: (cell, row) => (row?.custom &&
        <OverlayTrigger overlay={<Tooltip id="shipping-print-tooltip"><FormattedMessage id="GENERAL.PRINT.CUSTOMS_DECLARATION" /></Tooltip>}>
          <a className="btn btn-icon btn-hover-warning btn-sm" onClick={() => openPrintLabelsDialog(row, 'customs')}>
            <span className="svg-icon svg-icon-md">
              <Icon.FileEarmarkText />
            </span>
          </a>
        </OverlayTrigger>
      )
    },
    {
      dataField: 'action',
      text: "...",
      headerStyle: { minWidth: '50px', width: "7%" },
      headerClasses: 'font-size-h3 text-center pt-0 pr-9',
      classes: 'text-center text-nowrap pr-9',
      editable: false,
      formatter: columnFormatters.ActionFormatter,
      formatExtraData: {
        documentId,
        mailLabelHandler,
        openPrintLabelsDialog,
        openLabelEditDialog: UIProps.openLabelEditDialog,
        openDeleteLabelDialog: UIProps.openDeleteLabelDialog
      },
    },
  ];


  return (
    shipping?.length === 0
      ? <BlankMessage
        icon={<Icon.Supplier />}
        title={<FormattedMessage id='DOCUMENT.SHIPPING.BLANK_MESSAGE_TITLE' />}
        message={<FormattedMessage id='DOCUMENT.SHIPPING.BLANK_MESSAGE' />}
        arrow
      />
      : <>
        <BootstrapTable
          keyField="id"
          data={shipping?.filter(item => !item.error) || []}
          columns={COLUMNS}
          wrapperClasses="table-responsive"
          headerClasses="bg-info-o-10"
          classes="table table-head-custom table-vertical-center mb-0"
          bootstrap4
          bordered={false}
          deleteRow={true}
          condensed
          defaultSorted={[{ dataField: 'id', order: 'desc' }]}
          cellEdit={cellEditFactory({
            mode: 'dbclick',
            blurToSave: true,
            afterSaveCell: tableRowHandler,
          })}
        />

        {shipping?.filter(item => item.error)?.map((item, i) => (
          <Alert key={i} variant='light-danger' className='alert-custom alert-notice flex-column mx-10 mt-10' onClose={() => UIProps.openDeleteLabelDialog({ documentId, ...item })} dismissible>
            <FormattedMessage id='DOCUMENT.SHIPPING.CREATE_LABEL_ERROR' />
            <span className='font-weight-bold mt-2'>
              <span className='text-danger mr-1'>{UIHelpers.getShipperName(item?.service)}- <FormattedMessage id={item.type === 'return' ? 'DOCUMENT.SHIPPING.TYPE_RETURN' : 'DOCUMENT.SHIPPING.TYPE_SHIPMENT'} />:</span>
              {item.error}
            </span>
          </Alert>
        ))}
      </>
  )
}

