import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Card, CardHeader } from "_metronic/_partials/controls";
import { UserGuideHelper } from "../../../user-guides/UserGuideHelper";
import { LoadingDialog } from "_metronic/_partials/loading";
import { CardBody } from "_metronic/_partials/controls";
import { importExportActions } from "../_redux/actions";
import ExportTable from "./export-table/ExportTable";
import { FormattedMessage } from "react-intl";
import { useSearchQuery } from "app/hooks";
import ExportSelect from "./ExportSelect";
import { Icon } from '_metronic/_icons';



export default function ExportPage() {

  // query params
  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'created_at DESC',
    search: '',
    filter: {
      data_type: 'all_types',
    },
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), [])

  const { setFilterQuery, setPaginationQuery, setSearchQuery, setOrderQuery } = useSearchQuery(setQueryParams);
  const dispatch = useDispatch();

  const { loading, transportData, count, totalCount } = useSelector(state => ({
    loading: state.importExport.loading,
    transportData: state.importExport.transportData,
    count: state.importExport.count,
    totalCount: state.importExport.totalCount,
  }), shallowEqual);


  useEffect(() => {
    dispatch(importExportActions.getImportExports("export", queryParams));
  }, [dispatch, queryParams]);


  return (
    <Card className="card-box">

      <CardHeader
        icon={<Icon.CloudDownload />}
        title={<FormattedMessage id="IMPORT_EXPORT.EXPORT" />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_accounting_export'
        sticky
      />

      <CardBody className="d-flex flex-column">

        <div className="d-flex flex-row">
          <div className="flex-row-fluid">
            <ExportTable
              exportData={transportData}
              loading={loading}
              count={count}
              totalCount={totalCount}
              UIProps={{ queryParams, setFilterQuery, setSearchQuery, setOrderQuery, setPaginationQuery }} />
          </div>

          <div className="flex-column offcanvas-mobile w-250px ml-lg-8">
            <ExportSelect loading={loading} queryParams={queryParams} />
          </div>

        </div>

      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  )
}
