
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { sortCaret, headerSortingClasses, NoRecordsFoundMessage, UIDates } from '_metronic/_helpers';
import { allQueryKeys, EMAIL_TEMPLATE_TYPE } from '../../_context/ReportUIHelpers';
import { ShippingsActions } from 'app/modules/shippings/_redux/actions';
import { ActionFormatter } from './column-formatters/ColumnFormatters';
import { DocumentActions } from 'app/modules/documents/_redux/actions';
import { RemotePagination } from '_metronic/_partials/controls';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';



export function EmailLogsTable({ entities, loading, count, UIProps }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const openSendEmailsDialog = (row) => {
    const { data: { reminder, document_id, document_type, type, shipper_id } } = row;
    UIProps.setReminder({ reminder, type, document_type, document_id, shipper_id })
    UIProps.handleOpenSendEmailDialog()

  };

  const openPrintDialog = (row) => {
    const { data: { reminder, document_id, document_type, type, shipper_id, shipper }, ...rest } = row;
    if (shipper_id) {
      dispatch(ShippingsActions.getShipping([shipper_id]))
      UIProps.openPrintLabelsDialog(shipper);
    } else {
      const data = {
        ids: [document_id],
        type: document_type,
        ...(reminder?.id && {
          reminder: {
            id: reminder?.id,
            type: type,
          }
        }),
        ...rest,
      };

      dispatch(DocumentActions.printDocument(data, UIProps.queryParams));
      UIProps.openPrintDocumentDialog();
    }
  }

  const COLUMNS = [
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap pl-5',
      classes: 'font-weight-bold p-2',
      headerStyle: { minWidth: '100px' },
      style: { height: '50px' },
      formatter: (cell, row) =>
        <div className="d-flex align-items-center">
          <div className="symbol symbol-40 symbol-light mr-3">
            <div className="symbol-label text-dark-25">
              <span className="svg-icon svg-icon-lg">
                {EMAIL_TEMPLATE_TYPE?.find(item => item.type === row.type)?.icon}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-start flex-column">
            <div className="text-dark-75 text-hover-primary font-weight-bolder">
              {row?.name}
            </div>
            <a href={`mailto:${row?.email}`} className="text-decoration-none font-size-sm text-dark-50 text-hover-primary">
              {row?.email}
            </a>
          </div>
        </div>
    },
    {
      dataField: 'type',
      text: intl({ id: 'REPORT.EMAIL_LOG.TYPE' }),
      type: 'string',
      // sort: true, // db de ingilizce olduğu için sıralama almancada sikinti oluyor
      // sortCaret,
      // onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      formatter: (cell, row) => (
        <div className="text-dark-75 text-hover-primary">
          <span className='font-weight-bold'> {EMAIL_TEMPLATE_TYPE?.find(item => item.type === row.type)?.label}</span>
        </div>
      ),
    },
    {
      dataField: 'document_id',
      text: intl({ id: 'GENERAL.ID' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center pl-4',
      classes: 'font-weight-bold text-center',
      formatter: (cell, row) => (cell || row?.sku
        ? <OverlayTrigger overlay={<Tooltip id="market-tooltip" className='font-weight-bold'>
          {row?.sku ? <FormattedMessage id='GENERAL.SKU' /> : EMAIL_TEMPLATE_TYPE?.find(item => item.type === row.type)?.label}
        </Tooltip>}>
          <Link className="font-weight-bold text-dark-75 text-hover-primary"
            to={{
              pathname: EMAIL_TEMPLATE_TYPE?.find(item => item.type === (row.sku ? row.type : row.document_type))?.to,
              search: row.sku
                ? `page=1&search=${row.sku}${['digital_product_key', 'digital_product_confirm'].includes(EMAIL_TEMPLATE_TYPE?.find(item => item.type === row.type)?.type) ? '' : ''}`
                : `page=1&search=${cell}${EMAIL_TEMPLATE_TYPE?.find(item => item.type === row.document_type)?.type === "orders" ? '&filter.status=all-orders' : ''}`,
              hash: '',
            }}
            id={`btn_${EMAIL_TEMPLATE_TYPE?.find(item => item.type === row.type)?.type}`}
          >
            {cell ? cell : row?.sku ? row?.sku : null}
          </Link>
        </OverlayTrigger>
        : ''
      ),
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.CREATE_DATE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'text-center',
      headerClasses: 'text-center text-nowrap pl-4',
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) => (
        <div className='font-size-sm text-dark-50 font-weight-bold'>
          {UIDates.formatDateTime(cell)}
        </div>
      ),
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      headerStyle: { minWidth: '100px', width: "5%" },
      headerClasses: 'text-center',
      classes: 'text-center',
      editable: false,
      formatter: ActionFormatter,
      formatExtraData: {
        openSendEmailsDialog,
        openPrintDialog,
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        data={entities || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center table-sm overflow-hidden"
        bootstrap4
        bordered={false}
        condensed
        striped
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { UIProps.setFilterQuery('DELETE_PARAMS', allQueryKeys); UIProps.changeDateRange() }} />}
      />
      {entities.length ? <RemotePagination
        queryParams={UIProps.queryParams?.pagination}
        setQueryParams={UIProps.setPaginationQuery}
        totalCount={count}
        entitiesCount={entities?.length}
        loading={loading}
      /> : null}
    </>
  );
}
