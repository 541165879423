export const defaultSorted = [{ dataField: 'created_at', order: 'desc' }];



export const INITIAL_SUPPLIER = {
  name: '',
  company: '',
  email: '',
  fax: '',
  mobile: '',
  phone: '',
  tax_number: '',
  vat_id: '',
  bank: [],
  address: [],
};
