import React, { useMemo, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDigitalProductsUIContext } from '../../_context/DigitalProductsUIContext';
import { useSearchQueryContext } from '../../_context/SearchQueryProvider';
import { digitalProductsActions } from '../../_redux/actions';
import { UIDates } from '_metronic/_helpers';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';



export function SendEmailDialog() {

  const dispatch = useDispatch();

  const queryContext = useSearchQueryContext();
  const UIContext = useDigitalProductsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showSendEmailInvoice,
    onHide: UIContext.closeSendEmailInvoiceDialog,
    documentId: UIContext.documentId,
    queryParams: queryContext.queryParams,
  }), [UIContext, queryContext]);


  const { invoicesEbay, printLoading, error, emailSuccess } = useSelector(state => ({
    invoicesEbay: state.digitalProduct.invoicesEbay,
    printLoading: state.digitalProduct.printLoading,
    emailSuccess: state.digitalProduct.emailSuccess,
    error: state.digitalProduct.error,
  }),
    shallowEqual
  );

  const findInvoice = invoicesEbay?.find(invoice => invoice?.ebay_invoice_id === UIProps?.documentId);

  const sendEmailInvoice = () => {
    if (UIProps?.documentId) {
      const data = { ebay_invoice_id: UIProps?.documentId, ebay_invoice_action: 'email' }
      dispatch(digitalProductsActions.printDocument(data, UIProps?.queryParams));
    }
  };

  useEffect(() => {
    if (emailSuccess) {
      setTimeout(() => {
        handleOnHide();
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSuccess]);


  const handleOnHide = () => {
    UIProps.onHide();
    dispatch(digitalProductsActions.clearLoadingEffects());
  }


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={handleOnHide} aria-labelledby="example-modal-sizes-title-standard" centered>

        <Modal.Body className='p-12'>

          <div className="d-flex flex-column align-items-center">
            {emailSuccess
              ? <Icon.CheckCircle className="text-success icon-6x my-5" />
              : <Icon.EnvelopeAt className="text-dark-75 icon-5x my-6" />
            }
          </div>

          <h5 className="text-center font-weight-bold">
            {emailSuccess
              ? <FormattedMessage id='DOCUMENT.REDUX.SEND_SELECTED_DOCUMENT_WITH_EMAIL.SUCCESS' />
              : error
                ? <span className="text-danger">
                  {error?.message ? error.message : <FormattedMessage id='GENERAL.ERROR_MESSAGE' />}
                </span>
                :
                <span className='line-height-md'>
                  {findInvoice?.email_sended_date
                    ? <FormattedMessage id="DOCUMENT.EMAIL_SEND.CONTENT_WARNING" values={{ date: UIDates.formatDate(findInvoice.email_sended_date) }} />
                    : <FormattedMessage id="DOCUMENT.EMAIL_SEND.CONTENT" />}
                </span>
            }
          </h5>

        </Modal.Body>

        <Modal.Footer>

          <Button variant='light' onClick={handleOnHide} disabled={printLoading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>

          {!emailSuccess &&
            <Button variant='primary' className='ml-2' onClick={sendEmailInvoice} disabled={printLoading} id="btn_send_email">
              <FormattedMessage id={findInvoice?.email_sended_date ? "GENERAL.RESEND_EMAIL" : "GENERAL.SEND_EMAIL"} />
            </Button>}

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}