import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { getHandlerTableChange, sortCaret, headerSortingClasses, NoRecordsFoundMessage, getSelectRow, UIDates, UTILS, getCountryList } from '_metronic/_helpers';
import { useSupplierUIContext } from '../_context/SupplierUIContext';
import { ActionsColumnFormatter } from './ActionsColumnFormatter';
import { RemotePagination } from '_metronic/_partials/controls';
import * as HELPERS from '../_context/SuppliersUIHelpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';
import { useIntl } from 'react-intl';



export function SuppliersTable({ entities, countSupplier, loading, pagination, setPaginationQuery, setOrderQuery, setSearchQuery }) {

  const locale = useLang();
  const { formatMessage: intl } = useIntl();

  // Suppliers UI Context
  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
  }), [UIContext]);


  const COLUMNS = [
    {
      dataField: 'company',
      text: intl({ id: 'GENERAL.COMPANY' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap pl-4',
      classes: 'font-weight-bold p-2',
      headerStyle: { minWidth: "140px", width: "25%" },
      formatter: (cell, row) => (
        <div className="d-flex align-items-start text-left">

          <div className="symbol symbol-40 symbol-light mr-3">
            <span className="symbol-label font-size-h5 text-dark-25 text-uppercase">
              {UTILS.getFirstLetter(row?.name)}
            </span>
          </div>

          <div>
            <Link to={ModuleRoutes.EDIT_SUPPLIER_FN(row.id)} type="button" className=" text-decoration-none text-dark text-hover-primary text-nowrap preview" id='btn_supplier_edit'>
              <div className='d-flex align-items-center'>
                <span className='font-weight-bolder text-dark-75 mr-2'>
                  {row?.company}
                </span>
                <span className='d-flex text-dark-25 svg-icon svg-icon-sm invisible pl-2'>
                  <Icon.Pencil />
                </span>
              </div>
            </Link>

            <div className="font-size-sm text-dark-50">
              {row?.number}
            </div>

          </div>

        </div>
      ),
    },
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold',
      headerStyle: { minWidth: "140px", width: "20%" },
    },
    {
      dataField: 'email',
      text: intl({ id: 'GENERAL.EMAIL' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bold',
      headerStyle: { width: "15%" },
      formatter: (cell, row) => (<a href={`mailto:${cell}`} className="text-decoration-none text-dark text-hover-primary">{cell}</a>)
    },
    {
      dataField: 'phone',
      text: intl({ id: 'GENERAL.PHONE' }),
      headerStyle: { width: "15%" },
      formatter: (cell, row) => (
        <div className='font-weight-bold'>
          <a href={`tel:${cell}`} className="d-block text-decoration-none text-dark-75 text-hover-primary mb-1">{cell}</a>
          <a href={`tel:${row.mobile}`} className="d-block text-decoration-none text-dark-75 text-hover-primary">{row.mobile}</a>
        </div>
      )
    },
    {
      dataField: 'address[0].country_code',
      text: intl({ id: 'GENERAL.ADDRESS' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      headerStyle: { width: "10%" },
      formatter: (cell, row) => {
        const address = row.address.find((item) => item.type === "address_delivery") ?? row.address[0];
        return (
          <OverlayTrigger overlay={<Tooltip id="address-tooltip">
            <div className='font-weight-bold text-dark-75 text-left'>
              <div className='font-weight-bolder'>{address?.name}</div>
              <div className='font-weight-bolder text-dark-50'>{address?.company}</div>
              <hr className='my-1' />
              <div>{address?.address_line1} {address?.address_line2}</div>
              <div>{address?.post_code} {address?.city}</div>
              <div>{getCountryList(locale).find(code => code.value === address?.country_code)?.label}</div>
            </div>
          </Tooltip>}>
            <div className='d-flex flex-column font-weight-bold mr-1'>
              <span className='text-nowrap'>
                {address?.city}
              </span>
              <span className='text-dark-50'>
                {address?.country_code + '-' + address?.post_code}
              </span>
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.REGISTRATION_DATE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap pl-5',
      headerStyle: { minWidth: '100px', width: '8%' },
      classes: 'text-center',
      formatter: (cell, row) => (
        <div className='font-size-sm text-dark-50 font-weight-bold mb-1'>
          {UIDates.formatDateTime(cell)}
        </div>
      )
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      headerStyle: { minWidth: '100px', width: '5%' },
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditSupplierDialog: (id) => ModuleRoutes.EDIT_SUPPLIER_FN(id),
        openDeleteSupplierDialog: (id) => ModuleRoutes.DELETE_SUPPLIER_FN(id),
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={entities || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        striped
        bordered={false}
        condensed
        defaultSorted={HELPERS.defaultSorted}
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => setSearchQuery('')} />}
        onTableChange={getHandlerTableChange(UIProps.setQueryParams)}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: entities,
        })}
      />
      <RemotePagination
        queryParams={pagination}
        setQueryParams={setPaginationQuery}
        totalCount={countSupplier}
        entitiesCount={entities?.length}
        loading={loading}
      />
    </>
  );
}
