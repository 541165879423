import React from 'react'
import { UIDates } from '_metronic/_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



export const LastLoginFn = (cell) => {
  if (cell) {
    return (
      <OverlayTrigger overlay={<Tooltip id="last-login-tooltip" className='font-weight-bold'>{UIDates.formatDateTime(cell)}</Tooltip>}>
        <span type="button" className={`label label-inline label-lg label-rounded font-weight-bold ${UIDates.isOrderOlderThan(cell, 30) ? 'label-outline-danger' : 'label-outline-success'}`}>
          {UIDates.formNow(cell)}
        </span>
      </OverlayTrigger>
    )
  }
  return <></>
}
