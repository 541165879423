/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getConnection } from "constants/connectionSettings";
import { FormattedMessage } from "react-intl";
import { Icon } from '_metronic/_icons';



export const ActionsColumnFormatter = (cellContent, row, rowIndex, { openEditCustomerDialog, openDeleteCustomerDialog, openSetDocumentDialog }) => (
  <>
    <OverlayTrigger overlay={<Tooltip id="customers-document-tooltip" className='font-weight-bold'><FormattedMessage id="MENU.ASIDE.DOCUMENTS" /></Tooltip>}>
      <Link className="btn btn-icon btn-hover-info btn-sm" to={openSetDocumentDialog(row.number)} id={`btn_preview_${row.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.FileText />
        </span>
      </Link>
    </OverlayTrigger>

    <OverlayTrigger overlay={<Tooltip id="customers-edit-tooltip" className='font-weight-bold'><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
      <Link className="btn btn-icon btn-hover-primary btn-sm" to={openEditCustomerDialog(row.id)} id={`btn_edit_${row.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.PencilSquare />
        </span>
      </Link>
    </OverlayTrigger>

    <OverlayTrigger overlay={<Tooltip id="customers-delete-tooltip" className='font-weight-bold'><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
      <Link className="btn btn-icon btn-hover-danger btn-sm" to={openDeleteCustomerDialog(row.id)} id={`btn_delete_${row.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.Trash />
        </span>
      </Link>
    </OverlayTrigger>
  </>
);


export function SourceColumnFormatter(cell, row, rowIndex) {
  // FIXME: Eski veriler için düzeltme eklendi. exm: undefined, "", [], [""], "shopify", ["shopify"], ["shopify", "shopify"]
  cell = cell ? cell : ["salevali"];
  if (!Array.isArray(cell)) {
    cell = [cell];
  }

  cell = [...new Set(cell)].filter(item => item.length > 1); //FIXME:  db ['s','h' 'o', 'p', 'i','f','y' ] tarzında kaydolmuş veriler migrate edilene kadar filter eklendi
  cell = cell.length > 0 ? cell : ["salevali"];

  return (!cell?.length > 0 ? null :
    <div className="symbol-group justify-content-center">
      {cell?.map(item => {
        const market = getConnection(item) ?? { label: item, icon: <Icon.ShopWindow className="text-dark-50" /> };
        return (
          <OverlayTrigger key={item} overlay={<Tooltip id="marketplace-tooltip" className='font-weight-bold'>{market.label}</Tooltip>}>
            <span className="symbol d-flex mr-4 bg-white svg-icon svg-icon-lg">
              {market?.icon}
            </span>
          </OverlayTrigger>
        )
      })}
    </div>
  );
}