import React from 'react'
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { LogsFilter } from './LogsFilter';
import { LogsTable } from './LogsTable';
import { Icon } from '_metronic/_icons';



export function UserLogCard({ queryParams, setSearchQuery, setFilterQuery, setPaginationQuery, log_entries, total_log_entries, page, pageSize, loading }) {
  return (
    <Card className="card-stretch">

      <CardSubHeader icon={<Icon.FileEarmarkText />} title={<FormattedMessage id="GENERAL.LOGS" />}>

      </CardSubHeader>

      <CardBody>
        <LogsFilter queryParams={queryParams} setSearchQuery={setSearchQuery} setFilterQuery={setFilterQuery} />
        <LogsTable
          queryParams={queryParams}
          setPaginationQuery={setPaginationQuery}
          log_entries={log_entries || []}
          total_log_entries={total_log_entries}
          page={page}
          pageSize={pageSize}
        />
      </CardBody>

    </Card>
  );
}