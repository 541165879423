/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import moment from 'moment';



export function ActionFormatter(cellContent, row, rowIndex, { openPrintDocumentDialog, mailInvoiceHandler }) {

  const isCurrentMonth = moment(row.date_from).isSame(moment(), 'month')

  return (
    <>
      {(row.ebay_invoice_id && !row.preparation)
        ? <>
          <OverlayTrigger overlay={<Tooltip id="invoice-email-tooltip"><FormattedMessage id="GENERAL.RESEND_EMAIL" /></Tooltip>}>
            <a className="btn btn-icon btn-hover-success btn-sm" onClick={() => mailInvoiceHandler(row?.ebay_invoice_id)} id={`btn_email_${row.ebay_invoice_id}`}>
              <span className="svg-icon svg-icon-md">
                <Icon.EnvelopeAt />
              </span>
            </a>
          </OverlayTrigger>

          <OverlayTrigger overlay={<Tooltip id="shipping-print-tooltip"><FormattedMessage id="GENERAL.PRINT" /></Tooltip>}>
            <a className="btn btn-icon btn-hover-info btn-sm" onClick={() => openPrintDocumentDialog(row, "print")} id={`btn_print_${row.id}`}>
              <span className="svg-icon svg-icon-md">
                <Icon.Printer />
              </span>
            </a>
          </OverlayTrigger>
        </>
        : <>
          <OverlayTrigger overlay={<Tooltip id="shipping-print-tooltip"><FormattedMessage id="DOCUMENT.CONVERT.CREATE_INVOICE" /></Tooltip>}>
            <a className="btn btn-icon btn-hover-primary btn-sm" onClick={() => openPrintDocumentDialog(row, "preparation")} id={`btn_print_${row.id}`} style={isCurrentMonth ? { pointerEvents: "none", opacity: 0.5 } : null} >
              <span className="svg-icon svg-icon-md">
                <Icon.CheckSquare />
              </span>
            </a>
          </OverlayTrigger>

          <OverlayTrigger overlay={<Tooltip id="shipping-print-tooltip"><FormattedMessage id="GENERAL.PRINT_PREVIEW" /></Tooltip>}>
            <a className="btn btn-icon btn-hover-info btn-sm" onClick={() => openPrintDocumentDialog(row, "preview")} id={`btn_print_${row.id}`}>
              <span className="svg-icon svg-icon-md">
                <Icon.Eye />
              </span>
            </a>
          </OverlayTrigger>
        </>
      }
    </>
  )
}