import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cellEditFactory from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import { ActionsColumnFormatter } from './column-formatters/ActionsColumnFormatter';
import { useProductEditUIContext } from '../../../_context/ProductEditUIContext';
import * as columnFormatters from '../../../product-table/column-formatters';
import { BlankMessage } from '_metronic/_partials/components';
import { ProductActions } from '../../../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { VARIANT_ACTION_TYPES } from '../core/helpers';
import { useSkuValidationControl } from 'app/hooks';
import { QuantityStyle } from '_metronic/_helpers';
import { useFormikContext } from 'formik';
import { useWindowSize } from 'app/hooks';
import { Icon } from '_metronic/_icons';
import { validator } from './validator';
import { clone, isArray } from 'lodash';



export function VariantTable({ loading }) {

  const { productId } = useParams();
  const { windowSize } = useWindowSize();
  const { formatMessage: intl } = useIntl();
  const { checkIsSkuValidForVariants } = useSkuValidationControl();

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    dispatchNewVariants: UIContext.dispatchNewVariants,
  }),
    [UIContext.dispatchNewVariants]
  );

  const { values: { pricing, unit } } = useFormikContext();
  const dispatch = useDispatch();

  const { productVariants, products } = useSelector(state => ({
    productVariants: state.products.variants,
    products: state.products.entities,
  }), shallowEqual);

  // currency değişince table render olması için
  const [forceRerender, setForceRerender] = useState(false);
  useEffect(() => {
    setForceRerender(prev => !prev);
  }, [pricing]);

  const handleAfterSaveVariantCell = (oldValue, newValue, row, column) => {

    if (oldValue === newValue) { return; }

    if (column.dataField === 'pricing.purchase_price') {
      row.pricing.purchase_price = +row.pricing.purchase_price.replace(',', '.');
    }

    if (column.dataField === 'pricing.gross_price') {
      row.pricing.gross_price = +row.pricing.gross_price.replace(',', '.');
      newValue = +newValue.replace(',', '.')
    }

    if (column.dataField === 'quantity') {
      row.quantity = +row.quantity.replace(',', '.');
    }

    if (column.dataField === 'pricing.gross_price') {
      const tax = +pricing?.tax || 19;
      const netPrice = +newValue / (1 + tax / 100);
      row.pricing = {
        ...row.pricing,
        gross_price: +newValue,
        net_price: +netPrice,
      };
    }
    // If sku is changed, we need to check if it is already exist in database
    if (column.dataField === 'sku') {
      dispatch(ProductActions.findProductBySku([row.sku], productId));
    }


    const variants = clone(productVariants);
    const variantIndex = variants.findIndex(v => v.id === row.id);
    const newVariants = variants.map((v, index) => {
      if (index === variantIndex) {
        return {
          ...v,
          ...row,
        };
      }
      return v;
    });
    dispatch(ProductActions.setVariants(newVariants));
  };

  const deleteVariantHandler = rowId => {
    const variants = clone(productVariants);
    const variantIndex = variants.findIndex(v => v.id === rowId);
    const newVariants = variants.filter((v, index) => index !== variantIndex);
    dispatch(ProductActions.setVariants(newVariants));
  };

  // Check if sku is duplicated in rows
  const isSkuDuplicateInRows = rowSku => {
    const isDuplicate = productVariants.filter(variant => variant.sku === rowSku);
    return isDuplicate.length > 1 ? true : false;
  };

  // check if specs is duplicated in rows
  /*   const isSpecsDuplicateInRows = rowSpecs => {
      const isDuplicate = productVariants.filter(variant => variant.specs.map(s => s.value).join('') === rowSpecs);
      return isDuplicate.length > 1 ? true : false;
    }; */


  const openEditVariantDialog = rowId => {
    UIProps.dispatchNewVariants({
      type: VARIANT_ACTION_TYPES.OPEN_EDIT_VARIANT_DIALOG,
      payload: { variantId: rowId },
    });
  };

  const filterProduct = products.filter(product => product.id !== productId);

  const editorStyle = () => ({ height: '32px', padding: "0.5rem 0.75rem" });

  const COLUMNS = [
    {
      dataField: '#',
      text: intl({ id: 'GENERAL.NO' }),
      isDummyField: true,
      headerStyle: { minWidth: '50px', width: '5%' },
      headerClasses: 'text-center pl-7',
      classes: 'font-weight-bold text-center pl-7',
      editable: false,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: 'specs',
      text: intl({ id: 'PRODUCT.VARIANTS' }),
      headerClasses: 'text-left pl-3',
      headerStyle: { minWidth: '120px', width: '15%' },
      editable: false,
      formatter: (cell, row) => {
        if (isArray(cell)) {
          return cell.map((spec, i) => (
            <label className="label label-inline label-light-primary font-weight-bold text-nowrap m-1" key={i}>
              {spec.value}
            </label>
          ))
        }
      }
    },
    {
      dataField: 'sku',
      text: intl({ id: 'GENERAL.SKU' }),
      headerStyle: { minWidth: '70px', width: '10%' },
      headerClasses: 'text-left text-nowrap pl-4',
      classes: 'font-weight-bold',
      editable: true,
      editorStyle,
      validator: (cell, row, column) => validator.isValidSku(cell, row, filterProduct, productVariants, intl),
      formatter: (cell, row) => <div type="button" className="edit-table">{cell}</div>,
      title: (cell, row, rowIndex, colIndex) => {
        if (checkIsSkuValidForVariants(row?.sku) || isSkuDuplicateInRows(row?.sku)) {
          return `sku must be unique`;
        }
      },
      style: (cell, row, rowIndex, colIndex) => {
        if (checkIsSkuValidForVariants(row?.sku) || isSkuDuplicateInRows(row?.sku)) {
          return { color: 'red', minWidth: '120px', width: '15%' };
        }
        return { minWidth: '120px', };
      },
    },
    {
      dataField: 'ean',
      text: intl({ id: 'PRODUCT.EAN' }),
      headerStyle: { width: '7%' },
      headerClasses: 'text-center',
      classes: 'text-center',
      editable: true,
      editorStyle,
      validator: (cell, row, column) => validator.isValidEan(cell, intl),
      formatter: (cell, row) => <div type="button" className="edit-table">{cell}</div>
    },
    {
      dataField: 'asin',
      text: intl({ id: 'PRODUCT.ASIN' }),
      headerStyle: { width: '7%' },
      headerClasses: 'text-center',
      classes: 'text-center',
      editable: true,
      editorStyle,
      validator: (cell, row, column) => validator.isValidAsin(cell, intl),
      formatter: (cell, row) => <div type="button" className="edit-table">{cell}</div>
    },
    {
      dataField: 'quantity',
      text: intl({ id: 'PRODUCT.GENERAL.QUANTITY' }),
      headerStyle: { minWidth: '60px', width: '6%' },
      headerClasses: 'text-center',
      classes: 'text-center',
      editable: true,
      editorStyle: () => ({ height: '32px', textAlign: 'center', padding: "0.5rem 0.75rem" }),
      validator: (cell, row, column) => validator.isValidQuantity(cell, unit, intl),
      formatter: (cell, row) => QuantityStyle(cell)
    },
    {
      dataField: 'pricing.purchase_price',
      text: intl({ id: 'PRODUCT.TABLE.EK' }),
      headerStyle: { minWidth: '100px', width: '8%' },
      headerClasses: 'text-right pr-4',
      classes: 'font-weight-bolder text-right',
      editable: true,
      editorStyle: () => ({ height: '32px', textAlign: 'right', padding: "0.5rem 0.75rem" }),
      validator: (cell, row, column) => validator.isValidPrice(cell, intl),
      formatter: (cell, row) => columnFormatters.Ek1PriceVariantFormatter(cell, row, pricing),
    },
    {
      dataField: 'pricing.net_price',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_NET' }),
      type: 'number',
      headerStyle: { minWidth: '100px', width: '10%' },
      headerClasses: 'text-right',
      classes: 'font-weight-bolder text-right',
      editable: false,
      validator: (cell, row, column) => validator.isValidPrice(cell, intl),
      formatter: (cell, row) => columnFormatters.Vk1PriceNetVariantFormatter(cell, row, pricing),
    },
    {
      dataField: 'pricing.gross_price',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_GROSS' }),
      headerStyle: { minWidth: '100px', width: '10%' },
      headerClasses: 'text-right pr-4',
      classes: 'font-weight-bolder text-right',
      editorStyle: () => ({ height: '32px', textAlign: 'right', padding: "0.5rem 0.75rem" }),
      validator: (cell, row, column) => validator.isValidPrice(cell, intl),
      formatter: (cell, row) => columnFormatters.Vk1PriceBruttoVariantFormatter(cell, row, pricing),
    },
    {
      dataField: 'action',
      text: "...",
      headerStyle: { minWidth: '80px', width: '5%' },
      headerClasses: 'font-size-h3 text-center pr-9 pt-0',
      classes: 'text-right text-nowrap pr-9',
      editable: false,
      editorStyle,
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        onDeleteVariant: deleteVariantHandler,
        openEditVariantDialog: openEditVariantDialog,
        loading: loading,
      },
    },
  ];


  return (
    productVariants.length === 0
      ? <BlankMessage
        icon={<Icon.Diagram3 />}
        title={<FormattedMessage id='PRODUCT.VARIANT.BLANK_MESSAGE_TITLE' />}
        message={<FormattedMessage id='PRODUCT.VARIANT.BLANK_MESSAGE' />}
        arrow
      />
      : <BootstrapTable
        keyField="id"
        key={forceRerender}
        data={productVariants || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        headerClasses="bg-info-o-10 text-center"
        classes="table table-head-custom table-vertical-center"
        bootstrap4
        bordered={false}
        condensed
        deleteRow={true}
        cellEdit={cellEditFactory({
          mode: windowSize < 576 ? 'click' : 'dbclick',
          blurToSave: true,
          afterSaveCell: handleAfterSaveVariantCell,
        })}
      />
  );
}
