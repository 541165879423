import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Icon } from '_metronic/_icons';



export function ProductKeysGrouping({ UIProps }) {
  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="send_email-tooltip">{<FormattedMessage id="DIGITAL_PRODUCT.SEND_EMAIL" />}</Tooltip>}>
        <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold text-nowrap ml-auto" onClick={() => UIProps.openSendMailKeyDialog(UIProps.ids)} disabled={UIProps.ids.length <= 0} id="btn_multiple_email">
          <Icon.EnvelopeAt />
          <span className="d-none d-sm-inline ml-2">
            <FormattedMessage id="GENERAL.SEND_EMAIL" />
          </span>
        </Button>
      </OverlayTrigger>

      {/*<Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-2 mb-2" onClick={() => UIProps.openReserveDialog(UIProps.ids)} disabled={UIProps.ids.length <= 0} id="btn_multiple_delete">
        <Icon.Reply />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="PRODUCT.KEYS.RESERVE_DELETE" />
        </span>
      </Button> */}
    </>
  );
}