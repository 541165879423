import { useEffect } from 'react';
import { UTILS } from '_metronic/_helpers';



export function usePreventPrintKeyHandler() {
  useEffect(() => {
    document.addEventListener(
      'keydown',
      UTILS.keyboardEventHandlers.preventPrintMethod,
      false
    );
    return () =>
      document.removeEventListener(
        'keydown',
        UTILS.keyboardEventHandlers.preventPrintMethod,
        false
      );
  }, []);
}
