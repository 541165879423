import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { Dropdown } from "react-bootstrap";
import { theme } from "_metronic/_helpers";
import { Icon } from '_metronic/_icons';
import clsx from "clsx";



export default function MultipleFilter({ icon, title, items, itemName, noItemMessage, selectedFilters, onFilterChange }) {

  const { formatMessage: intl } = useIntl();

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    // Parent component'ten gelen selectedFilters ile local state'i eşitle
    selectedFilters && setSelectedItems(Object.keys(selectedFilters).filter(key => selectedFilters?.[key]));
  }, [selectedFilters]);

  const handleItemToggle = (item) => {
    const updatedItems = selectedItems.includes(item)
      ? selectedItems.filter((m) => m !== item)
      : [...selectedItems, item];

    setSelectedItems(updatedItems);

    const updatedFilters = items.reduce((acc, curr) => {
      acc[curr.name] = updatedItems.includes(curr.name);
      return acc;
    }, {});
    onFilterChange(updatedFilters);
  };

  const clearFilters = () => {
    setSelectedItems([]);
    onFilterChange(
      items.reduce((acc, curr) => {
        acc[curr.name] = false;
        return acc;
      }, {})
    );
  };


  return (
    <Dropdown className="dropdown-inline symbol mr-2" drop="down">

      <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center font-weight-bold svg-icon svg-icon-sm" style={{ height: theme.units.input.height['sm'] }} id="dropdown-item-filter">

        {selectedItems?.length === 0 && icon}

        <div className="symbol-group symbol-hover justify-content-center">
          {items?.length > 0 && items.filter(item => selectedItems.includes(item.name)).map((item, index) => (
            <div className={clsx('symbol d-flex bg-white', selectedItems.length - 1 !== index && "mr-3")} key={index}>
              {item?.icon}
            </div>
          ))}
        </div>

        <div className="d-none d-sm-inline ml-3">
          {selectedItems.length === 1
            ? items.find(item => item.name === selectedItems[0])?.label
            : selectedItems.length > 1
              ? <FormattedMessage id="GENERAL.FILTER.ITEMS_SELECTED" values={{ count: selectedItems.length, itemName: intl({ id: selectedItems.length > 1 ? "GENERAL.FILTER.MARKET_PLURAL" : "GENERAL.FILTER.MARKET_SINGULAR" }) }} />
              : title}
        </div>

      </Dropdown.Toggle>

      {selectedItems.length > 0 && <i className="symbol-badge bg-danger"></i>}

      <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" id="dropdown_item_options">
        <ul className="navi navi-hover">

          {items?.length > 0
            ? items.map((item, index) => (
              <li className="navi-item" key={index}>
                <div className="navi-link dropdown-item checkbox checkbox-primary" onClick={() => handleItemToggle(item.name)}>
                  <input type="checkbox" name={`checkbox_${item.name}`} onChange={() => handleItemToggle(item.name)} checked={selectedItems.includes(item.name)} id={`checkbox_${item.name}`} />
                  <span />
                  <div className="text-nowrap ml-1">
                    {item.icon && (
                      <span className="svg-icon svg-icon-md mr-2">
                        {item.icon}
                      </span>
                    )}
                    <span className="navi-text text-nowrap font-weight-bold">
                      {item.label}
                    </span>
                  </div>
                </div>
              </li>
            ))
            : <li className="navi-item px-3">
              <span className="svg-icon svg-icon-md mr-2">
                <Icon.InfoCircle />
              </span>
              <span className="navi-text">
                {noItemMessage}
              </span>
            </li>
          }

          <Dropdown.Divider />

          <li className="navi-item">
            <Dropdown.Item className="navi-link font-weight-bolder text-danger justify-content-center bg-white py-1" onClick={clearFilters} id="clear-item">
              <FormattedMessage id="GENERAL.CLEAR" />
            </Dropdown.Item>
          </li>

        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};


/*
Geliştirici Kılavuzu:

1. Projedeki ilgili dosyalar:

  - Parent Component (Usaully): src/app/modules/[module name]/[module name]Card.js
  - Filter Component: src/app/modules/[module name]/[module name]-filter/[module name]Filter.js
  - src/app/modules/[module name]/_context/[module name]QueryProvider.js
  - src/app/modules/[module name]/_contest/[module name]UIContext.js
  - src/app/modules/[module name]/redux/actions.js
  - src/app/modules/[module name]/redux/reduces.js
  - src/middlewares/[module name]/middleware.js

2. İlgili filter componentine aşağıdaki fonksiyonları ekleyerek ihtiyaca göre modifiye et:

  // Filter MultipleFilter Fonksiyonları
  const attribFilterContent = [
    {name: "api", label: "API", icon: "" },
    {name: "manuel", label: "Manuel", icon: "" },
    {name: "send", label: "Send", icon: "" },
    {name: "return", label: "Return", icon: "" },
  ] 

  const defaultAttribFilterValues = {
    api:  queryParams?.filter?.attrib?.api ? true : false,
    manuel:  queryParams?.filter?.attrib?.manuel ? true : false,
    send:  queryParams?.filter?.attrib?.send ? true : false,
    return:  queryParams?.filter?.attrib?.return ? true : false,
  }

  const [attribFilterValue, setAttribFilterValue] = useState(defaultAttribFilterValues);
  
  const handleAttribFilterChange = (updatedFilters) => {
    // MultipleFilter içinde seçili olan unsurları güncelle
    setAttribFilterValue(updatedFilters);
    // URL de seçili olan unsurları güncelle
    // Gönderdiğimiz filtreler attrib başlığı altında middleware a gitsin
    setFilterQuery(updatedFilters, "attrib");
  };

3. MultipleFilter JSX kodunu ilgili filter componentinin (madde 2 dekiyle aynı) JSX kısmına ekleyerek setleri ihtiyaca göre modifiye et:

  <MultipleFilter          
    icon={ <Icon.Sliders className="mr-3" /> }          
    title={ <FormattedMessage id='DOCUMENT.FILTER.CONTENT' /> }
    items={ attribFilterContent }
    itemName={ <FormattedMessage id="DOCUMENT.FILTER.CONTENT" /> }
    noItemMessage={ <FormattedMessage id="GENERAL.NO_OPTIONS_AVAILABLE" /> }
    selectedFilters={ attribFilterValue }
    onFilterChange={ handleAttribFilterChange }
  />

4. İlgili middleware dosyasında filtre unsurlarını gönderilen başlıkla ayıkla ve filtreyi uygula:

  *  Başlığı ayıkla:

    const {date_from, date_to, connections, attrib, ...rest} = filter;

  * Filtreyi uygula:

    ...((attrib && Object.keys(attrib).length > 0) && {
      ...(attrib.api && !attrib.manuel && { unlabeled: { exists: false } }),
      ...(attrib.manuel && !attrib.api && { unlabeled: true }),
      ...(attrib.send && !attrib.return && { type: 'shipment' }),
      ...(attrib.return && !attrib.send && { type: 'return' }),

  * Tam örnek:

  getShippings: async function (accessToken, { pagination, search, filter, order }) {
    const {date_from, date_to, connections, attrib, ...rest} = filter;
    return await API.get(`/shipping`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            tracking_id: { neq: "" },
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...((date_from && date_to) && {
              created_at: { between: [`${date_from}T00:00:00.001Z`, `${date_to}T23:59:59.999Z`] }
            }),
            ...((connections && Object.keys(connections).length > 0) && {
              service: { inq: Object.keys(connections) },
            }),
            ...((attrib && Object.keys(attrib).length > 0) && {
              ...(attrib.api && !attrib.manuel && { unlabeled: { exists: false } }),
              ...(attrib.manuel && !attrib.api && { unlabeled: true }),
              ...(attrib.send && !attrib.return && { type: 'shipment' }),
              ...(attrib.return && !attrib.send && { type: 'return' }),
            ...rest,
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

*/