import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormattedMessage, useIntl } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NoRecordsFoundMessage, UIDates, convertPriceCurrency, getSelectRow, headerSortingClasses, sortCaret } from '_metronic/_helpers';
import { getShipperName } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { useUserSettingsUIContext } from '../../_context/UserSettingsUIContext';
import { getConnection } from 'constants/connectionSettings';
import { DeleteConfirm } from '_metronic/_partials/dialogs';
import { SettingsActions } from '../../_redux/actions';
import { Icon } from '_metronic/_icons';



export function ShippingSettingsTable({ id, settings_type, searchedShipping, default_shipping, loading, setSearchQuery }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    openShippingDeleteDialog: UIContext.openShippingDeleteDialog,
    showDeleteShippingDialog: UIContext.showDeleteShippingDialog,
    closeDeleteShippingDialog: UIContext.closeDeleteShippingDialog,
    openShippingSettingDialog: UIContext.openShippingSettingDialog,
    shippingId: UIContext.shippingId,
    setIds: UIContext.setIds,
    ids: UIContext.ids,
  }), [UIContext]);

  const deleteShippingSettings = () => {
    let filteredShipping
    if (UIProps.ids.length > 0 && !UIProps.shippingId) {
      filteredShipping = default_shipping.filter(item => !UIProps.ids.includes(item.id));
    } else {
      filteredShipping = default_shipping.filter(item => item.id !== UIProps.shippingId);
    }

    if (id && settings_type === "shipping") {
      dispatch(SettingsActions.patchUserSettings(id, settings_type, { default_shipping: filteredShipping }))
    }

    UIProps.setIds([]);
    setSearchQuery('');
  }


  const COLUMNS = [
    {
      dataField: 'service',
      text: intl({ id: 'GENERAL.SHIPPING' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '150px', width: "30%" },
      headerClasses: 'text-left',
      classes: 'text-left font-weight-bold text-dark-75',
      formatter: (cell, row) => {
        const getService = getShipperName(row.service)
        const icon = getConnection(row?.service)?.icon
        return (
          <div type="button" className="d-flex align-items-center text-muted text-hover-primary preview" onClick={() => UIProps.openShippingSettingDialog(row?.id)}>
            <div className="d-flex label-outline-primary mr-4" style={{ fontSize: "2.5rem" }}>
              {(icon && !row?.unlabeled) ? icon : <Icon.SupplierFIll />}
            </div>
            <div>
              <div className="d-flex align-items-center text-nowrap">
                <span className='font-weight-bolder text-dark-75 mr-2'>
                  {getService}
                </span>
                <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip"><FormattedMessage id="SETTINGS.DOCUMENT.SHIPPING.DEFAULT" /></Tooltip>}>
                  <span className="d-flex svg-icon svg-icon-sm">
                    {row?.default ? <Icon.CheckCircleFill className="text-success mr-2" /> : null}
                  </span>
                </OverlayTrigger>
                <span className='d-flex invisible text-dark-25 svg-icon svg-icon-sm'>
                  <Icon.Pencil />
                </span>
              </div>
              <div className="font-weight-bold font-size-sm text-dark-50">
                {row.dp_product_name ? row.dp_product_name : <FormattedMessage id={row.unlabeled ? 'SETTINGS.DOCUMENT.SHIPPING.WITH_TRACKING_ID' : 'SETTINGS.DOCUMENT.SHIPPING.WITH_API'} />}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      dataField: 'profile_name',
      text: intl({ id: 'SETTINGS.DOCUMENT.SHIPPING.PROFILE_NAME' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '150px', width: "20%" },
      classes: 'font-weight-bold text-dark-75',
      formatter: (cell, row) => (
        <div className='d-flex align-items-center'>
          <OverlayTrigger overlay={<Tooltip id="type-tooltip"><FormattedMessage id={row.type === "return" ? 'DOCUMENT.SHIPPING.TYPE_RETURN' : 'DOCUMENT.SHIPPING.TYPE_SHIPMENT'} /></Tooltip>}>
            <div className="svg-icon svg-icon-lg mr-2">
              {row.type === "return" ? <Icon.Shipping className="text-danger" style={{ transform: "scaleX(-1)" }} /> : <Icon.Shipping />}
            </div>
          </OverlayTrigger>
          <span className="font-weight-bolder text-dark-75">{cell}</span>
        </div>
      )
    },
    {
      dataField: 'weight',
      text: intl({ id: 'GENERAL.WEIGHT' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { width: "5%" },
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center font-weight-bold font-size-lg text-dark-75',
      editorStyle: (row) => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
    },
    {
      dataField: 'price',
      text: intl({ id: 'DOCUMENT.SHIPPING_PRICE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '140px', width: "10%" },
      headerClasses: 'text-right text-nowrap',
      classes: 'text-right font-weight-bolder text-dark-75 pr-7',
      formatter: (cell, row) => (convertPriceCurrency(cell)),
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.DATE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center text-nowrap pr-5',
      formatter: (cell, row) => (
        <div className='font-size-sm text-dark-50 font-weight-bold'>
          {UIDates.formatDateTime(cell)}
        </div>
      ),
    },
    {
      dataField: 'action',
      text: "actions",
      headerStyle: { minWidth: '80px', width: "7%" },
      headerClasses: 'text-center',
      classes: 'text-center',
      editable: false,
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
              <div className="btn btn-icon btn-hover-primary btn-sm" onClick={() => UIProps.openShippingSettingDialog(row?.id)} id={`btn_edit_${row.id}`}>
                <span className="svg-icon svg-icon-md">
                  <Icon.PencilSquare />
                </span>
              </div>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip id="shipping-delete-tooltip"><FormattedMessage id="DOCUMENT.SHIPPING.DELETE" /></Tooltip>}>
              <div className="btn btn-icon btn-hover-danger btn-sm" onClick={() => UIProps.openShippingDeleteDialog(row?.id)} id={`btn_delete_${row.id}`}>
                <span className="svg-icon svg-icon-md">
                  <Icon.Trash />
                </span>
              </div>
            </OverlayTrigger>
          </>
        )
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        data={searchedShipping || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden mb-0"
        headerClasses="bg-info-o-10"
        bootstrap4
        bordered={false}
        deleteRow={true}
        condensed
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => setSearchQuery('')} />}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: searchedShipping,
        })}
      />

      <DeleteConfirm show={UIProps.showDeleteShippingDialog} onHide={UIProps.closeDeleteShippingDialog} toDelete={() => { deleteShippingSettings(); UIProps.closeDeleteShippingDialog() }} />
    </>
  )
}

