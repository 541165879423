import ActionTypes from "./actionTypes";



export class digitalProductsActions {

  // Get digital product action types
  static getDigitalProductKeys = (queryParams) => {
    return {
      type: ActionTypes.GET_DIGITAL_PRODUCT_KEYS,
      payload: {
        queryParams
      },
    };
  };

  static getDigitalProductKeysSuccess = (digitalProducts, count) => {
    return {
      type: ActionTypes.GET_DIGITAL_PRODUCT_KEYS_SUCCESS,
      payload: {
        digitalProducts,
        count
      },
    };
  };

  static getDigitalProductKeysFail = () => {
    return {
      type: ActionTypes.GET_DIGITAL_PRODUCT_KEYS_FAIL,
    };
  };

  
  // Get digital product action types
  static getDigitalProductInvoices = (queryParams) => {
    return {
      type: ActionTypes.GET_DIGITAL_PRODUCT_INVOICES,
      payload: {
        queryParams
      },
    };
  };

  static getDigitalProductInvoicesSuccess = (data) => {
    return {
      type: ActionTypes.GET_DIGITAL_PRODUCT_INVOICES_SUCCESS,
      payload: {
        data
      },
    };
  };

  static getDigitalProductInvoicesFail = () => {
    return {
      type: ActionTypes.GET_DIGITAL_PRODUCT_INVOICES_FAIL,
    };
  };


  // Get customer details action types
  static getCustomerDetails = (documentId) => {
    return {
      type: ActionTypes.GET_CUSTOMER_DETAILS,
      payload: {
        documentId
      },
    };
  };

  static getCustomerDetailsSuccess = (data) => {
    return {
      type: ActionTypes.GET_CUSTOMER_DETAILS_SUCCESS,
      payload: {
        data,
      },
    };
  };

  static getCustomerDetailsFail = (error) => {
    return {
      type: ActionTypes.GET_CUSTOMER_DETAILS_FAIL,
      payload: {
        error
      },
    };
  };


  // Send Mail Key action types
  static sendMailKey = (ids, queryParams) => {
    return {
      type: ActionTypes.SEND_MAIL_KEY,
      payload: {
        ids,
        queryParams
      },
    };
  };

  static sendMailKeySuccess = (data) => {
    return {
      type: ActionTypes.SEND_MAIL_KEY_SUCCESS,
      payload: {
        data,
      },
    };
  };

  static sendMailKeyFail = (error) => {
    return {
      type: ActionTypes.SEND_MAIL_KEY_FAIL,
      payload: {
        error
      },
    };
  };


  // Key Reserve action types
  static deleteKeyReserve = (ids, queryParams) => {
    return {
      type: ActionTypes.DELETE_KEY_RESERVE,
      payload: {
        ids,
        queryParams
      },
    };
  };

  static deleteKeyReserveSuccess = (data) => {
    return {
      type: ActionTypes.DELETE_KEY_RESERVE_SUCCESS,
      payload: {
        data,
      },
    };
  };

  static deleteKeyReserveFail = (error) => {
    return {
      type: ActionTypes.DELETE_KEY_RESERVE_FAIL,
      payload: {
        error
      },
    };
  };

    /**
   * print document
   * @param {object} data
   */
    static printDocument = (data, queryParams) => {
      return {
        type: ActionTypes.PRINT_DOCUMENT,
        payload: {
          data,
          queryParams,
        },
      };
    };
  
    static printDocumentSuccess = (response) => {
      return {
        type: ActionTypes.PRINT_DOCUMENT_SUCCESS,
        payload: {
          response,
        },
      };
    };
  
    static printDocumentFail = (err) => {
      return {
        type: ActionTypes.PRINT_DOCUMENT_FAIL,
        payload: {
          err
        },
      };
    };

    /**
   * print document
   * @param {object} data
   */
    static preparationDocument = (data) => {
      return {
        type: ActionTypes.PREPARATION_DOCUMENT,
        payload: {
          data
        },
      };
    };
  
    static preparationDocumentSuccess = (response) => {
      return {
        type: ActionTypes.PREPARATION_DOCUMENT_SUCCESS,
        payload: {
          response,
        },
      };
    };
  
    static preparationDocumentFail = (err) => {
      return {
        type: ActionTypes.PREPARATION_DOCUMENT_FAIL,
        payload: {
          err
        },
      };
    };


  static clearLoadingEffects = () => {
    return {
      type: ActionTypes.CLEAR_LOADING_EFFECTS
    };
  };

  static cleanStatesInStore = (cleanedStates) => {
    return {
      type: ActionTypes.CLEAN_STATES_IN_STORE,
      payload: {
        cleanedStates
      },
    };
  };

}