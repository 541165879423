/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import * as UIHelpersDocuments from '../../../_context/DocumentsUIHelpers';
import { getConnection } from 'constants/connectionSettings';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function ActionFormatter(cellContent, row, rowIndex, { documentId, mailLabelHandler, openPrintLabelsDialog, openLabelEditDialog, openDeleteLabelDialog }) {
  return (
    <>
      {(openLabelEditDialog && row.unlabeled && documentId) &&
        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip"><FormattedMessage id="PRODUCT.PRODUCT_EDIT" /></Tooltip>}>
          <a className="btn btn-icon btn-hover-primary btn-sm" onClick={() => openLabelEditDialog({ documentId, ...row })} id={`btn_edit_${row.id}`}>
            <span className="svg-icon svg-icon-md">
              <Icon.PencilSquare />
            </span>
          </a>
        </OverlayTrigger>
      }

      {!row.unlabeled && documentId && (row.type === "return"
        ? <OverlayTrigger overlay={<Tooltip id="shipping-email-tooltip"><FormattedMessage id="GENERAL.EMAIL" /></Tooltip>}>
          <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => mailLabelHandler(row)} id={`btn_email_${row.id}`}>
            <span className="svg-icon svg-icon-md">
              <Icon.EnvelopeAt />
            </span>
          </a>
        </OverlayTrigger>
        : <OverlayTrigger overlay={<Tooltip id="shipping-print-tooltip"><FormattedMessage id="GENERAL.PRINT" /></Tooltip>}>
          <a className="btn btn-icon btn-hover-info btn-sm" onClick={() => openPrintLabelsDialog(row)} id={`btn_print_${row.id}`}>
            <span className="svg-icon svg-icon-md">
              <Icon.Printer />
            </span>
          </a>
        </OverlayTrigger>
      )}

      <OverlayTrigger overlay={<Tooltip id="shipping-delete-tooltip"><FormattedMessage id="DOCUMENT.SHIPPING.DELETE" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => openDeleteLabelDialog({ documentId, ...row })} id={`btn_delete_${row.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </a>
      </OverlayTrigger>
    </>
  )
}



export function LabelFormatter(cellContent, row, rowIndex, { documentId, openPrintLabelsDialog, openLabelEditDialog }) {

  const getService = UIHelpersDocuments.getShipperName(row.service)
  const icon = getConnection(row?.service)?.icon

  return (
    <div className="d-flex align-items-center">
      <OverlayTrigger overlay={<Tooltip id="market-tooltip" className={clsx('font-weight-bold', !(icon && !row?.unlabeled) && 'd-none')}>Open Label</Tooltip>}>
        <div type="button" target="_blank" rel="noopener noreferrer"
          onClick={() => row?.unlabeled ? openLabelEditDialog && documentId && openLabelEditDialog({ documentId, ...row }) : openPrintLabelsDialog(row)}
          className="d-flex text-decoration-none text-muted text-hover-primary mr-4" style={{ fontSize: "2.5rem" }}>
          {(icon && !row?.unlabeled) ? icon : <Icon.SupplierFIll />}
        </div>
      </OverlayTrigger>

      <div>
        <div className="font-weight-bolder text-dark-75">
          {getService}
        </div>
        <div className="font-weight-bold text-dark-50 font-size-sm">
          {row.tracking_id}
        </div>
      </div>
    </div>
  );
}