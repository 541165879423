import React, { useEffect, useState } from 'react';
import { useDigitalProductsUIContext } from '../../_context/DigitalProductsUIContext';
import { DatePickerDropdown } from '_metronic/_partials/components';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { theme } from '_metronic/_helpers';



export function InvoicesToEbayFilter({ setFilterQuery, queryParams, marketNames }) {

  const UIContext = useDigitalProductsUIContext();

  const [marketName, setMarketName] = useState(queryParams?.filter?.market_name ?? null);

  useEffect(() => {
    setMarketName(queryParams?.filter?.market_name ?? null);
  }, [queryParams]);


  return (
    <Row className="mb-3">
      <Col md="12" className='d-flex align-items-end justify-content-end'>

        <Dropdown className="dropdown-inline symbol mr-2" drop="down">
          <Dropdown.Toggle variant="outline-secondary" className='d-flex justify-content-between align-items-center font-weight-bold svg-icon svg-icon-sm' style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <Icon.Shop />
            <span className="d-none d-sm-inline ml-2">
              {marketName ?? <FormattedMessage id="DOCUMENT.FILTER.MARKET" />}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" id="dropdown_market_options">
            <ul className="navi navi-hover">
              {marketNames?.map((name, index) => (
                <li className="navi-item" key={index}>
                  <Dropdown.Item className="navi-link" onClick={() => setFilterQuery('market_name', name)} id={`dropdown_item_${name}`}>
                    <span className="svg-icon svg-icon-md mr-2">
                      <Icon.Shop />
                    </span>
                    <span className="navi-text text-nowrap font-weight-bold">
                      {name}
                    </span>
                  </Dropdown.Item>
                </li>
              ))}

              <Dropdown.Divider />

              <li className="navi-item">
                <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' onClick={() => setFilterQuery('market_name', null)} id='clear-marketplace' >
                  <FormattedMessage id='GENERAL.CLEAR' />
                </Dropdown.Item>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>

        <DatePickerDropdown UIContext={UIContext} setFilterQuery={setFilterQuery} />

      </Col>
    </Row>
  );
}