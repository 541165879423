import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { RemotePagination } from '_metronic/_partials/controls';
import { sortCaret, headerSortingClasses, NoRecordsFoundMessage, UIDates } from '_metronic/_helpers';
import { getShipperName } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { ACCOUNT_STATUS } from 'app/admin/_context/AdminUIHelper';
import { getConnection } from 'constants/connectionSettings';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { allQueryKeys } from '../_context/ReportUIHelper';
import { FormattedMessage, useIntl } from 'react-intl';
import * as columnFormatters from './column-formatters';
import { LicenseBadge } from '_metronic/_partials';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function ReportsUserTable({ reports, count, loading, UIProps, setFilterParamsBase }) {

  const { formatMessage: intl } = useIntl();

  const COLUMNS = [
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '150px' },
      classes: 'text-left',
      headerClasses: 'text-left text-nowrap',
      formatter: (cell, row) => {

        const userStatus = ACCOUNT_STATUS?.find(item => item.value === row.status)

        return (
          <div className='d-flex align-items-center'>

            <OverlayTrigger overlay={<Tooltip id="language-panel-tooltip">
              <>
                <div className='font-weight-bolder'>
                  <FormattedMessage id={userStatus?.label ? userStatus?.label : 'GENERAL.OTHER'} />
                </div>
                <div className='font-weight-bolder'> {UIDates.formatDate(row?.latestLogEntry?.created_at)}</div>
                <div className='font-weight-bolder'>{row?.latestLogEntry?.method_name}</div>
              </>
            </Tooltip>}>

              <div className={`symbol symbol-40 symbol-light-${userStatus?.className} mr-3`}>
                <div className="symbol-label">
                  <span className={clsx("svg-icon svg-icon-lg", userStatus?.textColor)}>
                    <Icon.Person />
                  </span>
                </div>
              </div>

            </OverlayTrigger>

            <div>
              <div type="button" className="text-dark text-hover-primary text-decoration-none text-nowrap preview" onClick={() => UIProps.openUserDetailsDialog(row.user_id)} id='btn_customer_preview'>
                <span className='font-size-lg font-weight-bold'>
                  {`${row.name} ${row.surname ? row.surname : ''}`}
                </span>
                <span className='text-dark-25 svg-icon svg-icon-sm invisible pl-2'>
                  <Icon.FileEarmarkText />
                </span>
              </div>

              <div type="button" className="d-flex align-items-center text-nowrap preview" onClick={() => navigator.clipboard.writeText(row.user_id)} id='btn_customer_preview'>
                <a href={`mailto:${row.email}`} className="font-weight-bold text-decoration-none text-dark-50 text-hover-primary">
                  {row.email}
                </a>
                <span className='text-dark-25 svg-icon svg-icon-sm invisible pl-2'>
                  <Icon.EnvelopeAtFill />
                </span>
              </div>
              {/* <span className='font-size-sm'> {row.user_id} </span> */}
            </div>

          </div>
        )
      },
    },
    {
      dataField: 'license.type',
      text: intl({ id: 'ADMIN.USER.LICENSE_TYPE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'text-center text-nowrap',
      headerClasses: 'text-center text-nowrap pl-4',
      headerStyle: { minWidth: '150px' },
      formatter: (cell, row) => (<LicenseBadge license={row.license} />)
    },
    {
      dataField: 'orders',
      text: intl({ id: 'DOCUMENT.ORDER_TITLE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bolder font-size-lg text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '10px', width: "4%" },
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      formatter: columnFormatters.OrderFormatter,
    },
    {
      dataField: 'invoices',
      text: intl({ id: 'DOCUMENT.INVOICE_TITLE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bolder font-size-lg text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '10px', width: "4%" },
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      formatter: columnFormatters.OrderFormatter,
    },
    {
      dataField: 'offers',
      text: intl({ id: 'DOCUMENT.OFFER_TITLE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bolder font-size-lg text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '10px', width: "4%" },
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'credits',
      text: intl({ id: 'DOCUMENT.CREDIT_TITLE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bolder font-size-lg text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '10px', width: "4%" },
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'waybills',
      text: intl({ id: 'DOCUMENT.WAYBILL_TITLE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bolder font-size-lg text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '10px', width: "4%" },
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'shipping',
      text: intl({ id: 'SHIPPING.TITLE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bolder font-size-lg text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '10px', width: '4%' },
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      formatter: (cell, row) => {
        return (
          <OverlayTrigger placement="top" overlay={<Tooltip id="shipping-tooltip">
            {cell?.services?.map((item, index) => (
              <li key={index} className='text-left pb-0'>
                {getShipperName(item.service) + ": " + item.count}
              </li>
            ))}
          </Tooltip>}>
            <span>{cell.total}</span>
          </OverlayTrigger>
        );
      }
    },
    {
      dataField: 'products',
      text: intl({ id: 'PRODUCT.TITLE_PLURAL' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bolder font-size-lg text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '10px', width: "4%" },
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'customers',
      text: intl({ id: 'CUSTOMER.TITLE_PLURAL' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bolder font-size-lg text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '10px', width: "4%" },
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'suppliers',
      text: intl({ id: 'SUPPLIER.TITLE' }),
      hidden: true,
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bolder font-size-lg text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '10px', width: "4%" },
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'supports',
      text: intl({ id: 'SUPPORT.TITLE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bolder font-size-lg text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '10px', width: "4%" },
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      formatter: columnFormatters.SupportFormatter,
    },
    {
      dataField: 'transactions',
      text: intl({ id: 'GENERAL.TRANSACTIONS' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bolder font-size-lg text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '10px', width: "4%" },
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'connection_count',
      text: intl({ id: 'GENERAL.NUMBER_OF_API' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'font-weight-bolder font-size-lg text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '10px', width: "4%" },
      editorStyle: () => ({ height: '32px', textAlign: 'center' }),
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'connections',
      text: intl({ id: 'MENU.ASIDE.CONNECTIONS' }),
      classes: 'font-weight-bold text-left text-nowrap',
      headerClasses: 'text-left text-nowrap',
      headerStyle: { minWidth: '10px', width: '10%' },
      editorStyle: () => ({ height: '32px', textAlign: 'left' }),
      formatter: (cell, row) => {
        ['marketplace', 'shop', 'shipper'].map((type, i) => {
          const filteredItems = cell?.filter(item => item.type === type);
          if (!filteredItems || filteredItems.length === 0) return null;
          return (
            <div key={type} className={clsx('d-flex symbol-group symbol-hover', i !== 2 && "border-right mr-3")}>
              {filteredItems.map((item, i) => (
                <OverlayTrigger key={item.id} overlay={<Tooltip id="connection-tooltip" className="font-weight-bold">{item.market_name ? item.market_name : item.website ? item.website : getConnection(item.name)?.label}</Tooltip>}>
                  <span className="symbol d-flex bg-white svg-icon svg-icon-xl mr-3">
                    {getConnection(item.name)?.icon}
                  </span>
                </OverlayTrigger>
              ))}
            </div>
          )
        });
      }
    },
    {
      dataField: 'action',
      text: '...',
      classes: 'text-center text-nowrap',
      headerClasses: 'text-center icon-lg',
      headerStyle: { minWidth: '100px' },
      formatter: columnFormatters.ActionsColumnFormatter,
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="user_id"
        data={reports || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center"
        bootstrap4
        striped
        bordered={false}
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { UIProps.setFilterQuery('DELETE_PARAMS', allQueryKeys); setFilterParamsBase({}); UIProps.changeDateRange() }} />}
      />
      <RemotePagination
        queryParams={UIProps.queryParams.pagination}
        setQueryParams={UIProps.setPaginationQuery}
        totalCount={count}
        entitiesCount={reports?.length}
        loading={loading}
      />
    </>
  );
}
