import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { useDocumentLocalUIContext } from '../_context/DocumentLocalUIContext';
import * as uiHelpers from '../_context/DocumentsUIHelpers';
import { DocumentActions } from '../_redux/actions';
import EnterPaymentBody from './EnterPaymentBody';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';



export function EnterPaymentDialog({ show, onHide, id }) {

  const dispatch = useDispatch();

  const [paymentAmountDifference, setPaymentAmountDifference] = useState(0);

  const UIContext = useDocumentsUIContext();
  const contextLocal = useDocumentLocalUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    documentQueryParams: contextLocal.queryParams,
    handleOpenEnterPaymentEditDialog: UIContext.handleOpenEnterPaymentEditDialog,
    requestedDocument: UIContext.requestedDocument,
  }),
    [
      contextLocal.queryParams,
      UIContext.handleOpenEnterPaymentEditDialog,
      UIContext.queryParams,
      UIContext.requestedDocument,
    ]
  );

  useEffect(() => {
    if (!id) {
      onHideHandler();
    } else {
      dispatch(DocumentActions.getDocumentById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);


  const handleOpenEnterPaymentEditDialog = () => {
    UIProps.handleOpenEnterPaymentEditDialog();
  };

  const onHideHandler = () => {
    onHide();
    dispatch(
      DocumentActions.cleanStatesInStore({
        documentForEdit: uiHelpers.initialDocument,
        actionController: uiHelpers.initialStatesForReducer.actionController,
      })
    );
  };

  const goBackDocumentTable = () => {
    dispatch(DocumentActions.getDocuments(UIProps.documentQueryParams));
    onHideHandler();
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={goBackDocumentTable} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>

        <Modal.Header className='py-3'>

          <Modal.Title>
            <FormattedMessage id='DOCUMENT.ACTION.PAYMENTS' />
          </Modal.Title>

          <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={handleOpenEnterPaymentEditDialog} disabled={paymentAmountDifference <= 0} id='btn_open'>
            <Icon.Plus />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id='DOCUMENT.PAYMENT.ENTER_TITLE' />
            </span>
          </Button>

        </Modal.Header>

        <Modal.Body className='p-0'>

          <EnterPaymentBody documentId={id} setPaymentAmountDifference={setPaymentAmountDifference} />

        </Modal.Body>

        <Modal.Footer>
          <Button variant='light' onClick={goBackDocumentTable} id='btn_close_modal'>
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>
        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
