import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ListGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import * as columnFormatters from '../column-formatters/ColumnFormatters';
import { TAX_OPTIONS, UNIT_OPTIONS } from '../../../../products/_context/ProductsUIHelpers';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { BlankMessage } from '_metronic/_partials/components';
import { validator } from '../column-formatters/validator';
import { DocumentActions } from '../../../_redux/actions';
import { convertPriceCurrency } from '_metronic/_helpers';
import { FormattedMessage, useIntl } from 'react-intl';
import { SVInput } from '_metronic/_partials/controls';
import { UserInfoDropdown } from '_metronic/_partials';
import { useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useWindowSize } from 'app/hooks';
import { Icon } from '_metronic/_icons';
import { replace } from 'lodash';
import clsx from 'clsx';



export function PositionsTable({ loading }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const { values } = useFormikContext();
  const { windowSize } = useWindowSize();
  const location = useLocation();

  const taxCategory = values?.tax?.category === "domestic" || values?.tax?.category === "eu_region" || !values?.tax?.category
  const { positions, positionTotals, products, documents } = useSelector(state => ({
    positions: state.documents.positions,
    positionTotals: state.documents.positionTotals,
    products: state.products.entities,
    documents: state.documents.documentForEdit
  }), shallowEqual);

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    type: UIContext.requestedDocument.type,
  }), [UIContext]);

  const currency = documents?.marketplace === "" ? positionTotals?.currency : documents?.pricing_summary.currency;

  const isMarketplace = !documents?.marketplace;

  const isCredit = isMarketplace || (!isMarketplace && UIProps.type === "credits");

  const [togglePriceOption, setTogglePriceOptionBase] = useState(!isMarketplace);

  const foundDiscount = positions.length > 0 ? documents?.pricing_summary?.discount : documents?.customer_details?.discount || 0;

  const [discount, setDiscount] = useState(foundDiscount);

  const setTogglePriceOption = useCallback(() => setTogglePriceOptionBase(!togglePriceOption), [togglePriceOption]);

  const tableRowHandler = (oldValue, newValue, row, column) => {

    if (oldValue === newValue) { return; }

    if (typeof row.quantity === 'string') {
      row.quantity = replace(row.quantity, ',', '.');
      row.quantity = +row.quantity;
    }
    if (typeof row.net_price === 'string') {
      row.net_price = replace(row.net_price, ',', '.');
      row.net_price = +row.net_price;
    }
    if (column.dataField === 'gross_price') {
      row.net_price = +row.gross_price / (1 + +row.tax_rate / 100);
    }
    if (column.dataField === 'net_price') {
      row.gross_price = +row.net_price * (1 + +row.tax_rate / 100);
    }
    if (column.dataField === 'tax_rate') {
      if (togglePriceOption) {
        row.net_price = +row.gross_price / (1 + +row.tax_rate / 100);
      } else {
        row.gross_price = +row.net_price * (1 + +row.tax_rate / 100);
      }
    }
    if (typeof row.gross_price === 'string') {
      row.gross_price = replace(row.gross_price, ',', '.');
      row.gross_price = +row.gross_price;
    }
    if (column.dataField === 'weight') {
      row.weight = +row.weight.replace(',', '.');
    }

    dispatch(DocumentActions.updatePosition(row, column));
  };

  let skuArray = [];
  products.forEach(item => {
    if (item.variants.length > 0) {
      skuArray = skuArray.concat(item.variants.map(variant => ({ sku: variant.sku, quantity: variant.quantity, category: item.category })));
    } else {
      skuArray.push({ sku: item.sku, quantity: item.quantity, category: item.category });
    }
  });

  const unitOptions = UNIT_OPTIONS.map(item => ({
    value: item.value,
    label: intl({ id: item.label })
  }));

  const COLUMNS = [
    {
      dataField: '#',
      text: intl({ id: 'GENERAL.NO' }),
      isDummyField: true,
      headerStyle: { minWidth: '50px', width: "3%" },
      headerClasses: 'text-center pl-9',
      classes: 'text-center font-weight-bolder text-dark-50 pl-9',
      editable: false,
      formatter: (cell, row, rowIndex) => (rowIndex + 1),
    },
    {
      dataField: 'title',
      text: intl({ id: 'GENERAL.NAME' }),
      headerStyle: { minWidth: '250px', width: "10%" },
      headerClasses: 'pl-4',
      classes: 'font-weight-bolder text-dark-75 font-size-lg',
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem" }),
      validator: (cell, row, column) => validator.isValidTitle(cell, intl),
      formatter: (cell, row) => <div type="button" className={clsx("edit-table", !cell && "form-control form-control-sm is-invalid")}>{cell}</div>,
      editor: { type: Type.TEXTAREA }
    },
    {
      dataField: 'sku',
      text: intl({ id: 'GENERAL.SKU' }),
      headerStyle: { minWidth: '80px', width: "10%" },
      style: { cursor: isMarketplace ? 'pointer' : 'default' },
      headerClasses: isMarketplace ? 'pl-4' : '',
      classes: 'font-weight-bolder text-dark-50',
      editable: isMarketplace,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
      formatter: (cell, row) => <div className={clsx(isMarketplace && "edit-table")}>{cell}</div>
    },
    {
      dataField: 'quantity',
      text: intl({ id: 'PRODUCT.GENERAL.QUANTITY' }),
      // type: 'number',
      headerStyle: { minWidth: '60px', width: "6%" },
      style: { cursor: isCredit ? 'pointer' : 'default' },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editable: isCredit,
      editorStyle: () => ({ height: "32px", textAlign: "center" }),
      validator: (cell, row, column) => validator.isValidQuantity(cell, row, skuArray, documents?.position, intl),
      formatter: (cell, row) => <div className={clsx(isCredit && "edit-table")}>{cell}</div>
    },
    {
      dataField: 'unit',
      text: intl({ id: 'DOCUMENT.POSITION_UNIT' }),
      headerStyle: { minWidth: '100px', width: "10%" },
      style: { cursor: isMarketplace ? 'pointer' : 'default' },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editable: isMarketplace,
      editor: { type: Type.SELECT, options: unitOptions },
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem 0.5rem 1.75rem" }),
      formatter: (cell, row) => (
        <div className={clsx(isMarketplace && "edit-table")}>
          {unitOptions.find(item => item.value === cell)?.label}
        </div>
      ),
    },
    {
      dataField: 'weight',
      text: intl({ id: 'GENERAL.WEIGHT' }),
      headerStyle: { width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center',
      editorStyle: () => ({ height: "34.7px", textAlign: "center" }),
      validator: (cell, row, column) => validator.isValidWeight(cell, intl),
      formatter: (cell, row) => <div type="button" className="edit-table">{cell ? +cell : 0}</div>,
    },
    {
      dataField: 'tax_rate',
      text: intl({ id: 'DOCUMENT.POSITION_TAX_FIELD' }),
      headerStyle: { minWidth: '100px', width: "6%" },
      style: { cursor: taxCategory ? 'pointer' : 'default' },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      type: 'number',
      editable: taxCategory,
      editor: {
        type: Type.SELECT, options: TAX_OPTIONS.map(item => ({
          value: item.value,
          label: intl({ id: item.label })
        }))
      },
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem 0.5rem 1.75rem" }),
      formatter: (cell, row) => <div className={clsx(taxCategory && "edit-table")}>{cell + "%"}</div>,
    },
    {
      dataField: 'net_price',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_NET' }),
      hidden: togglePriceOption,
      headerStyle: { minWidth: '100px', width: "8%" },
      style: { cursor: isCredit ? 'pointer' : 'default' },
      headerClasses: 'text-right pr-4',
      classes: 'font-weight-bold text-right',
      editable: isCredit,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "right" }),
      validator: (cell, row, column) => validator.isValidPrice(cell, row, intl),
      formatter: (cell, row) => <div className={clsx(isCredit && "edit-table")}>{convertPriceCurrency(+cell, currency)}</div>,
    },
    {
      dataField: 'gross_price',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_GROSS' }),
      hidden: !togglePriceOption,
      headerStyle: { minWidth: '100px', width: "8%" },
      style: { cursor: isMarketplace ? 'pointer' : 'default' },
      headerClasses: 'text-right',
      classes: 'font-weight-bold text-right',
      editable: isMarketplace,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "right" }),
      validator: (cell, row, column) => validator.isValidPrice(cell, row, intl),
      formatter: (cell, row) => <div className={clsx(isMarketplace && "edit-table")}>{convertPriceCurrency(+cell, currency)}</div>,
    },
    {
      dataField: 'total_net_price',
      text: intl({ id: 'DOCUMENT.POSITION_GP_NET' }),
      headerStyle: { minWidth: '120px', width: "10%" },
      headerClasses: 'text-right',
      classes: 'font-weight-bolder text-right',
      editable: false,
      formatter: (cell, row) => convertPriceCurrency(+cell, currency)
    },
    {
      dataField: 'total_gross_price',
      text: intl({ id: 'DOCUMENT.POSITION_GP_BRUT' }),
      headerStyle: { minWidth: '120px', width: "10%" },
      headerClasses: `text-right ${!(isMarketplace || (!isMarketplace && UIProps.type === "credits")) && "pr-10"}`,
      classes: `font-weight-bolder text-right ${!(isMarketplace || (!isMarketplace && UIProps.type === "credits")) && "pr-10"}`,
      editable: false,
      formatter: (cell, row) => convertPriceCurrency(+cell, currency)
    },
    {
      dataField: 'action',
      text: "...",
      hidden: !(isMarketplace || (!isMarketplace && UIProps.type === "credits")),
      headerStyle: { minWidth: '40px', width: "3%" },
      headerClasses: 'font-size-h3 text-center pt-0 px-9',
      classes: 'text-center px-9',
      editable: false,
      formatter: columnFormatters.ActionFormatter,
      formatExtraData: { deletePositionAction: id => dispatch(DocumentActions.deletePosition(id)) },
    },
  ];

  //const paymentWarningsPrice = documents?.payment_warnings?.reduce((acc, warning) => acc + +warning.amount, 0) ?? 0;

  const totalPrice = [
    {
      title: intl({ id: 'DOCUMENT.POSITION_TOTAL_NET' }),
      price: positionTotals.sub_total
    },
    ...((discount !== "" && discount > 0) ? [{
      title: intl({ id: 'GENERAL.DISCOUNT_VALUE' }, { name: `${discount} %` }),
      price: - discount * (positionTotals.sub_total) / 100
    }] : []),
    ...((positionTotals?.total_price_discount) ? [{
      title: intl({ id: 'GENERAL.DISCOUNT_VALUE' }, { name: `Br.: ${convertPriceCurrency(-positionTotals.total_price_discount, currency)}` }),
      price: (positionTotals.total_price_discount ?? 0) + (positionTotals.priceDiscountTaxTotal ?? 0)
    }] : []),
    {
      title: intl({ id: 'DOCUMENT.POSITION_TOTAL_ITEM' }),
      price: positionTotals.total_tax - ((discount ?? 0) * (positionTotals.total_tax) / 100) - (positionTotals.priceDiscountTaxTotal ?? 0)
    },
    /*     ...((documents?.payment_warnings?.length > 0) ? [{
          title: intl({ id: 'DOCUMENT.PAYMENT.WARNINGS_PRICE' }, { name: discount }),
          price: documents?.payment_warnings?.reduce((acc, warning) => acc + +warning.amount, 0)
        }] : []), */
    {
      title: intl({ id: 'DOCUMENT.POSITION_TOTAL_BRUT' }),
      price: positionTotals.total - ((discount ?? 0) * (positionTotals.total) / 100) /* + paymentWarningsPrice */ + (positionTotals?.total_price_discount ?? 0)
    },
    // {
    //   title: intl({ id: 'DOCUMENT.POSITION_TOTAL_SHIPPING' }),
    //   price: positionTotals.shipping_price
    // },
    // {
    //   title: intl({ id: 'DOCUMENT.POSITION_TOTAL_AMOUNT' }),
    //   price: positionTotals.total + positionTotals.shipping_price - (discount ?? 0) * (positionTotals.total) / 100
    // }
  ];

  useEffect(() => {
    dispatch(DocumentActions.updateDiscount(discount));
  }, [dispatch, discount])

  useEffect(() => {
    if (location.pathname.includes('/copy')) {
      setDiscount(documents.customer_details?.discount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, documents.customer_details?.discount])

  const handleDiscountChange = (e) => {
    const newDiscount = Number(e.target.value);
    if (newDiscount >= 0 && newDiscount <= 100) {
      setDiscount(newDiscount);
    }
  };

  return (
    positions.length === 0
      ? <BlankMessage
        icon={<Icon.Layers />}
        title={<FormattedMessage id='DOCUMENT.POSITION.BLANK_MESSAGE_TITLE' />}
        message={<FormattedMessage id='DOCUMENT.POSITION.BLANK_MESSAGE' />}
        arrow
      />
      : <>
        <BootstrapTable
          keyField="id"
          data={positions || []}
          columns={COLUMNS}
          wrapperClasses="table-responsive"
          classes="table table-head-custom table-vertical-center"
          headerClasses="bg-info-o-10"
          bootstrap4
          bordered={false}
          deleteRow={true}
          condensed
          cellEdit={cellEditFactory({
            mode: windowSize < 576 ? 'click' : 'dbclick',
            blurToSave: true,
            afterSaveCell: tableRowHandler,
          })}
        />


        <div className='d-flex justify-content-end border-top w-100 mb-5 pr-0 pr-md-8'>
          <ListGroup variant="flush" style={{ width: "400px" }}>

            <ListGroup.Item className="d-flex justify-content-between">

              <div className="switch switch-icon">
                <label className="form-label font-size-lg font-weight-bold d-flex align-items-center mr-3" htmlFor="custom-switch">
                  <FormattedMessage id='DOCUMENT.POSITION_GROSS' />
                  <UserInfoDropdown description={<FormattedMessage id='DOCUMENT.POSITION_ADD.POSITION_GROSS' />} />
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="select"
                    id="custom-switch"
                    checked={togglePriceOption}
                    onChange={e => setTogglePriceOption(e.target.checked)}
                    disabled={loading}
                  />
                  <span />
                </label>
              </div>

              {isMarketplace && <div className='d-flex align-items-center'>
                <label className="form-label font-size-lg font-weight-bold d-flex align-items-center mb-0 mr-3">
                  <FormattedMessage id='GENERAL.DISCOUNT' />
                  <UserInfoDropdown description={<FormattedMessage id='DOCUMENT.POSITION_ADD.DISCOUNT' />} />
                </label>
                <div className="input-group">
                  <SVInput
                    name="discount"
                    type="number"
                    min="0"
                    max="100"
                    step="0.01"
                    size="sm"
                    value={discount}
                    onChange={handleDiscountChange}
                    style={{ width: "65px" }}
                    disabled={loading}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text svg-icon svg-icon-sm">
                      <Icon.Percent />
                    </span>
                  </div>
                </div>
              </div>}
            </ListGroup.Item>


            {totalPrice.map((item, i) => (
              <ListGroup.Item key={i} className={`d-flex justify-content-between text-muted ${totalPrice.length - 1 === i && "h6"}`}>
                <span className='font-weight-bold text-uppercase'>{item.title}</span>
                <span className='font-weight-boldest'>{convertPriceCurrency(+item.price, currency)}</span>
              </ListGroup.Item>
            ))}

          </ListGroup>
        </div>

      </>
  );
}
