import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { InvoicesToEbayFilter } from "./invoices-to-ebay-filter/InvoicesToEbayFilter";
import { InvoicesToEbayTable } from "./invoices-to-ebay-table/InvoicesToEbayTable";
import { useSearchQueryContext } from "../_context/SearchQueryProvider";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls";
import { BlankMessage } from "_metronic/_partials/components";
import { LoadingDialog } from "_metronic/_partials/loading";
import { digitalProductsActions } from "../_redux/actions";
import { Icon } from '_metronic/_icons';



export function InvoicesToEbayCard() {

  const dispatch = useDispatch();

  const { invoicesEbay, loading, totalInvoicesEbay, connections } = useSelector(state => ({
    invoicesEbay: state.digitalProduct.invoicesEbay,
    loading: state.digitalProduct.loading,
    totalInvoicesEbay: state.digitalProduct.totalInvoicesEbay,
    connections: state.connections.entities
  }));

  const queryContext = useSearchQueryContext();
  const { queryParams, setOrderQuery, setPaginationQuery, setSearchQuery, setFilterQuery } = useMemo(() => ({
    queryParams: queryContext.queryParams,
    setOrderQuery: queryContext.setOrderQuery,
    setPaginationQuery: queryContext.setPaginationQuery,
    setSearchQuery: queryContext.setSearchQuery,
    setFilterQuery: queryContext.setFilterQuery
  }), [queryContext]);


  const myEbay = connections?.filter(item => item?.name === 'ebay') || [];
  const marketName = myEbay.map(item => item?.ebay?.market_name) || [];
  const ebayMarketNames = invoicesEbay ? invoicesEbay.map(inv => inv.market_name) : [];
  const marketNames = [...new Set([...marketName, ...ebayMarketNames])];


  useEffect(() => {
    dispatch(digitalProductsActions.getDigitalProductInvoices(queryParams))
  }, [dispatch, queryParams]);


  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Document />}
        title={<FormattedMessage id="DIGITAL_PRODUCT.INVOICE" />}
        className="display-5"
        id='title_digital_products'
      />

      <CardBody>
        <InvoicesToEbayFilter setFilterQuery={setFilterQuery} queryParams={queryParams} marketNames={marketNames} />
        {totalInvoicesEbay === 0
          ? <BlankMessage
            icon={<Icon.Document />}
            className='min-50-height'
            loading={loading}
          />
          :
          <InvoicesToEbayTable
            loading={loading}
            invoicesEbay={invoicesEbay}
            totalInvoicesEbay={totalInvoicesEbay}
            queryParams={queryParams}
            setPaginationQuery={setPaginationQuery}
            setOrderQuery={setOrderQuery}
            setSearchQuery={setSearchQuery}
          />
        }
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}