/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';



export function ActionFormatter(cellContent, row, rowIndex, { openSendEmailsDialog, openPrintDialog, paymentWarnings, openPaymentWarningsDelete, }) {

  return (
    <>
      {row?.document_id
        ? <>
          <OverlayTrigger overlay={<Tooltip id="shipping-mail-tooltip" className='font-weight-bold'><FormattedMessage id="GENERAL.EMAIL" /></Tooltip>}>
            <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => openSendEmailsDialog({ isEmail: true, data: row })} id={`btn_email_${row?.id}`}>
              <span className="svg-icon svg-icon-md">
                <Icon.EnvelopeAt />
              </span>
            </a>
          </OverlayTrigger>

          <OverlayTrigger overlay={<Tooltip id="shipping-print-tooltip" className='font-weight-bold'><FormattedMessage id="GENERAL.PRINT" /></Tooltip>}>
            <a className="btn btn-icon btn-hover-info btn-sm" onClick={() => openPrintDialog({ isEmail: false, data: row })} id={`btn_print_${row?.id}`}>
              <span className="svg-icon svg-icon-md">
                <Icon.Printer />
              </span>
            </a>
          </OverlayTrigger>
        </>
        : null
      }

    </>
  )
}

