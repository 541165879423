import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePdfMaker, useLoadingHandler, usePreventPrintKeyHandler } from 'app/hooks';
import { useArchiveUIContext } from '../_context/ArchiveUIContext';
import { PdfViewer } from '_metronic/_partials/components';
import { ArchiveActions } from '../_redux/actions';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { Portal } from 'react-portal';



export function ArchivePrint() {

  // Prevent (ctrl+p) from opening print dialog in browser (Chrome)
  usePreventPrintKeyHandler();
  const dispatch = useDispatch();

  const { documentPDF, loading } = useSelector(state => ({
    documentPDF: state.archive.documentPDF,
    loading: state.archive.printLoading,
  }));

  const UIContext = useArchiveUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showArchivePrintDialog,
    onHide: UIContext.closeArchivePrintDialog
  }), [
    UIContext.showArchivePrintDialog,
    UIContext.closeArchivePrintDialog,
  ]);

  const [pdf, error, resetPdfMaker] = usePdfMaker(documentPDF);

  const onHideHandler = () => {
    dispatch(ArchiveActions.cleanUpReducer({ documentPDF: {} }));
    resetPdfMaker();
    UIProps.onHide();
  };

  const useLoading = useLoadingHandler(loading, ArchiveActions.cleanUpReducer({ printLoading: false }));


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={onHideHandler} keyboard={false} aria-labelledby="example-modal-sizes-title-lg" size="xl">

        <Modal.Header className="py-3">

          <Modal.Title>
            <FormattedMessage id="GENERAL.PRINT_PREVIEW" />
          </Modal.Title>

          <CloseButton onClick={onHideHandler} />

        </Modal.Header>

        {useLoading
          ? <div className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
            <div className='spinner spinner-primary spinner-xl mr-16' />
          </div>
          : pdf
            ? <PdfViewer url={pdf} />
            : <div className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <h3 className='text-muted'> {error ?? <FormattedMessage id="GENERAL.DOCUMENT_NOT_FOUNT" />}</h3>
            </div>
        }

      </Modal>
    </Portal>
  );
}
