import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { UserInfoDropdown } from '_metronic/_partials';
import { Card, CardBody, CardSubHeader, SwitchControl } from '_metronic/_partials/controls';
import { Icon } from '_metronic/_icons';
import { Field } from 'formik';



export function PrintDetails() {

  const { formatMessage: intl } = useIntl();

  const INPUT = [
    {
      type: 'mega',
      name: "print_details.payment_method",
      label: "DOCUMENT.PAYMENT_METHOD",
      description: "SETTINGS.DOCUMENT.PRINT_DETAILS.INFO"
    }
  ];

  return (
    <Card className='card-stretch gutter-b'>

      <CardSubHeader
        icon={<Icon.FileText />}
        title={<FormattedMessage id="SETTINGS.DOCUMENT.PRINT_DETAILS.TITLE" />}
        info={<UserInfoDropdown description={<FormattedMessage id='SETTINGS.DOCUMENT.PRINT_DETAILS.TITLE_INFO' />} />}
      />

      <CardBody>
        <Row>

          {INPUT.map(item => (
            <Col md="12" key={item.name}>
              <Field component={SwitchControl}
                {...{
                  type: item.type,
                  name: item.name,
                  label: intl({ id: item.label }),
                  description: intl({ id: item.description }),
                  info: intl({ id: item.description }),
                  defaultValue: true
                }}
              />
            </Col>
          ))}

        </Row>
      </CardBody>
    </Card>
  );
}


