import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls";
import { ProductKeysTable } from "./product-keys-table/ProductKeysTable";
import { ProductKeysFilter } from "./product-keys-filter/ProductKeysFilter";
import { useSearchQueryContext } from "../_context/SearchQueryProvider";
import { BlankMessage } from "_metronic/_partials/components";
import { LoadingDialog } from "_metronic/_partials/loading";
import { digitalProductsActions } from "../_redux/actions";
import { FormattedMessage } from "react-intl";
import { Icon } from '_metronic/_icons';



export function ProductsKeysCard() {

  const dispatch = useDispatch();

  const { entities, loading, countDigitalProduct, totalDigitalProduct, connectionEntities } = useSelector(state => ({
    entities: state.digitalProduct.entities,
    loading: state.digitalProduct.loading,
    countDigitalProduct: state.digitalProduct.countDigitalProduct,
    totalDigitalProduct: state.digitalProduct.totalDigitalProduct,
    connectionEntities: state.connections.entities
  }));


  const queryContext = useSearchQueryContext();
  const { queryParams, setOrderQuery, setPaginationQuery, setSearchQuery, setFilterQuery, changeDateRange } = useMemo(() => ({
    queryParams: queryContext.queryParams,
    setOrderQuery: queryContext.setOrderQuery,
    setPaginationQuery: queryContext.setPaginationQuery,
    setSearchQuery: queryContext.setSearchQuery,
    setFilterQuery: queryContext.setFilterQuery,
    changeDateRange: queryContext.changeDateRange,
  }), [queryContext]);

  useEffect(() => {
    let delayDebounceFn;
    if (queryParams.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(digitalProductsActions.getDigitalProductKeys(queryParams))
      }, 350);
    } else {
      dispatch(digitalProductsActions.getDigitalProductKeys(queryParams))
    }

    return () => { clearTimeout(delayDebounceFn) };
  }, [dispatch, queryParams]);


  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Password />}
        title={<FormattedMessage id="PRODUCT.KEYS" />}
        className="display-5"
        id='title_digital_products'
      />

      <CardBody>
        {totalDigitalProduct === 0
          ? <BlankMessage
            icon={<Icon.Password />}
            className='min-50-height'
            loading={loading}
          />
          : <>
            <ProductKeysFilter connectionEntities={connectionEntities} entities={entities} />
            <ProductKeysTable
              loading={loading}
              entities={entities}
              countDigitalProduct={countDigitalProduct}
              queryParams={queryParams}
              setPaginationQuery={setPaginationQuery}
              setOrderQuery={setOrderQuery}
              setSearchQuery={setSearchQuery}
              setFilterQuery={setFilterQuery}
              changeDateRange={changeDateRange}
            />
          </>
        }
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}