import { useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { isFunction } from 'lodash';
import moment from 'moment';
import QS from 'qs';



const QSParse = search => QS.parse(search, { ignoreQueryPrefix: true, allowDots: true });
const QSStringify = params => QS.stringify(params, { addQueryPrefix: true, allowDots: true });


const getParams = (params) => {
  return {
    pagination: {
      page: +params?.page || 1,
      limit: params.limit ? +params.limit : 10,
    },
    filter: params?.filter || {},
    order: params?.order || 'id DESC',
    search: params?.search || '',
  };
};


export function useSearchQuery(setQueryBase) {

  const history = useHistory();
  const location = useLocation();
  const { currency } = useSelector(state => state.settings.dashboardSettings);

  // FIXME: Tabloda tüm filtreler silindiğinde default filtrelerin gelmesi için. Mutlaka başka şekilde çözülmeli.
  const [deleteParams, setDeleteParams] = useState(0);

  useEffect(() => {
    const params = QSParse(location.search);

    if (!params.page && location.pathname !== "/dashboard") params.page = 1;

    if (location.pathname === "/dashboard" && !params?.filter?.currency) {
      params.filter = { currency: currency }
    }

    // if location is /document/orders or /support and no status filter, then set status filter to "open"
    if ((location.pathname === "/document/orders" || location.pathname === "/support") && !params?.filter?.status) {
      params.filter = { status: 'open' };
    }

    if ((location.pathname === "/admin/reports-user" || location.pathname === "/admin/user") && !params?.filter?.status) {
      params.filter = { status: 'active' };
    }

    if (location.pathname === "/archive" && !params?.filter?.doc_type) {
      params.filter = { doc_type: 'orders' };
    }
    
    if (location.pathname === "/digital-product/invoices-to-ebay" && !params.order) {
      params.order = 'date_from DESC';
    }

    history.replace({ search: QSStringify(params) });
    if (isFunction(setQueryBase)) setQueryBase(getParams(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteParams]);


  const getQueryParamsFromUrl = useMemo(() => {
    const params = QSParse(location.search);
    return getParams(params);
  }, [location.search]);


  const setPaginationQuery = useCallback(({ page, limit }) => {
    const params = QSParse(location.search);

    params.page = page;
    if (limit) params.limit = limit;
    setQueryBase(getParams(params));
    history.push({ search: QSStringify(params) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location.search]
  );


  const setFilterQuery = useCallback((key, value, doc_type) => {
    const params = QSParse(location.search);

    if (location.pathname !== "/dashboard") {
      params.page = 1;
    }

    if (!params.filter) params.filter = {};

    // if key is "DELETE_PARAMS", then delete all params from filter
    if (key === 'DELETE_PARAMS') {

      if (Array.isArray(value)) {
        value.forEach(item => {
          delete params.filter[item];
        });
        value.includes('search') && delete params.search;
      } else {
        delete params.filter[value];
      }

      setDeleteParams(deleteParams + 1);

      setQueryBase(getParams(params));
      history.push({ search: QSStringify(params) });
      return;
    }

    if (key === "DATE_FILTER") {
      params.filter.date_from = moment().add(-7, 'days').format('YYYY-MM-DD');
      params.filter.date_to = moment().format('YYYY-MM-DD');
      params.filter.doc_type = doc_type;

      setQueryBase(getParams(params));
      history.push({ search: QSStringify(params) });
      return;
    }

    if (key === "DATE_RANGE") {
      params.filter.date_from = value[0];
      params.filter.date_to = value[1];

      setQueryBase(getParams(params));
      history.push({ search: QSStringify(params) });
      return;
    }

    if (key === "published") {
      const { unpublished, ...rest } = params.filter;
      params.filter = { ...rest };
    }

    if (key === "unpublished") {
      const { published, ...rest } = params.filter;
      params.filter = { ...rest };
    }

    if (key === "success_filter") {
      const { error_filter, ...rest } = params.filter;
      params.filter = { ...rest };
    }

    if (key === "error_filter") {
      const { success_filter, ...rest } = params.filter;
      params.filter = { ...rest };
    }

    if (key === "status") {
      const { status, ...rest } = params.filter;
      params.filter = { ...rest };
    }

    // Key object olarak gönderilirse
    if (typeof key === 'object' && key !== null) {
      // setFilterQuery(updatedFilters, "connections"); formatında gönderirsem, Query parametresi filter.connections.dhl formatında olsun
      // setFilterQuery(updatedFilters); formatında gönderirsem, Query parametresi filter.dhl formatında olsun
      // Bu düzenlemeyi middleware kısmında kolaylık olması için oluşturdum
      // Böylece örneğin connectionsla ilgili bütün filtreleri middleware da connections olarak çekebiliriz
      // Eğer value varsa ve params.filter[value] henüz tanımlı değilse, boş bir obje olarak başlat
      const filterObj = value ? params.filter[value] : params.filter;
      // Eğer value varsa ve params.filter[value] henüz tanımlı değilse, boş bir obje olarak başlat
      if (value && !filterObj) {
        params.filter[value] = {};
      }
      // key objesi içindeki her bir filtreyi işle
      Object.entries(key).forEach(([filterKey, filterValue]) => {
        // Her bir key için değer varsa bunu filter ya da filter[value] içine koy,
        // Değeri olmayan ya da false olanı Query parametresinden sil.
        if (!filterValue || filterValue === false) {
          delete params.filter[value][filterKey];
        } else {
          params.filter[value][filterKey] = filterValue;
        }
      });
      
    }     
    // Key topluca değil de tek tek gönderilirse
    else {
      if (!value) {
        delete params.filter[key];
      } else {
        params.filter[key] = value;
      }
    }

    if (doc_type && doc_type === 'orders') {
      params.filter.doc_type = doc_type;
    }

    if (Object.keys(params.filter).length === 0) {
      params.filter = {};
    }

    setQueryBase(getParams(params));
    history.push({ search: QSStringify(params) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location.search]
  );


  const setSearchQuery = useCallback(search => {
    const params = QSParse(location.search);

    params.page = 1;
    if (!search) {
      delete params.search;
    } else {
      params.search = search;
    }
    setQueryBase(getParams(params));
    history.push({ search: QSStringify(params) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location.search]
  );


  const setOrderQuery = useCallback((key, value) => {
    const params = QSParse(location.search);

    if (!params.page) params.page = 1;
    params.order = `${key} ${value.toUpperCase()}`;
    setQueryBase(getParams(params));
    history.push({ search: QSStringify(params) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location.search]
  );


  const deleteQuery = useCallback(key => {
    const params = QSParse(location.search);

    params.delete(key);
    params.page = 1;
    setQueryBase(getParams(params));
    history.push({ search: QSStringify(params) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location.search]
  );

  return { deleteQuery, setPaginationQuery, setFilterQuery, setSearchQuery, setOrderQuery, getQueryParamsFromUrl };
}
