/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as UIHelpersDocuments from 'app/modules/documents/_context/DocumentsUIHelpers';
import { getConnection } from 'constants/connectionSettings';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function ActionFormatter(cellContent, row, rowIndex, { openPrintLabelsDialog, openDeleteLabelDialog }) {
  return (
    <>
      {!row?.unlabeled &&
        <OverlayTrigger overlay={<Tooltip id="shipping-print-tooltip"><FormattedMessage id="GENERAL.PRINT" /></Tooltip>}>
          <a className="btn btn-icon btn-hover-info btn-sm" onClick={() => openPrintLabelsDialog(row)} id={`btn_print_${row?.id}`}>
            <span className="svg-icon svg-icon-md">
              <Icon.Printer />
            </span>
          </a>
        </OverlayTrigger>}

      <OverlayTrigger overlay={<Tooltip id="shipping-delete-tooltip"><FormattedMessage id="DOCUMENT.SHIPPING.DELETE" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => openDeleteLabelDialog({ id: row?.id, service: row?.service, relation: row?.relation_documents })} id={`btn_delete_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </a>
      </OverlayTrigger>
    </>
  )
}


export function LabelFormatter(cellContent, row, rowIndex, { openPrintLabelsDialog }) {

  const subText = row?.error ? row?.error : row.tracking_id

  return (
    <OverlayTrigger align="auto" overlay={<Tooltip id="shipping-tooltip" className={clsx('font-weight-bold text-danger', !row?.error && 'd-none')}>{row?.error}</Tooltip>}>
      <div className="d-flex align-items-center">

        <div type={!row?.unlabeled ? "button" : ""} className="d-flex mr-4" style={{ fontSize: "2.5rem" }} onClick={() => !row?.unlabeled && openPrintLabelsDialog(row)}>
          {row?.unlabeled ? <Icon.SupplierFIll /> : row?.error ? <Icon.ErrorFill /> : getConnection(row?.service)?.icon}
        </div>

        <div>
          <div className="font-weight-bolder text-dark-75">
            {UIHelpersDocuments.getShipperName(row.service)}
          </div>
          <div className={clsx("font-weight-bold", row?.error ? "text-danger" : "text-dark-50")}>
            {subText.length > 50 ? subText.substring(0, 50) + '...' : subText}
          </div>
        </div>

      </div>
    </OverlayTrigger>
  );
}