/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Icon } from '_metronic/_icons';
import { selectModule } from "../../_context/ImportExportUIHelper";



export const ActionsColumnFormatter = (cellContent, row, rowIndex, { importDelete, importDownload }) => (
  <>
    <OverlayTrigger overlay={<Tooltip id="products-download-tooltip"><FormattedMessage id="IMPORT_EXPORT.EXPORT.DOWNLOAD" /></Tooltip>}>
      <span className="btn btn-icon btn-hover-success btn-sm" onClick={() => importDownload(row)} id={`btn_download_${row?.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.Download />
        </span>
      </span>
    </OverlayTrigger>

    <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
      <span className="btn btn-icon btn-hover-danger btn-sm" onClick={() => importDelete(row?.id)} id={`btn_delete_${row?.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.Trash />
        </span>
      </span>
    </OverlayTrigger>

  </>
);


export const NameColumnFormatter = (cellContent, row, rowIndex, { importDownload }) => (
  <div className="d-flex align-items-center">
    <div className="symbol symbol-40 symbol-light mr-3">
      <div className="symbol-label">
        <span className="svg-icon svg-icon-lg text-dark-25">
          <OverlayTrigger overlay={<Tooltip id="filetype-tooltip" className='font-weight-bold'>CSV</Tooltip>}>
            <Icon.FileEarmarkCSV />
          </OverlayTrigger>
        </span>
      </div>
    </div>
    <div className="d-flex align-items-start flex-column">
      <div type="button" className="font-weight-bolder text-dark-75 text-hover-primary" onClick={() => importDownload(row)}>
        {row?.module && <FormattedMessage id={selectModule(row.module)?.label ?? "IMPORT_EXPORT.EXPORT"} />}
      </div>
      <div className="font-weight-bold font-size-sm text-dark-50">
        <FormattedMessage id='IMPORT_EXPORT.ACCOUNTING.IMPORT.DATA_LENGTH' values={{ data: row?.items }} />
      </div>
    </div>
  </div>
);
