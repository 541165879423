import { INITIAL_DIGITAL_PRODUCT } from '../_context/DigitalProductsUIHelpers';
import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  customerLoading: false,
  emailSuccess: false,
  entities: [],
  countDigitalProduct: 0,
  totalDigitalProduct: 0,
  digitalProductForEdit: INITIAL_DIGITAL_PRODUCT,
  customerDetails: {},
  error: '',
  documentPDF: '',
  printLoading: false,
  invoicesEbay: [],
  preparation: {},
  totalInvoicesEbay: 0,
};


export function digitalProductReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    // Get Digital Products keys
    case ActionTypes.GET_DIGITAL_PRODUCT_KEYS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_DIGITAL_PRODUCT_KEYS_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: payload.digitalProducts,
        countDigitalProduct: payload.count.count,
        totalDigitalProduct: payload.count.total,
      };

    case ActionTypes.GET_DIGITAL_PRODUCT_KEYS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };


    // Get Digital Products invoices to Ebay
    case ActionTypes.GET_DIGITAL_PRODUCT_INVOICES:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_DIGITAL_PRODUCT_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoicesEbay: payload.data.results,
        totalInvoicesEbay: payload.data.totalCount,
      };

    case ActionTypes.GET_DIGITAL_PRODUCT_INVOICES_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };



    //* get customer details
    case ActionTypes.GET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerLoading: true,
      };

    case ActionTypes.GET_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        customerLoading: false,
        customerDetails: payload.data.customer_details,
      };

    case ActionTypes.GET_CUSTOMER_DETAILS_FAIL:
      return {
        ...state,
        customerLoading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };



    //* send mail key
    case ActionTypes.SEND_MAIL_KEY:
      return {
        ...state,
        loading: true,
        emailSuccess: false,
      };

    case ActionTypes.SEND_MAIL_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        emailSuccess: true,
      };

    case ActionTypes.SEND_MAIL_KEY_FAIL:
      return {
        ...state,
        loading: false,
        emailSuccess: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };

    //* print document
    case ActionTypes.PRINT_DOCUMENT:
      return {
        ...state,
        printLoading: true,
        emailSuccess: false,
      };

    case ActionTypes.PRINT_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentPDF: payload.response,
        printLoading: false,
        emailSuccess: true,
      };

    case ActionTypes.PRINT_DOCUMENT_FAIL:
      return {
        ...state,
        printLoading: false,
        emailSuccess: false,
        error: payload.err,
      };

      
    //* print document
    case ActionTypes.PREPARATION_DOCUMENT:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.PREPARATION_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        preparation: payload.response,
      };

    case ActionTypes.PREPARATION_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.err,
      };


    case ActionTypes.CLEAN_STATES_IN_STORE:
      return {
        ...state,
        ...payload.cleanedStates,
        success: false,
      };

    case ActionTypes.CLEAR_LOADING_EFFECTS:
      return {
        ...state,
        loading: false,
        emailSuccess: false,
        error: '',
      };

    default:
      return state;
  }
}